import axios from 'axios';
import { API_ROUTE } from './Api';

export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    
    try {
        // send the refresh token to the backend in other to get a new accessToken
        const response = await axios.post(`${API_ROUTE}refreshAccessToken`,{
            refreshToken
        })

        const { accessToken } = response.data;
        localStorage.setItem('accessToken', accessToken)

        return { accessToken };
    } catch (error) {
        // just navigate them to the login page again and log them out
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('userId')
        localStorage.removeItem('userName')
        localStorage.removeItem('profilePicture')
        localStorage.removeItem('selectedTheme')
        // localStorage.clear();
        
        window.location.href = '/login';
        return null;
    }
}
