import { Outlet, Navigate } from "react-router-dom";


export function AuthenticatedRoutes (){
    const accessToken = localStorage.getItem('accessToken');
  
    
    return(
        accessToken!=null?<Outlet/>:<Navigate to='/login'/>
    )
}

export function UnAuthenticatedRoutes (){
    const accessToken = localStorage.getItem('accessToken');

    return(
        accessToken===null?<Outlet/>:<Navigate to='/'/>
    )
}

