import { useEffect } from "react";
import styled from "styled-components"

const Container = styled.div`
    position: fixed; /* Fixed positioning */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust back by 50% of its size */
    // background-color: var(--twikVibe-light-theme-bg-color);
    background-color: rgba(128, 128, 128, 0.8);

    // color: var(--twikVibe-dark-text-color);
    color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 15px;
    font-size: 15px;
    z-index: 20;
`;


const PopupMessage = ({message, setShowPopupMessage, showPopupMessage}) => {
    

    

    useEffect(()=>{
        const handleFuntion = ()=>{
            setTimeout(() => {
                setShowPopupMessage(false)
            }, 3000);
        }

        
        handleFuntion();
    },[setShowPopupMessage])
    return (
        <Container>{message}</Container>
    )
}

export default PopupMessage