import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';


const numberFormat = (num) => {
    if (num < 1000) return num.toString(); // 100 -> 100
    if (num >= 1000 && num < 10000) return (num / 1000).toFixed(1) + "k"; // 1.1k
    if (num >= 10000 && num < 100000) return (num / 1000).toFixed(2) + "k"; // 10.1k, 100.23k
    if (num >= 100000 && num < 1000000) return (num / 1000).toFixed(1) + "k"; // 100.2k
    if (num >= 1000000 && num < 10000000) return (num / 1000000).toFixed(2) + "m"; // 1.12m
    if (num >= 10000000 && num < 100000000) return (num / 1000000).toFixed(1) + "m"; // 10.1m
    if (num >= 100000000 && num < 1000000000) return (num / 1000000).toFixed(2) + "m"; // 100.4m
    if (num >= 1000000000 && num < 10000000000) return (num / 1000000000).toFixed(2) + "b"; // 1.12b
    if (num >= 10000000000 && num < 100000000000) return (num / 1000000000).toFixed(1) + "b"; // 12.5b
    if (num >= 100000000000) return (num / 1000000000).toFixed(2) + "b"; // 100.2b
};

const NavigationIcon = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    width: 40px;
    // position: relative;
    
    
    &:hover{
        background: var(--twikVibe-dark-grey-bg);
    }
`;

const FloatingNumberCount = styled.div`
    position: absolute;
    right: 15px;
    top: 2px;
    // width: 20px;
    padding: 0px 4px;
    height: 15px;
    border-radius: 20px;
    background: var(--twikVibe-brand-color);
    color: var(--twikvibe-white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    border: solid 2px var(--twikVibe-dark-theme-bg-color);
`;

const NavButton = ({setHideMobileNav, icon, link, unreadChatCount, grey, totalUnreadNotifications, setShowNotifications}) => {
    const [scale, setScale] = useState(1);

    const handleClick = () => {
        setHideMobileNav(false)
        // Increase the scale on click
        setScale(prevScale => prevScale - 0.09);
        
        // Reset scale after a short delay
        setTimeout(() => {
        setScale(1);
        }, 200);
    };

    return (
        <>
            {link
                ?<NavLink onClick={handleClick}
                    style={{
                        transform: `scale(${scale})`,
                    }} className='transparent_click inactive' to={link}>
                    <NavigationIcon>
                        {icon}
                        {unreadChatCount>0&&<FloatingNumberCount>{numberFormat(unreadChatCount)}</FloatingNumberCount>}
                    </NavigationIcon>
                </NavLink>

                // check if the number of unred messages is greater or equal to zero to show it
                :totalUnreadNotifications>=0
                ?<NavigationIcon onClick={()=>{
                    handleClick();
                    setShowNotifications(prev=>!prev)
                }}
                    style={{
                        transform: `scale(${scale})`,
                        color: grey
                    }} className='transparent_click'>
                    {icon}
                    {totalUnreadNotifications>0&&<FloatingNumberCount>{totalUnreadNotifications}</FloatingNumberCount>}
                </NavigationIcon>
                :null}
        </>
        
        
    );
};

export default NavButton;
