import styled from "styled-components"
import MobileNavButton from "./MobileNavButton";
import { RiHomeSmile2Fill } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import { IoMdPerson } from "react-icons/io";
import { RiMessage3Line } from "react-icons/ri";
import { IoMdNotifications } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";

const Container = styled.div`
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: none;
    padding: 5px;
    gap: 5px;
    background: var(--twikVibe-dark-theme-bg-color);
    // background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0, 0.9) 90%);

    @media screen and (max-width: 600px){
        display: flex;
    }
`;
const ProfilePicCon = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 100%;
    overflow: hidden;
`;

const ProfilePic = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;



const MobileNav = ({unreadChatCount, showBackButton, popupmenuOpen, setPopupMenuOpen, totalUnreadNotifications, setShowNotifications}) => {
    const userName = localStorage.getItem('userName');
    const profilePicture = localStorage.getItem('profilePicture');


    return (
        <Container>
            <MobileNavButton icon={<RiHomeSmile2Fill size={25}/>} link='/'/>
            <MobileNavButton icon={<FiSearch size={25}/>} link='/search'/>
            <MobileNavButton icon={<FaPlus size={25}/>} link='/create'/>
            <MobileNavButton icon={<RiMessage3Line size={25}/>} grey={'var(--twikvibe-grey-color)'} unreadChatCount={unreadChatCount} link='/inbox'/>
            <MobileNavButton icon={<IoMdNotifications size={25}/>} grey={'var(--twikvibe-grey-color)'} totalUnreadNotifications={totalUnreadNotifications} setShowNotifications={setShowNotifications}/>
            <MobileNavButton icon={profilePicture?<ProfilePicCon><ProfilePic src={profilePicture}/></ProfilePicCon>:<IoMdPerson size={25}/>} link={`/@${userName}`}/>
        </Container>
    )
}

export default MobileNav