// import {  useState } from "react";
import styled from "styled-components"
// import ToggleSwitch from "./ToggleSwitch";
// import axios from "axios";
// import { API_ROUTE } from "../utils/Api";
// import { refreshAccessToken } from "../utils/RefreshAccessToken";
import { Link, useNavigate } from "react-router-dom";

const Container = styled.div`
    position: fixed;
    bottom: 50px;
    left: 50px;
    padding: 10px;
    z-index:12;
    background: var(--twikVibe-dark-theme-color);
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0,0,0,0.2);
    width: 200px
    
`;

const MenuItem = styled.div`
    padding: 15px;
    border-radius: 10px;
    background: var(--twikVibe-dark-theme-color);
    color: var(--twikVibe-light-text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 10px;
    font-weight: 600;

    &:hover{
        background: var(--twikVibe--darker-transparent-black-color);
    }
`;



const PopupMenu = ({setPopupMenuOpen}) => {
    const navigate = useNavigate();


    const logOutFunction = ()=>{
        localStorage.clear();
        navigate('/login')
    }
    
    return (
        <Container>
            <Link className="transparent_click" onClick={()=>setPopupMenuOpen(prev=>!prev)} to='/settings'>
                <MenuItem>Monetisation</MenuItem>
            </Link>
            <Link className="transparent_click" onClick={()=>setPopupMenuOpen(prev=>!prev)} to='/settings'>
                <MenuItem>Settings</MenuItem>
            </Link>
            <Link className="transparent_click" onClick={()=>setPopupMenuOpen(prev=>!prev)} to='/settings/display'>
                <MenuItem>Display</MenuItem>
            </Link>

            {/* <MenuItem>
                <ToggleSwitch
                    isChecked={selecetedTheme!=='dark'?false:true}
                    onChange={toggleTheme}
                />
            </MenuItem> */}
            <MenuItem onClick={logOutFunction}>Log Out</MenuItem>

            
        </Container>
    )
}

export default PopupMenu