import { useState } from "react";
import styled from "styled-components";

// Styled components
const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 12;
    color: white;
`;

const FilesViewContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CloseButton = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 24px;
    color: white;
    z-index: 15;
`;

const FileImg = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const FileVideo = styled.video`
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
`;

const ArrowSContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ArrowContainer = styled.div`
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    font-size: 36px;
    color: ${({ disabled }) => (disabled ? "rgba(255, 255, 255, 0.3)" : "white")};
    padding: 20px;
    user-select: none;
    pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
    &:hover {
        color: ${({ disabled }) => (disabled ? "rgba(255, 255, 255, 0.3)" : "#ccc")};
    }
`;

const FilesViewer = ({ files, setSelectedFiles }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        if (currentIndex < files.length - 1) {
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    };

    const currentFile = files[currentIndex];
    const isVideo = currentFile?.fileName?.endsWith('.mp4');

    return (
        <Container>
            <CloseButton onClick={() => setSelectedFiles(null)}>✕</CloseButton>
            <ArrowSContainer>
                <ArrowContainer
                    onClick={handlePrevious}
                    disabled={currentIndex === 0}
                >
                    {"‹"}
                </ArrowContainer>
                <FilesViewContainer>
                    {isVideo ? (
                        <FileVideo controls src={currentFile.pinataUrl} />
                    ) : (
                        <FileImg src={currentFile.pinataUrl} alt={`File ${currentIndex + 1}`} />
                    )}
                </FilesViewContainer>
                <ArrowContainer
                    onClick={handleNext}
                    disabled={currentIndex === files.length - 1}
                >
                    {"›"}
                </ArrowContainer>
            </ArrowSContainer>
        </Container>
    );
};

export default FilesViewer;
