import styled, { keyframes } from "styled-components";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet"
import { API_ROUTE } from "../../utils/Api";
import { refreshAccessToken } from "../../utils/RefreshAccessToken";

const Container = styled.div`
    padding: 20px;
    flex: 1;
`;
const InnerContainer = styled.div`
    width: 350px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: auto;
    margin-top: 20px;

    @media screen and (max-width: 500px){
        width: auto;
    }
`;
const WeSentYouText = styled.h4`
    font-size: 14px;
    font-weight: 500;
    color: var(--twikVibe-light-text-color);
`;
const VerificationCodeText = styled.label`
    font-size: 15px;
    font-weight: 600;
    color: var(--twikVibe-light-text-color);
    text-align: left;
`;
const CodeInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0px;
`;
const CodeInput = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }
    
    border-radius: 10px;
    outline: none;
    border: solid 2px var(--twikVibe-dark-grey-bg);
    background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    flex: 1;
    width: 60%;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    transition: 0s;
    padding: 5px;

    &:focus{
        border: solid 2px var(--twikVibe-brand-color);
    }
`;
const ResendContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const ResendButton = styled.div`
    color: var(--twikvibe-white-color);
    font-weight: 600;
    font-size: 13px;
    background: var(--twikVibe-brand-color);
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;



const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:10px;
    background-color:transparent;
    border-radius:100px;
    width:3px;
    height:3px;
    border-style:solid;
    border-left-color:var(--twikVibe-brand-color);
    border-top-color:var(--twikVibe-brand-color);
    border-width:5px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin-left: 20px;
`;

const ErrorContainer = styled.div`
    color: var(--twikVibe-red-danger-zone-bg-color);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
`;



const ActivateEmailpage = ({setShowHeader}) => {
    const inputLength = 6;
    const [otpInput, setOTPInput] = useState(new Array(inputLength).fill(''));
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(true);
    const [regeneratingOtp, setRegeneratingOtp] = useState(false);
    const [verifyingCode, setVerifyingCode] = useState(false)
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')

    const [timeLeft, setTimeLeft] = useState(null); 
    const [isRunning, setIsRunning] = useState(false);
    const [canResend, setCanResend] = useState(false);
    
    const navigate = useNavigate();
    // remove side bar 
    useEffect(()=>{
        setShowHeader(false)
    },[setShowHeader])
    
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');

    const handleInputChange = (e, index)=>{
        const value = e.value;
        setError('');
        setMessage('');

        
        // Ensure the input is a number and of a single character
        if (isNaN(value) || value.length > 1) return;

        const newCode = [...otpInput];
        newCode[index] = value;
        setOTPInput(newCode)

        if(index < inputLength - 1 && value){
            e.nextSibling.focus();
        }

        // Check if all inputs are filled
        const allFilled = newCode.every(val => val.length === 1);
        
        if (allFilled) {
            verifyEmail(newCode.join('')); 
        }
    }


    // handle what happens when the user presses back button to clear the otp entered
    const handleBackspace=(e, index)=>{
        const newCode = [...otpInput];
        newCode[index] = '';
        setOTPInput(newCode)

        if(index > 0){
            e.previousSibling.focus();
        }
    }

    // navigate to the login page if there's no accesstoken in localstorage
    useEffect(() => {
        if (!accessToken) {
            navigate('/login');
        }
    }, [accessToken, navigate]);


    // get th user data from the stored userid

    const getUser = useCallback(async () => {
        try {
            const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`, {
                headers: {Authorization: accessToken}
            });


            // check if the user email is already activated
            if(response.data.returnWithoutPassword.emailActivated){
                navigate('/')
            }else{
                setEmail(response.data.returnWithoutPassword.email);
                const lastRequest = new Date(response.data.returnWithoutPassword.last_otp_resend_time);
                // setLastRequestTime(lastRequest);
                const timeDiff = Math.floor((new Date() - lastRequest) / 1000);

                // check if the last last_otp_resend_time has elapsed
                if (timeDiff >= 120) {
                    setCanResend(true);
                } else {
                    setCanResend(false);
                    setTimeLeft(120 - timeDiff);
                    setIsRunning(true);
                }
                setLoading(false);
            }
                
            
        } catch (error) {

            // 403 means expird access token
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`, {
                        headers: {Authorization: accessToken}
                    });
        
        
                    // check if the user email is already activated
                    if(response.data.returnWithoutPassword.emailActivated){
                        navigate('/')
                    }else{
                        setEmail(response.data.returnWithoutPassword.email);
                        const lastRequest = new Date(response.data.returnWithoutPassword.last_otp_resend_time);
                        // setLastRequestTime(lastRequest);
                        const timeDiff = Math.floor((new Date() - lastRequest) / 1000);
        
                        // check if the last last_otp_resend_time has elapsed
                        if (timeDiff >= 120) {
                            setCanResend(true);
                        } else {
                            setCanResend(false);
                            setTimeLeft(120 - timeDiff);
                            setIsRunning(true);
                        }
                        setLoading(false);
                    }
                }

            }else{
                setError(error.response.data.error)
            }
            
        }
    },[accessToken, navigate, userId]);

    useEffect(() => {
        getUser();
    }, [getUser]);

    useEffect(() => {
        let timerInterval;
        if (isRunning && timeLeft > 0) {
            timerInterval = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(timerInterval);
                        setIsRunning(false);
                        setCanResend(true);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }

        return () => clearInterval(timerInterval);
    }, [isRunning, timeLeft]);

    const startTimer = () => {
        setTimeLeft(120); // 2 minutes
        setIsRunning(true);
        setCanResend(false);
        // const currentTime = new Date();
        // setLastRequestTime(currentTime);
    };

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60).toString().padStart(2, '0');
        const seconds = (timeInSeconds % 60).toString().padStart(2, '0');
        return `00:${minutes}:${seconds}`;
    };



    const regenerateNewOTP = async () => {
        setRegeneratingOtp(true);
        setError('');
        // Call your API to resend the OTP here...
        try {
            const response = await axios.post(`${API_ROUTE}regenerateNewOTP`, {
                email: email
            });

            if (response.status === 200) {
                setMessage(`An OTP has been sent to ${email}`);
                startTimer();
                setRegeneratingOtp(false);
            }
        } catch (error) {
            setError(error.response.data.error)
            setRegeneratingOtp(false);
        }
    };

    const verifyEmail = async (otp) => {
        setVerifyingCode(true)

        // check if otp is empty 
        if(!otp){
            setError('OTP cannot be empty')
            setVerifyingCode(false);
            return;
        }

        try {
            const response = await axios.post(`${API_ROUTE}verifyEmail`, {
                email: email,
                verificationOTP: otp
            });

            if (response.status === 200) {
                window.location.href = '/';
            }
            setVerifyingCode(false)
        } catch (error) {
            setVerifyingCode(false)
            setError(error.response.data.error)
        }
     
    };

    return (
            
        <Container>
            <Helmet>
                <title>Verify Account</title>
            </Helmet>

            {loading
            ? <div>loading</div>
            :<InnerContainer>
            
                <WeSentYouText>We just sent a verification code<br/>to  <strong>{email}</strong></WeSentYouText>

                <VerificationCodeText>Enter 6-digit verification Code</VerificationCodeText>
                <CodeInputContainer>
                    {otpInput.map((data, index) => (
                        <CodeInput
                            disabled={verifyingCode}
                            key={index}
                            maxLength={1}
                            type="text"
                            value={data}
                            onChange={(e) => handleInputChange(e.target, index)}
                            placeholder="-"
                            onKeyDown={(e)=>{
                                if(e.key==='Backspace'){
                                    handleBackspace(e.target, index)
                                }
                            }}
                        />
                    ))}
                    {/* {!verifyingCode&&<div style={{width: '3px', padding:'10px', marginLeft:'20px'}}/>} */}
                    {verifyingCode&&<LoadingSpinnerElement/>}
                </CodeInputContainer>      
                {message&&<ErrorContainer style={{color:'var(--twikVibe-brand-color)'}}>{message}</ErrorContainer>}
                {error&&<ErrorContainer>{error}</ErrorContainer>}          
                                

                <ResendContainer>
                {canResend ? (
                            // check if the opt is regenerating
                            regeneratingOtp
                                ?<ResendButton>Regenerating...</ResendButton>
                                :<ResendButton  onClick={regenerateNewOTP}>Resend Code?</ResendButton>
                        ) : (
                            <ResendButton style={{cursor: 'not-allowed'}}>{formatTime(timeLeft)} left</ResendButton>
                        )}
                </ResendContainer>

                <div onClick={()=>{
                    localStorage.clear()
                    navigate('/login')
                }}>logout</div>


                {/* <SecuredByText><SheildIconImg src={SheildIcon}/> Secured by <span style={{color: "var(--twikVibe-brand-color)"}}>Lumina</span></SecuredByText> */}
            </InnerContainer>}
        </Container>
        
    )
}

export default ActivateEmailpage
