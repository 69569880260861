import axios from 'axios';
import React, { useCallback, useEffect } from 'react';
import { API_ROUTE } from '../utils/Api';
import { refreshAccessToken } from '../utils/RefreshAccessToken';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const SplashScreenContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => (theme === 'dark' ? 'var(--twikVibe-dark-theme-bg-color)' : 'var(--twikVibe-light-theme-bg-color)')};
    color: ${({ theme }) => (theme === 'dark' ? 'var(--twikVibe-light-text-color)' : 'var(--twikVibe-dark-text-color)')};
    // background-color: var(--twikVibe-dark-theme-bg-color);
    // color: var(--twikVibe-light-text-color);

    transition: background-color 300ms linear, color 300ms linear;
`;

const SplashScreen = ({ setShowSplashScreen }) => {
    const accessToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();

    const getUserData = useCallback(async () => {
        const userId = localStorage.getItem('userId');

        try {
            const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`, {
                headers: { Authorization: accessToken }
            });

            const darkModeTheme = response.data.returnWithoutPassword.darkModeTheme;
            const emailActivated = response.data.returnWithoutPassword.emailActivated;

            // Set theme for the body
            const theme = darkModeTheme ? 'dark' : 'light';
            document.querySelector("body").setAttribute('data-theme', theme);
            localStorage.setItem('selectedTheme', theme);


            // check if the email is eactivated
            if(emailActivated){
                setShowSplashScreen(false);
            }else{
                // navigate to email activation
                setShowSplashScreen(false);
                navigate('/activate-email')
            }

            
        } catch (error) {
            if (error.status === 403) {
                const { accessToken } = await refreshAccessToken();

                if (accessToken) {
                    getUserData()
                }
            }else{
                // if something goes wrong, just log them out and send them to login
                localStorage.clear()
                navigate('/login');

                const theme = 'dark';
                document.querySelector("body").setAttribute('data-theme', theme);
                localStorage.setItem('selectedTheme', theme);
                
                setTimeout(() => {
                    setShowSplashScreen(false);
                }, 2000);

            }
        }
     
    }, [accessToken, setShowSplashScreen, navigate]);

    useEffect(() => {
        if(accessToken){
            getUserData();
        }else{
            // Set theme for the body
            const theme = 'dark';
            document.querySelector("body").setAttribute('data-theme', theme);
            localStorage.setItem('selectedTheme', theme);
            
            setTimeout(() => {
                setShowSplashScreen(false);
            }, 2000);
        }

    }, [accessToken, getUserData, setShowSplashScreen]); 

    const theme = localStorage.getItem('selectedTheme') || 'light'; // Default to light

    return (
        <SplashScreenContainer theme={theme}>
            <div>SplashScreen loading...</div>
        </SplashScreenContainer>
    );
};

export default SplashScreen;
