import React, { useEffect } from 'react'

const Notfoundpage = ({setShowPopupMenuButton}) => {
  useEffect(()=>{
    setShowPopupMenuButton(false)
  },[setShowPopupMenuButton])
  return (
    <div>Notfoundpage</div>
  )
}

export default Notfoundpage