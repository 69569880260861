import axios from "axios";
import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components"
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_ROUTE } from "../../utils/Api";
import { TiWarningOutline } from "react-icons/ti";


const Container = styled.div`
    padding: 20px;
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;

`;

const MiddleSection = styled.form`
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    

    @media screen and (max-width: 500px){
        width: 100%;
    }
`;
const Inputabel = styled.label`
    color: var(--twikVibe-light-text-color);
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 5px;

 
`;
const InputContainer = styled.label`
    display: flex;
    padding: 8px;
    background-color: var(--twikVibe-dark-grey-bg);
    border-radius: 10px;
    border: 2px solid var(--twikVibe-dark-grey-bg);
    align-items: center;
    gap: 5px;
    justify-content: space-between;

    &:focus-within{
        border: 2px solid var(--twikVibe-brand-color);
    }
`;
const Input = styled.input`
    flex: 1;
    width: 100%:
    background: none;
    border: none;
    padding: 5px;
    color: var(--twikVibe-light-text-color);
    background-color: transparent;
    font-size: 16px;
    // font-weight: 500;
`;

const ActionButton = styled.button`
    color: var(--twikvibe-white-color);
    background: var(--twikVibe-brand-color);
    font-size: 13px;
    border-radius: 50px;
    padding: 15px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    font-weight: 500;
    border: none;

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;


const Label = styled.div`
    color: var(--twikVibe-light-text-color);
    font-size: 13px;
`;
// email found section, time to enter OTP
const CodeInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0px;
`;

const WeSentYouText = styled.h4`
    font-size: 14px;
    font-weight: 600;
    color: var(--twikVibe-light-text-color);
    margin-bottom: 20px;
`;

const VerificationCodeText = styled.label`
    font-size: 15px;
    font-weight: 600;
    color: var(--twikVibe-light-text-color);
    text-align: left;
`;
const CodeInput = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }

    border: solid 2px var(--twikVibe-dark-grey-bg);
    background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    border-radius: 10px;
    outline: none;
    flex: 1;
    width: 60%;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    transition: 0s;
    padding: 5px;

    &:focus{
        border: solid 2px var(--twikVibe-brand-color);
    }
`;
const ResendContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
const ResendButton = styled.div`
    color: var(--twikvibe-white-color);
    font-weight: 600;
    font-size: 13px;
    background: var(--twikVibe-brand-color);
    padding: 10px;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;


    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }

`;
const ResponseText = styled.div`
    color: var(--twikVibe-red-danger-zone-bg-color);
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;
`;


// OTP correct time to reset password



const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:10px;
    background-color:transparent;
    border-radius:100px;
    width:3px;
    height:3px;
    border-style:solid;
    border-left-color:var(--twikVibe-brand-color);
    border-top-color:var(--twikVibe-brand-color);
    border-width:5px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin-left: 20px;
`;

const ResendOTPLoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:4px;
    height:4px;
    border-style:solid;
    border-left-color:var(--twikvibe-white-color);
    border-top-color:var(--twikvibe-white-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin: auto;
`;

const ErrorContainer = styled.div`
    color: var(--twikVibe-red-danger-zone-bg-color);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
`;

// use the email validator to confirm if the login data is an email or regular text
function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

function isUsernameValid(username) {
    const regex = /^[a-zA-Z_]{4,20}$/;
    return regex.test(username);
}

const ResetPassword = ({setShowHeader}) => {

    const inputLength = 6;
    
    const [email, setEmail] = useState('');
    const [resetPasswordData, setResetPasswordData] = useState('');

    
    const [isUserNameReset, setIsUserNameReset] = useState(false);
    const [isEmailReset, setIsEmailReset] = useState(false);


    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [confirmedOTP, setConfirmedOTP] = useState('');
    const [otp, setOTP] = useState(new Array(inputLength).fill(''));
    const [emailFound, setEmailFound] = useState(false);
    const [otpIsConfirmed, setOTPIsConfirmed] = useState(false);
    const [timeLeft, setTimeLeft] = useState(null); 
    const [isRunning, setIsRunning] = useState(false);
    // const [lastRequestTime, setLastRequestTime] = useState(null);
    const [canResend, setCanResend] = useState(true);
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const [resendOTPLoading, setResendOTPLoading] = useState(false)

    
    
    const navigate = useNavigate();

    // remove side bar 
    useEffect(()=>{
        setShowHeader(false)
    },[setShowHeader])

    const onResetPasswordDataChange = (data) => {
        const value = data.target.value;

        // set the login data to the input value initially
        setResetPasswordData(value);

        // check if the email checker returns a true or false state
        if(value){
            if(isValidEmail(value)){
                // yes its an email
                setIsEmailReset(true)
                setIsUserNameReset(false);
            }else if(!isValidEmail(value)){
                // no its not an email, which means its a username
                setIsEmailReset(false)
                setIsUserNameReset(true);
            }
        }else{
            // there no login data value, set it all to false
            setIsEmailReset(false)
            setIsUserNameReset(false);
        }
    }

    
    useEffect(() => {
        let timerInterval;
        if (isRunning && timeLeft > 0) {
            timerInterval = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(timerInterval);
                        setIsRunning(false);
                        setCanResend(true);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }

        return () => clearInterval(timerInterval);
    }, [isRunning, timeLeft]);

    const startTimer = () => {
        setTimeLeft(120); // 2 minutes
        setIsRunning(true);
        setCanResend(false);
        // const currentTime = new Date();
        // setLastRequestTime(currentTime);
    };
    const handleOTPInputChange = (e, index)=>{
        const value = e.value;
        setError('');
        setMessage('');

        
        // Ensure the input is a number and of a single character
        if (isNaN(value) || value.length > 1) return;

        const newCode = [...otp];
        newCode[index] = value;
        setOTP(newCode)

        if(index < inputLength - 1 && value){
            e.nextSibling.focus();
        }

        // Check if all inputs are filled
        const allFilled = newCode.every(val => val.length === 1);
        
        if (allFilled) {
            confirmOTP(newCode.join(''));
            setConfirmedOTP(newCode.join('')) 
        }
    }

    const handleBackspace=(e, index)=>{
        const newCode = [...otp];
        newCode[index] = '';
        setOTP(newCode)

        if(index > 0){
            e.previousSibling.focus();
        }
    }
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60).toString().padStart(2, '0');
        const seconds = (timeInSeconds % 60).toString().padStart(2, '0');
        return `00:${minutes}:${seconds}`;
    };

    const regenerateNewOTP = async () => {
        // Call your API to resend the OTP here...
        setResendOTPLoading(true);
        setError('');

        try {
            const response = await axios.post(`${API_ROUTE}regenerateNewOTP`, {
                email: email
            });

            if (response.status === 200) {
                setMessage(`An OTP has been sent to ${email}`);


                startTimer();
                setResendOTPLoading(false);
            }
        } catch (error) {
            setError(error.response.data.error)
            setResendOTPLoading(false);
        }
    }

    const findUserAccount = async(e)=>{
        e.preventDefault();
        setLoading(true);

        if(!resetPasswordData){
            setLoading(false)
            setError('Enter your username or email')
            return;
        }

        

        try {

            // check if its usernam or email entered
            if(isUserNameReset){
                if(!isUsernameValid(resetPasswordData)){
                    setError('Enter a valid username or email address')
                    setLoading(false)
                    return;
                }

                const response = await axios.post(`${API_ROUTE}checkUsernameExists`,{
                    userName: resetPasswordData,
                });

                if(!response.data.usernameExists){
                    setError('Account not found')
                    setLoading(false)
                }
    
            }else if(isEmailReset){
                const response = await axios.post(`${API_ROUTE}checkEmailExists`,{
                    email: resetPasswordData,
                });

                if(!response.data.emailExists){
                    setError('Account not found')
                    setLoading(false)
                }

            }

        } catch (error) {
            // check if its usernam or email entered
            if(isUserNameReset){
                if (error.response.data.usernameExists) {
                    setEmail(error.response.data.existingUser.email)
                    setEmailFound(true);
                }
            }else if(isEmailReset){
                if (error.response.data.emailExists) {
                    setEmail(error.response.data.existingUser.email)
                    setEmailFound(true);
                }
            }

            setLoading(false)

            if(!error.response.data.error==='This email already exists'){
                setError(error.response.data.error)
            }else{
                setError('')
            }

        }
        
    }

    

    const confirmOTP= async(otp)=>{
        setLoading(true)

        // check if otp is empty 
        if(!otp){
            setError('OTP cannot be empty')
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(`${API_ROUTE}confirmOTP`,{
                email,
                verificationOTP: otp,
            })

            if(response.status===200){
                setOTPIsConfirmed(true)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setError(error.response.data.error)
        }
       
    }

    const resetPassword=async(e)=>{
        e.preventDefault();
        setLoading(true)

        if(!newPassword&&!confirmNewPassword){
            setError('Please enter a new password')
            setLoading(false)
            return;
        }

        if(newPassword !== confirmNewPassword){
            setError("Passwords must match")
            setLoading(false)
            return;
        }


        try {
            const response = await axios.post(`${API_ROUTE}resetPassword`,{
                email,
                verificationOTP: confirmedOTP,
                password: newPassword,
            })

            if(response.status === 200){
                setLoading(false)
                navigate('/login')
            }
            
            
        } catch (error) {
            setLoading(false)
            setError(error.response.data.error)
        }
        
    }


    return (
        <>
        <Helmet>
            <title>Reset Password</title>
        </Helmet>

        <Container>
            {emailFound && !otpIsConfirmed
            // email found section, time to enter OTP
            ?<MiddleSection>
                {/* <WeSentYouText>A 6-digit OTP has been sent to <strong>{email.toLowerCase()}</strong></WeSentYouText> */}
                <Label>
                <WeSentYouText>We just sent a verification code<br/>to  <strong>{email}</strong></WeSentYouText>

                <VerificationCodeText>Enter 6-digit verification Code</VerificationCodeText>
                
                    <CodeInputContainer>
                        {otp.map((data, index) => (
                            <CodeInput
                                disabled={loading}
                                key={index}
                                maxLength={1}
                                type="text"
                                value={data}
                                onChange={(e) => handleOTPInputChange(e.target, index)}
                                placeholder="-"
                                onKeyDown={(e)=>{
                                    if(e.key==='Backspace'){
                                        handleBackspace(e.target, index)
                                    }
                                }}
                            />
                        ))}
                    {loading&&<LoadingSpinnerElement/>}
                    {/* {!verifyingCode&&<div style={{width: '3px', padding:'10px', marginLeft:'20px'}}/>} */}
                    </CodeInputContainer>
                </Label>

                {message&&<ResponseText style={{color:'var(--twikVibe-brand-color)'}}>{message}</ResponseText>}
                {error&&<ErrorContainer ><TiWarningOutline />{error}</ErrorContainer>}
                
                <ResendContainer>

                {canResend ? (
                    <ResendButton disabled={resendOTPLoading} onClick={regenerateNewOTP}>{resendOTPLoading?'Sending':'Resend Code?'} {resendOTPLoading&&<ResendOTPLoadingSpinnerElement/>}</ResendButton>
                ) : (
                    <ResendButton disabled={resendOTPLoading} style={{cursor: 'not-allowed'}}>{formatTime(timeLeft)} left</ResendButton>
                )}
                </ResendContainer>

            </MiddleSection>
            

            // OTP is correct 
            :emailFound && otpIsConfirmed
            ?<MiddleSection onSubmit={resetPassword}>
                <Inputabel>
                    <InputContainer>
                        <Input disabled={loading}  value={newPassword} placeholder="Enter new password" onChange={(e)=>{setNewPassword(e.target.value); setError('')}} />
                    </InputContainer>
                </Inputabel>

                <Inputabel>
                    <InputContainer>
                        <Input disabled={loading}  value={confirmNewPassword} placeholder="Confirm new password" onChange={(e)=>{setConfirmNewPassword(e.target.value); setError('')}} />
                    </InputContainer>
                </Inputabel>
                
                {error&&<ErrorContainer ><TiWarningOutline />{error}</ErrorContainer>}
                

                {loading
                ?<ActionButton disabled={loading} ><ResendOTPLoadingSpinnerElement/></ActionButton>
                :<ActionButton disabled={loading} type='submit' onClick={resetPassword}>Reset Password</ActionButton>}
            </MiddleSection>

            // Enter email
            :<MiddleSection onSubmit={findUserAccount}>
                <Inputabel>
                    Let's find your account
                    <InputContainer>
                        <Input disabled={loading}  value={resetPasswordData} placeholder="Username or email address" onChange={(e)=>onResetPasswordDataChange(e)} />
                    </InputContainer>
                </Inputabel>
                {error&&<ErrorContainer ><TiWarningOutline />{error}</ErrorContainer>}

                {loading
                ?<ActionButton disabled={loading} ><ResendOTPLoadingSpinnerElement/></ActionButton>

                :(<>
                    {   resetPasswordData
                        ?<ActionButton disabled={loading} type='submit' onClick={findUserAccount}>Continue</ActionButton>
                        :<ActionButton type="button"  onClick={(e)=>e.stopPropagation()} style={{background:'var(--twikvibe-grey-color)', cursor:'not-allowed'}}>Continue</ActionButton>
                    }
                </>)}
                
            </MiddleSection>}

            
        </Container>
        </>
    )
}

export default ResetPassword