import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { API_ROUTE } from "../../utils/Api";
import styled, { keyframes } from "styled-components";
import {jwtDecode} from "jwt-decode";
import blackBackgroundImage from "../../assets/loaders/blackdesign.png";
import whiteBackgroundImage from "../../assets/loaders/whitedesign.png";
import GoogleIcon from "../../assets/icons/google-icon.svg";
import { TiWarningOutline } from "react-icons/ti";
import { useGoogleLogin } from "@react-oauth/google";


const Container = styled.div`
    background-image: ${({ theme }) => (theme === 'dark' ? `url(${blackBackgroundImage})` : `url(${whiteBackgroundImage})`)};
    background-size: cover; 
    background-position: center; 
    width: 100vw;
    height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 20px;
    flex: 1;

    @media screen and (max-width: 500px){
        background-image: none;
        height: auto; 
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
    }

    @media screen and (max-height: 400px){
        height: auto; 
    }
`;
const InnerContainer = styled.div`
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 500px){
        width: 90%;

    }
`;
// const LogoContainer = styled.div``;
// const Logo = styled.img``;
const WelcomeText = styled.h1`
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: var(--twikVibe-light-text-color);
`;
const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const InputContainer = styled.label`
    display: flex;
    padding: 8px;
    background-color: var(--twikVibe-dark-grey-bg);
    border-radius: 10px;
    border: 2px solid var(--twikVibe-dark-grey-bg);
    align-items: center;
    gap: 5px;
    justify-content: space-between;

    &:focus-within{
        border: 2px solid var(--twikVibe-brand-color);
    }
`;
const Input = styled.input`
    flex: 1;
    width: 100%:
    background: none;
    border: none;
    padding: 5px;
    color: var(--twikVibe-light-text-color);
    background-color: transparent;
    font-size: 16px;
    // font-weight: 500;
`;
const ForgotPassword = styled.div`
    background: var( --twikVibe-dark-theme-bg-color);
    padding: 5px 10px;
    border-radius: 15px;
    color: var(--twikvibe-grey-color);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

`;
const LoginButton = styled.button`
    width: 100%;
    padding: 15px;
    background: var(--twikVibe-brand-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: var(--twikvibe-white-color);


    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;
const SignupText = styled.div`
    font-size: 13px;
    color: var(--twikVibe-light-text-color);
    font-weight: 600;
    text-align: center;
`;

const CustomGoogleSignIn = styled.div`
    color: var(--twikVibe-dark-text-color);
    font-size: 13px;
    font-weight: 600;
    background: var( --twikVibe-light-theme-bg-color);
    text-align: center;
    padding: 15px;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
`;

const GoogleIconCon = styled.div`
    width: 15px;
    height: 15px;
`;

const GoogleImage = styled.img`
    width: 100%;
    height: 100%;
`;

const OrSection = styled.div`
    display: flex;
    gap: 10px;
    color: var(--twikvibe-grey-color);
    align-items: center;
    padding: 0px 10px;
`;

const OrLine = styled.div`
    height: 1px;
    flex: 1;
    background: #5c5c5c;
`;
const Error = styled.div`
    color:var(--twikvibe-white-color);
    background: var(--twikVibe-red-danger-zone-bg-color);
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:4px;
    height:4px;
    border-style:solid;
    border-left-color:var(--twikvibe-white-color);
    border-top-color:var(--twikvibe-white-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin: auto;
`;

// use the email validator to confirm if the login data is an email or regular text
function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}



const Loginpage = ({setShowHeader }) => {

    // login type
    const [isGoogleLogin, setIsGoogleLogin] = useState(false);
    const [isUserNameLogin, setIsUserNameLogin] = useState(false);
    const [isEmailLogin, setIsEmailLogin] = useState(false);

    const selectedTheme = localStorage.getItem('selectedTheme')
    

    // login values
    const [loginData, setLoginData] = useState('');
    const [password, setPassword] = useState('');

    // error states
    const [error, setError] = useState('');
    

    // loading state
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    // remove side bar 
    useEffect(()=>{
        setShowHeader(false)
    },[setShowHeader])

    const onLoginDataChange = (data) => {
        const value = data.target.value;

        // set the login data to the input value initially
        setLoginData(value);

        // check if the email checker returns a true or false state
        if(value){
            if(isValidEmail(value)){
                // yes its an email
                setIsEmailLogin(true)
                setIsUserNameLogin(false);
                setIsGoogleLogin(false)
            }else if(!isValidEmail(value)){
                // no its not an email, which means its a username
                setIsEmailLogin(false)
                setIsUserNameLogin(true);
                setIsGoogleLogin(false)
            }
        }else{
            // there no login data value, set it all to false
            setIsEmailLogin(false)
            setIsUserNameLogin(false);
            setIsGoogleLogin(false)
        }
    }

    const onPasswordChange = (data) => {
        const value = data.target.value;
        setPassword(value)
    }

    const login = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('')

        try {
            // check if there's data
            if(!loginData) {
                setError('Please enter your email or username');
                setLoading(false)
                return;
            }

            if(!password) {
                setError('Please enter your password');
                setLoading(false)
                return;
            }
            
            // send the data
            const response = await axios.post(`${API_ROUTE}login`,{
                loginData,
                password,
                isGoogleLogin,
                isUserNameLogin,
                isEmailLogin
            })

            
            const { darkModeTheme, emailActivated, accessToken, refreshToken } = response.data;

            const user = jwtDecode(accessToken);
            console.log(user.userId)

            // set access refresh tokens to local storage
            localStorage.setItem('accessToken', accessToken)
            localStorage.setItem('refreshToken', refreshToken)
            localStorage.setItem('userId', user.userId)
            localStorage.setItem('userName', user.userName)
            localStorage.setItem('profilePicture', user.profilePicture)

            console.log(`'picture: ${user.profilePicture}'`)

            // check if the darkModeTheme is set to true and show dark mode
            if(darkModeTheme){
                document.querySelector("body").setAttribute('data-theme', 'dark')
                localStorage.setItem('selectedTheme', 'dark')
            }else{
                document.querySelector("body").setAttribute('data-theme', 'light')
                localStorage.setItem('selectedTheme', 'light')
            }


            if(emailActivated){
                navigate('/');
                // window.location.href = '/';
            }else{
                navigate(`/activate-email`);
            }

            setLoading(false)

        } catch (error) {
            setError(error.response.data.error);
            setLoading(false)
        }
    }

    const googleLogin = useGoogleLogin({
        onSuccess: credentialResponse => googleSignIn(credentialResponse.access_token),
    })


    const googleSignIn = async(credentialResponse)=>{
        setLoading(true)

        

        // set the login type to google
        setIsEmailLogin(false)
        setIsUserNameLogin(false);
        setIsGoogleLogin(true)

        // get the users data from the credential
        await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${credentialResponse}`, {

            headers: {
                Authorization: `Bearer ${credentialResponse}`,
                Accept: 'application/json'
            }
        })
        .then(async(result)=>{

            const resultResponse = result.data;

            console.log(resultResponse)


            
            try {

                // send the login call
                const response = await axios.post(`${API_ROUTE}login`,{
                    loginData: resultResponse.email,
                    isGoogleLogin: true,
                    isUserNameLogin: false,
                    isEmailLogin: false,
                })

                

                const { darkModeTheme, accessToken, refreshToken } = response.data;

                const user = jwtDecode(accessToken);
                console.log(user.userId)

                // set access refresh tokens to local storage
                localStorage.setItem('accessToken', accessToken)
                localStorage.setItem('refreshToken', refreshToken)
                localStorage.setItem('userId', user.userId)
                localStorage.setItem('userName', user.userName)
                localStorage.setItem('profilePicture', user.profilePicture)

                // check if the darkModeTheme is set to true and show dark mode
                if(darkModeTheme){
                    document.querySelector("body").setAttribute('data-theme', 'dark')
                    localStorage.setItem('selectedTheme', 'dark')
                }else{
                    document.querySelector("body").setAttribute('data-theme', 'light')
                    localStorage.setItem('selectedTheme', 'light')
                }

                // navigate the user to the homepage 
                navigate('/');

                setLoading(false)

            } catch (error) {
                setError(error.response.data.error);
                setLoading(false)
                console.log(error)
            }
        })
        .catch(async(err) => {
            console.log(err)
            setError(error.response.data.error);
            setLoading(false)
        });
    }

    return (
        <Container theme={selectedTheme}>    
            <InnerContainer>
                <WelcomeText>Hey, welcome back.</WelcomeText>

                <FormContainer onSubmit={login}>
                    {error&&<Error><TiWarningOutline />{error}</Error>}
                    <InputContainer>
                        <Input disabled={loading} value={loginData} onChange={(e)=>onLoginDataChange(e)} placeholder="Username or email address"/>
                    </InputContainer>

                    <InputContainer>
                        <Input type="password" disabled={loading} value={password} onChange={(e)=>onPasswordChange(e)} placeholder="Password"/>
                        <Link className="transparent_click" to='/reset-password'><ForgotPassword>Forgot?</ForgotPassword></Link>
                        {/* <ForgotPassword onClick={()=>setPasswordHide(prev=>!prev)}>{passwordHide?'show':'hide'}</ForgotPassword> */}
                    </InputContainer>

                    {loading
                    ?<LoginButton style={{cursor:'not-allowed'}} disabled={loading}><LoadingSpinnerElement/></LoginButton>
                    :<>
                        {/* show a grey button if the conditions arent met */}
                        {loginData&&password
                        ?<LoginButton disabled={loading} type="submit" onClick={login}>Login</LoginButton>
                        :<LoginButton type="button"  onClick={(e)=>e.stopPropagation()} style={{background:'var(--twikvibe-grey-color)', cursor:'not-allowed'}}>Login</LoginButton>}
                    </>}
                </FormContainer>

                <OrSection>
                    <OrLine/>
                    or
                    <OrLine/>
                </OrSection>


                {loading
                ?<CustomGoogleSignIn ><LoadingSpinnerElement style={{borderLeftColor:'var(--twikvibe-light-color)',borderTopColor:'var(--twikvibe-light-color)' }} /></CustomGoogleSignIn>
                :<CustomGoogleSignIn onClick={googleLogin}><GoogleIconCon><GoogleImage src={GoogleIcon}/></GoogleIconCon>Login with Google</CustomGoogleSignIn>}


                <SignupText>Don't have an account? <Link className="transparent_click" style={{color:' var(--twikVibe-brand-color)'}} to='/signup'>Sign Up</Link></SignupText>
                    
                
            </InnerContainer>
            
        </Container>
    )
}

export default Loginpage