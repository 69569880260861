import styled, { keyframes } from "styled-components";
import { AiOutlineDollar } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { TbMessage2 } from "react-icons/tb";
import { Link } from "react-router-dom";
import { AiFillHeart } from "react-icons/ai"; // filed icon 
import { useRef, useState} from "react"
import axios from "axios";
import { API_ROUTE } from "../utils/Api";
import { refreshAccessToken } from "../utils/RefreshAccessToken";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import RegularBadge from "../assets/icons/regular_verification_badge.svg";
import SilverBadge from "../assets/icons/silver_verification_badge.svg";
import OrganisationBadge from "../assets/icons/business_verification_badge.svg";
import PrideBadge from "../assets/icons/pride_verification_tag.svg";
import TipUser from "./TipUser";
import { FiFlag } from "react-icons/fi";
import { FiTrash2 } from "react-icons/fi";
import { FiLink2 } from "react-icons/fi";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import PayToSeePost from "./PayToSeePost";
import { FaPlayCircle } from "react-icons/fa";
import FilesViewer from "./FilesViewer";
import PopupMessage from "./PopupMessage";


// import PostDetail from "./PostDetail";



// Function to detect and convert URLs and @tags into clickable links
// function detectAndConvertLinks(text) {
//     // Regex to match URLs
//     const urlRegex = /((https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))/g;
    
//     // Regex to match @tags
//     const tagRegex = /(@[a-zA-Z0-9_]+)/g;
  
//     // Merge both regexes into a combined regex
//     const combinedRegex = new RegExp(`${urlRegex.source}|${tagRegex.source}`, 'g');
  
//     const parts = [];
//     let lastIndex = 0;
  
//     // Replace matches (either URLs or @tags) with clickable links
//     text.replace(combinedRegex, (match, ...args) => {
//         const index = args[args.length - 2];
//         if (index > lastIndex) {
//             // Push the text between the last match and the current match
//             parts.push(text.slice(lastIndex, index));
//         }
    
//         if (match.startsWith('@')) {
//             // Handle @tags: Create custom link for your website
//             const profileUrl = `http://localhost:3000/@${match.slice(1)}`; // Your custom URL
//             parts.push(
//                 <Link style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click" key={index} to={profileUrl}>
//                     {match}
//                 </Link>
//             );
//         } else {
//             // Handle URLs: Make them clickable links
//             let href = match;
//             if (!href.startsWith('http://') && !href.startsWith('https://')) {
//                 href = 'http://' + href;
//             }

//             parts.push(
//                 <a key={index} href={href} target="_blank" rel="noopener noreferrer">
//                     {match}
//                 </a>
//             );
//         }
  
//         lastIndex = index + match.length;
//         return match; // Keep the match as it is
//     });
  
//     // Add the remaining text after the last match
//     if (lastIndex < text.length) {
//         parts.push(text.slice(lastIndex));
//     }
  
//     return parts;
// }

function detectAndConvertLinks(text) {
    // Regex to match URLs
    const urlRegex = /((https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))/g;

    // Regex to match @tags
    const tagRegex = /(@[a-zA-Z0-9_]+)/g;

    // Merge both regexes into a combined regex
    const combinedRegex = new RegExp(`${urlRegex.source}|${tagRegex.source}`, 'g');

    // Split text into paragraphs by newlines or double newlines
    const paragraphs = text.split(/\n+/);

    return paragraphs.map((paragraph, pIndex) => {
        const parts = [];
        let lastIndex = 0;

        // Replace matches (either URLs or @tags) with clickable links
        paragraph.replace(combinedRegex, (match, ...args) => {
            const index = args[args.length - 2];
            if (index > lastIndex) {
                // Push the text between the last match and the current match
                parts.push(paragraph.slice(lastIndex, index));
            }

            if (match.startsWith('@')) {
                // Handle @tags: Create custom link for your website
                const profileUrl = `http://localhost:3000/@${match.slice(1)}`; // Your custom URL
                parts.push(
                    <Link style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click" key={index} to={profileUrl}>
                        {match}
                    </Link>
                );
            } else {
                // Handle URLs: Make them clickable links
                let href = match;
                if (!href.startsWith('http://') && !href.startsWith('https://')) {
                    href = 'http://' + href;
                }

                parts.push(
                    <a style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click" key={index} href={href} target="_blank" rel="noopener noreferrer">
                        {match}
                    </a>
                );
            }

            lastIndex = index + match.length;
            return match; // Keep the match as it is
        });

        // Add the remaining text after the last match
        if (lastIndex < paragraph.length) {
            parts.push(paragraph.slice(lastIndex));
        }

        // Wrap the processed text in a paragraph <p> element
        return <p style={{color: 'var(--twikVibe-light-text-color)'}} key={pIndex}>{parts}</p>;
    });
}


const formatTime = (date) => {
    const parsedDate = new Date(date);
    
    // Check if the parsedDate is valid
    if (isNaN(parsedDate)) {
      return "Invalid date";
    }
  
    const now = new Date();
    const secondsAgo = Math.floor((now - parsedDate) / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
  
    if (secondsAgo < 60) return `${secondsAgo}s ago`;
    if (minutesAgo < 60) return `${minutesAgo}m ago`;
    if (hoursAgo < 24) return `${hoursAgo}h ago`;
    if (daysAgo <= 7) return `${daysAgo}d ago`;

    const formattedDate = `${parsedDate.getDate().toString().padStart(2, '0')}/${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}/${parsedDate.getFullYear()}`;
  
    // For more than 7 days, return formatted date MM/DD/YY
    return formattedDate;
};

const formatLikes = (num) => {
    if (num < 1000) return num.toString(); // 100 -> 100
    if (num >= 1000 && num < 10000) return (num / 1000).toFixed(1) + "k"; // 1.1k
    if (num >= 10000 && num < 100000) return (num / 1000).toFixed(2) + "k"; // 10.1k, 100.23k
    if (num >= 100000 && num < 1000000) return (num / 1000).toFixed(1) + "k"; // 100.2k
    if (num >= 1000000 && num < 10000000) return (num / 1000000).toFixed(2) + "m"; // 1.12m
    if (num >= 10000000 && num < 100000000) return (num / 1000000).toFixed(1) + "m"; // 10.1m
    if (num >= 100000000 && num < 1000000000) return (num / 1000000).toFixed(2) + "m"; // 100.4m
    if (num >= 1000000000 && num < 10000000000) return (num / 1000000000).toFixed(2) + "b"; // 1.12b
    if (num >= 10000000000 && num < 100000000000) return (num / 1000000000).toFixed(1) + "b"; // 12.5b
    if (num >= 100000000000) return (num / 1000000000).toFixed(2) + "b"; // 100.2b
};

const PostParentContainer = styled.div`
    display:flex;
    flex-direction:column;
    // padding:10px;
    // flex:1;
    gap: 10px;
    // margin-top:-14px;
    margin-bottom: 100px;
`;


const PostContainer = styled.div `
    padding: 10px;
    display:flex;
    flex-direction:column;
    gap:20px;
    background-color: var(--twikVibe-dark-grey-bg);
    border-radius: 10px;
    position: relative;
`;


const PostProfileParentContainer=styled.div`
    display:flex;
    align-items:center;
    gap: 10px;
`;

const PostProfilePicsCon= styled.div`
    background:grey;
    border-radius:100%;
    width:40px;
    height:40px;
`;

const NoProfilePicCon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: var(--twikvibe-white-color);
    border-radius:100%;
`;
const PostProfilePics= styled.img`
    border-radius:100%;
    width:100%;
    height:100%;
    object-fit: cover;
`;

const PostProfileContainer = styled.div`
    display:flex;
    align-items:center;
    gap:1em;
    justify-content:space-between;
`;

const PostProfileUserNameContainer = styled.div`
    display:flex;
    flex-direction:column;
    gap:5px;
    position: relative;
`;

const PostProfileUserName = styled.h4`
    color:var(--twikVibe-light-theme-color);
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover + .profileDetailHover{
        visibility: visible;
        opacity: 1;
    }
`;

const VerficationImageContainer = styled.div`
    position: relative;
    width: 14px;
    height: 14px;
`;
const VerficationImage = styled.img`
    width: 100%;
    height:100%;
`;
const Check = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
`;
const ProfileDetailHover = styled.div`
    padding: 10px;
    background: blue;
    width: 200px;
    height:200px;
    position: absolute;
    top: 20px;
    border-radius: 10px;


    background: var(--twikVibe-dark-theme-bg-color);
    box-shadow: 0px 4px 15px rgba(0,0,0,0.2);
    z-index: 12;
    visibility: hidden;
    opacity: 0;

    
    &:hover {
        visibility: visible;
        opacity: 1;
    }

    @media screen and (max-width: 600px){
        display: none;
    }
`;

const PostTimeStamp = styled.div`
    font-size:13px;
    color:var(--twikvibe-grey-color);
`;

const MoreContainer = styled.div`
    cursor: pointer;
`;


const PostProfileSubcribeBtn=styled.button`
    background:var( --twikVibe-brand-color);
    border-style:solid;
    border-color:transparent;
    color:ivory;
    font-size:12px;
    font-weight:bold;
    padding:8px;
    border-radius:5px;
    cursor:pointer;
`;


const PostContentContainer = styled.div`
    display:flex;
    flex-direction:column;
    // padding:5px;
    color:var(--twikVibe-light-theme-color);
    // gap:10px;
    position: relative;
`;


const PostContent= styled.div`
    line-height:1.5em;
    font-size: 16px;
    color:var(--twikVibe-light-theme-color);
    word-break: break-word;
`;

const PostImagesContainer = styled.div`
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 5px; /* Optional: Adds padding around the scroll area */
    gap: 5px; /* Space between each item */
    cursor: ${({ isdragging }) => (isdragging ? 'grabbing' : 'grab')};
    user-select: none;
    // width: 100%;
    
    /* Hide the scrollbar but still allow scrolling */
    &::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
    }
    -ms-overflow-style: none; /* For IE and Edge */
    scrollbar-width: none; /* For Firefox */
    
`;

// const PostImgGridContainer = styled.div`
//     position: relative;
//     width: 250px;  
//     height: 250px;
//     margin: auto;
//     margin-top: 40px;
//     margin-bottom: 30px;


//     @media screen and (max-width: 600px){
//         width: 60%;
//         margin-top: 40px;
//         // height: 200px;
//     }

//     @media screen and (max-width: 400px){
//         width: 70%;
//         // height: 200px;
//     }

    
// `;


// const PostContentImgCon = styled.div`
//     // background: whitesmoke;
//     width: 100%;
//     height: 100%;
//     border-radius: 10px;
//     overflow: hidden;
//     cursor: pointer;
//     position: absolute;
//     transform: ${({ rotation }) => `rotate(${rotation}deg)`};
// `;

const PostContentImgCon = styled.div`
    display: inline-block;
    cursor: drag;
    background:whitesmoke;
    width:200px;
    height:200px;
    border-radius:5px;
    // overflow: hidden;
    flex-shrink: 0;
    user-select: none;
    position: relative;
`;


const PostContentImgOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

`;


const PostContentImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius:5px;
`;

const PostContentVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius:5px;
`;
const PostContentVideoOverlay = styled.div`
    border-radius:5px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PlayButton = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: var(--twikVibe-brand-color);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PostReactionContainer = styled.div`
    font-size:27px;
    display:flex;
    gap:1em;
    // cursor:pointer;
    // padding:5px;
    align-items: center;
`;

const ReactionItem = styled.div`
    display:flex;
    gap: 10px;
    cursor:pointer;
    align-items: center;
    color: var(--twikVibe-light-text-color);
    // padding:5px;
    font-size: 14px;
    justify-content: center;
`;

const PostPopupBG = styled.div`
    background: var(--twikvibe-transparent-black-color);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;

    @media screen and (max-width: 600px){
        padding: 10px;
    }
`;


const PostPopupContainer = styled.div`
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0,0,0,0.1);
    // border: solid .2px var(--twikvibe-grey-color);
    background: var(--twikVibe-dark-theme-color);
    width: 180px;
    top: 50px;
    right: 10px;
    z-index: 10;
`;

const PostPopupItem = styled.div`
    padding: 15px;
    border-radius: 10px;
    background: var(--twikVibe-dark-theme-color);
    color: var(--twikVibe-light-text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;
    gap: 10px;

    &:hover{
        background: var(--twikVibe--darker-transparent-black-color);
    }
`;

const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:2px;
    height:2px;
    border-style:solid;
    border-left-color:var(--twikVibe-light-text-color);
    border-top-color:var(--twikVibe-light-text-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin-left: 50px;
`;

const HasNotPaidContainer = styled.div`
    // background: var(--twikVibe--darker-transparent-black-color);
    // background: rgba(43, 159, 255, 0.9);
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    border-radius: 10px;
`;

const PayButton = styled.div`
    background: var(--twikVibe-brand-color);
    color: var(--twikvibe-white-color);
    font-size: 15px;
    cursor: pointer;
    padding: 10px;
    border-radius: 20px;

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;

// const AdsContainer = styled.div``;

const Posts = ({posts, setPosts}) => {

    // max visible images in a post 
    // const maxVisible = 4;

    const userName = localStorage.getItem('userName')
    const userId = localStorage.getItem('userId')
    const accessToken = localStorage.getItem('accessToken')

    const [deleting, setDeleting] = useState(false);

    const [showPayToSeePost, setShowPayToSeePost] = useState({})

    const [showTipUser, setShowTipUser] = useState({
        userName: null,
        profilePicture: null
    })

    const [selectedPost, setSelectedPost] = useState({})
    const [selectedPostIndex, setSelectedPostIndex] = useState({})

    const [selectedFiles, setSelectedFiles] = useState(null)

    const scrollContainerRefs = useRef([]); // Reference to the container for dragging
    const [isDragging, setIsDragging] = useState(false); // Track if dragging is active
    const [startX, setStartX] = useState(0); // Initial X position when drag starts
    const [scrollLeft, setScrollLeft] = useState(0);

    // popupmessage state
    const [showPopupMessage, setShowPopupMessage] = useState(false);
    const [popupMessage, setPopupMessage] = useState('')

    const handleMouseDown = (e, index) => {
        const container = scrollContainerRefs.current[index];
        if (container) {
            setIsDragging(true);
            setStartX(e.pageX - container.offsetLeft);
            setScrollLeft(container.scrollLeft);
        }
    }; 

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e, index) => {
        if (!isDragging) return;
        e.preventDefault();
        const container = scrollContainerRefs.current[index];
        if (container) {
            const x = e.pageX - container.offsetLeft;
            const walk = (x - startX) * 2;
            container.scrollLeft = scrollLeft - walk;
        }
    }; 

    const togglePostReaction = async({postId, index})=>{

        // toggle the reaction
        setPosts((prevPosts) =>
            prevPosts.map((post, i) =>
              i === index
                ? {
                    ...post,
                    reactionsId: post.reactionsId.includes(userId)
                      ? post.reactionsId.filter((id) => id !== userId) // Unlike
                      : [...post.reactionsId, userId], // Like
                  }
                : post
            )
        );

        try {
            await axios.post(`${API_ROUTE}togglePostReaction?postId=${postId}`,
            {},
            {
                headers: {Authorization: accessToken}
            })

            
        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    await axios.post(`${API_ROUTE}togglePostReaction?postId=${postId}`,
                        {},
                        {
                            headers: {Authorization: accessToken}
                        })

                }
            }else{

                // toggle it back if i fails
                setPosts((prevPosts) =>
                    prevPosts.map((post, i) =>
                      i === index
                        ? {
                            ...post,
                            reactionsId: post.reactionsId.includes(userId)
                              ? post.reactionsId.filter((id) => id !== userId) // Unlike
                              : [...post.reactionsId, userId], // Like
                          }
                        : post
                    )
                );
                console.log(error)
            }
        }
    }

    const toggleSubscribeToUser = async({userIamSubscribingToId, index})=>{

        // toggle the subscribe button
        setPosts((prevPosts) =>
            prevPosts.map((post, i) =>
              i === index
                ? {
                    ...post,
                    subscribers: post.subscribers.includes(userId)
                      ? post.subscribers.filter((id) => id !== userId) // Unlike
                      : [...post.subscribers, userId], // Like
                  }
                : post
            )
        );


        try {

            await axios.post(`${API_ROUTE}toggleSubscribeToUser?userIamSubscribingToId=${userIamSubscribingToId}`,
                {},
                {
                    headers: {Authorization: accessToken}
                });

            // set the subscribe button to the opposite of what it was initially

        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();
                

                if(accessToken){
                    await axios.post(`${API_ROUTE}toggleSubscribeToUser?userIamSubscribingToId=${userIamSubscribingToId}`,
                        {},
                        {
                            headers: {Authorization: accessToken}
                        });
                    
                }
            }else{

                // toggle back if it fails
                setPosts((prevPosts) =>
                    prevPosts.map((post, i) =>
                      i === index
                        ? {
                            ...post,
                            subscribers: post.subscribers.includes(userId)
                              ? post.subscribers.filter((id) => id !== userId) // Unlike
                              : [...post.subscribers, userId], // Like
                          }
                        : post
                    )
                );
        
                console.log(error)
            }
        }
    }

    if(showTipUser.userName){
        document.body.style.overflow = 'hidden';
    }else{
        document.body.style.overflow = 'auto';
    }
    

    const deletePost = async(postId, index)=>{
        setDeleting(true);
        try {
            await axios.delete(`${API_ROUTE}deletePost?postId=${postId}`,{
                headers: {Authorization: accessToken}
            })

            setDeleting(false);

            // delete the posts from the array without refreshing
            setPosts((prevPosts) => prevPosts.filter((_, i) => i !== index));


            setSelectedPost({});

            // add the popup message
            setPopupMessage('Deleted');
            setShowPopupMessage(true)

            
        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();
                

                if(accessToken){
                    await axios.delete(`${API_ROUTE}deletePost?postId=${postId}`,{
                        headers: {Authorization: accessToken}
                    })

                    setDeleting(false);
                    // delete the posts from the array without refreshing
                    setPosts((prevPosts) => prevPosts.filter((_, i) => i !== index));

                    setSelectedPost({});

                    // add the popup message
                    setPopupMessage('Deleted');
                    setShowPopupMessage(true)
                }

            }else{

              
                setDeleting(false);
                console.log(error)
            }
        }
    }

    // check if the posts array is empty
    if (!posts || !Array.isArray(posts)) {
        return <div>No posts available</div>;
    }

    return (
        
        <PostParentContainer>
            {posts.map((post, index)=>(

                <span key={index}>
                    {/* check if the post is an ad */}
                    {post.isAdActive
                    // ads container for when we start displaying ads, just uncomment the below container
                    // ?<AdsContainer key={index}></AdsContainer>
                    ?null

                    // post is not an ad, but a regular post
                    :<PostContainer>



                        {/* Post profile container beginning */}
                        <PostProfileContainer>

                            <PostProfileParentContainer>

                                {/* Post profile pics beginning */}
                                <Link className="transparent_click" to={`/@${post.userName}`}>
                                    <PostProfilePicsCon>

                                        {/* check if theres a profile picture */}
                                        {!post.profilePicture
                                        ?<NoProfilePicCon style={{background: post.userBackgroundColor}}>{post.userName[0]}</NoProfilePicCon>
                                        :<PostProfilePics src={post.profilePicture}/>}
                                    </PostProfilePicsCon>
                                </Link>
                            
                                {/* Post UserName Section Beginning */}
                                <PostProfileUserNameContainer>
                                    <PostProfileUserName>
                                        <Link style={{color:'var(--twikVibe-light-text-color)'}} className="transparent_click" to={`/@${post.userName}`}>{post.userName}</Link>

                                        {/* verification badge */}

                                        {/* check if this user has purchased a verification badge first */}
                                        {post.hasVerificationBadge&&
                                        <VerficationImageContainer>

                                            {/* get the exact verification badge type the user has if they have a verification badge */}
                                            <VerficationImage src={post.verificationBadgeType==='regular'?RegularBadge : post.verificationBadgeType==='silver'?SilverBadge : post.verificationBadgeType==='organisation'? OrganisationBadge : post.verificationBadgeType==='pride'?PrideBadge : null} alt="verification badge"/>
                                            <Check><FaCheck color="var(--twikVibe-dark-text-color)" size={8}/></Check>
                                        </VerficationImageContainer>}

                                    </PostProfileUserName>

                                    {/* div appears when username is hovered */}
                                    <ProfileDetailHover className="profileDetailHover">
                                        <PostProfileUserName>
                                            <Link style={{color:'var(--twikVibe-light-text-color)'}} className="transparent_click" to={`/@${post.userName}`}>{post.userName}</Link>

                                            {/* verification badge */}

                                            {/* check if this user has purchased a verification badge first */}
                                            {post.hasVerificationBadge&&
                                            <VerficationImageContainer>

                                                {/* get the exact verification badge type the user has if they have a verification badge */}
                                                <VerficationImage src={post.verificationBadgeType==='regular'?RegularBadge : post.verificationBadgeType==='silver'?SilverBadge : post.verificationBadgeType==='organisation'? OrganisationBadge : post.verificationBadgeType==='pride'?PrideBadge : null} alt="verification badge"/>
                                                <Check><FaCheck color="var(--twikVibe-dark-text-color)" size={10}/></Check>
                                            </VerficationImageContainer>}

                                        </PostProfileUserName>

                                        {/* Post profile subscription Begining */}
                                        <PostProfileSubcribeBtn onClick={()=>toggleSubscribeToUser({userIamSubscribingToId: post.userId, index})}>{post.subscribers.includes(userId)?'Unsubscribe':'Subscribe'}</PostProfileSubcribeBtn>
                                    </ProfileDetailHover>

                                    <PostTimeStamp>{formatTime(post.timeStamp)}</PostTimeStamp>
                                </PostProfileUserNameContainer>
                                
                        
                            </PostProfileParentContainer>
                            

                            {/* the three dots menu on the post item container ============================================================================================================ */}
                            <MoreContainer onClick={()=>{setSelectedPost(post); setSelectedPostIndex(index)}}>
                                <BsThreeDotsVertical />
                            </MoreContainer>

                        </PostProfileContainer>
                        {/* Profile Profile Container ending  */}

                        {/* Post Content Begining */}
                        <PostContentContainer>
                            <PostContent>{detectAndConvertLinks(post.content)}</PostContent>
                            
                            
                            {/* the post with images and  */}
                            <PostContentContainer>
                                
                                {/* check if post is a paid one */}

                                {post.isPostPaid&&!post.hasPaid&&post.userName!==userName ?   (
                                    <HasNotPaidContainer>
                                        <PayButton onClick={()=>{
                                            setShowPayToSeePost(post)
                                        }}>Pay {Number(post.postPrice).toLocaleString("en-US", {style:"currency", currency:"USD"})} to view this content.</PayButton>
                                    </HasNotPaidContainer>
                                )   :   (
                                    <>
                                        {/* check if this post has images */}
                                        {post.files.length>0&&
                                        // <PostImgGridContainer>
                                            // {post.files.slice(0, maxVisible).map((file, index) => {
                                            //     const rotation = index > 0 ? (-20 + (index - 1) * 20) : 0; // 0, 60, 80, 100, 120 for index 1, 2, 3
                                            //     return (
                                            //         // {/* check if the fil is an image or video */}
                                            //         <PostContentImgCon key={index} rotation={rotation}>
                                            //             {file && file.pinataUrl && (file.fileName.endsWith('.jpg') || file.fileName.endsWith('.png')) ? (
                                            //                 <PostContentImg src={file.pinataUrl} alt={`Post image ${index + 1}`} />
                                            //             )   :   file && file.pinataUrl && file.fileName.endsWith('.mp4') ? (
                                            //                 <video style={{width:'100%', height:'100%'}} >
                                            //                 {/* <video style={{width:'100%', height:'100%'}} autoPlay loop controls> */}
                                            //                     <source src={file.pinataUrl} />
                                            //                     Your browser does not support the video tag.
                                            //                 </video>
                                            //             ) : null}
                                            //         </PostContentImgCon>
                                            //     );
                                            // })}
                                        // </PostImgGridContainer>
                                        <PostImagesContainer
                                            isdragging={isDragging}
                                            ref={(el) => (scrollContainerRefs.current[index] = el)}
                                            onMouseDown={(e) => handleMouseDown(e, index)}
                                            onMouseLeave={handleMouseLeave}
                                            onMouseUp={handleMouseUp}
                                            onMouseMove={(e) => handleMouseMove(e, index)}
                                        >
                                            {/* list of images in post */}
                                            {post.files.map((file, index)=>(
                                                // <Link key={index} className="transparent_click" to={`/@${post.userName}/post/${post._id}`}>
                                                    <PostContentImgCon onClick={()=>setSelectedFiles(post.files)}>
                                                        {/* check if the fil is an image or video */}

                                                        {file && file.pinataUrl && (file.fileName.endsWith('.jpg') || file.fileName.endsWith('.png')) ? (
                                                            <PostContentImg src={file.pinataUrl} alt={`post ${index}`}/>
                                                        )   :   file && file.pinataUrl && file.fileName.endsWith('.mp4') ? (
                                                            // <video style={{width:'100%', height:'100%'}}>
                                                            <PostContentVideo >
                                                            {/* <video style={{width:'100%', height:'100%', objectFit:'cover'}} autoPlay loop controls> */}
                                                                <source src={file.pinataUrl}  />
                                                                Your browser does not support the video tag.
                                                            </PostContentVideo>
                                                        ) : null}
                                                        <PostContentImgOverlay/>
                                                        {/* check if its a video */}
                                                        {/* the  play button on videos */}
                                                        {file && file.pinataUrl && file.fileName.endsWith('.mp4') &&
                                                        <PostContentVideoOverlay><PlayButton><FaPlayCircle size={20} color="var(--twikvibe-white-color)" /></PlayButton></PostContentVideoOverlay>}
                                                    </PostContentImgCon>
                                                // </Link>
                                                
                                            ))}
                                        </PostImagesContainer>
                                        
                                        }



                                    </>
                                )}
                            </PostContentContainer>
                            
                                
                        </PostContentContainer>

                        {/* Post Reaction Begining */}
                
                        <PostReactionContainer>
                            
                            {/* post likes */}
                            {post.reactionsId.includes(userId)
                            ?<ReactionItem><AiFillHeart size={20} color="var(--twikVibe-light-text-color)" style={{cursor:'pointer' ,fill:'var(--twikVibe-brand-color)'}} onClick={()=>togglePostReaction({postId: post._id, index})} /> {formatLikes(post.reactionsId.length)}</ReactionItem>
                            :<ReactionItem><AiOutlineHeart size={20} color="var(--twikVibe-light-text-color)"  style={{cursor: 'pointer', }} onClick={()=>togglePostReaction({postId: post._id, index})} /> {formatLikes(post.reactionsId.length)}</ReactionItem>}
                            
                            {/* comment button */}
                            <ReactionItem><Link className="transparent_click" to={`/@${post.userName}/post/${post._id}`}><TbMessage2 size={20} color="var(--twikVibe-light-text-color)" style={{cursor: 'pointer'}}/> </Link> {formatLikes(post.commentsCount)}</ReactionItem>
                            


                            {/* tip user button */}

                            {/* hide the tip button if it is my post */}
                            {post.userName!==userName&&<ReactionItem>
                                <AiOutlineDollar onClick={()=>{
                                setShowTipUser((prev)=>({
                                    ...prev,
                                    userName: post.userName,
                                    profilePicture: post.profilePicture
                                }))
                            }} size={20} color="var(--twikVibe-light-text-color)"  style={{cursor: 'pointer'}}/>
                            </ReactionItem>}
                        </PostReactionContainer>
                   
                    </PostContainer>}
                </span>
                
            ))}

            
            {/* the posts popup actions */}
            {selectedPost._id&&
            <PostPopupBG onClick={()=>setSelectedPost({})}>
                <PostPopupContainer onClick={(e)=>e.stopPropagation()}>
                    {/* show report button if the post is not mine */}
                    {userName!==selectedPost.userName&&<PostPopupItem><FiFlag style={{color: 'var(--twikvibe-grey-color)'}}/> Report</PostPopupItem>}
                    {/* show delete button if the post is mine */}
                    {userName===selectedPost.userName&&<PostPopupItem onClick={()=>deletePost(selectedPost._id, selectedPostIndex)}><FiTrash2 style={{color: 'var(--twikvibe-grey-color)'}}/> Delete {deleting&&<LoadingSpinnerElement/>}</PostPopupItem>}

                    <CopyToClipboard text={`http://localhost:3000/@${selectedPost.userName}/post/${selectedPost._id}`} onCopy={()=>{
                        setSelectedPost({});

                        // add the popup message
                        setPopupMessage('Copied');
                        setShowPopupMessage(true)
                    }}>
                        <PostPopupItem><FiLink2 style={{color: 'var(--twikvibe-grey-color)'}}/> Copy link </PostPopupItem>
                    </CopyToClipboard>
                </PostPopupContainer>
            </PostPopupBG>}
            {showTipUser.userName&&<TipUser userName={showTipUser.userName} profilePicture={showTipUser.profilePicture} setShowTipUser={setShowTipUser}/>}
            {showPayToSeePost._id&&<PayToSeePost postOwnerUsername={showPayToSeePost.userName} post={showPayToSeePost} setShowPayToSeePost={setShowPayToSeePost} />}
            {selectedFiles&&<FilesViewer files={selectedFiles} setSelectedFiles={setSelectedFiles}/>}

            {/* popup message  */}
            {showPopupMessage&&<PopupMessage message={popupMessage} setShowPopupMessage={setShowPopupMessage} showPopupMessage={showPopupMessage}/>}

        </PostParentContainer>
        


    )
}

export default Posts