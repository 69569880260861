
import styled from "styled-components"
import { IoArrowBackOutline } from "react-icons/io5"
import { SlOptionsVertical } from "react-icons/sl";
import { BiTransferAlt } from "react-icons/bi"
import { useEffect } from "react";

const Container=styled.div`

// align-items:center;
flex:1;
// // padding:15px;
// color:var(--twikVibe-light-text-color);
// gap:3em;
`;

const WalletContainer = styled.div`
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: auto;
    gap: 20px;
    
    
    @media screen and (max-width: 600px){
        width: auto;
        // padding: 10px;
    }
`;

const WalletHeader = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
gap:10em;
background:var(--twikVibe-dark-theme-bg-color);
padding: 15px 10px;
top:0px;
position:sticky;

@media screen and (max-width: 600px){
    top: 50px;
}
`;

const WalletHeaderTxt = styled.h4`
font-size:16px;
color:var(--twikVibe-light-text-color);
`;


const WalletBalanceContainer = styled.div`
display:flex;
flex-direction:column;
gap:4em;
align-items:center;
`;

const WalletBalancePrice=styled.h2`
    color:var(--twikVibe-light-text-color);
`;

const WalletBtnContainer = styled.div`
display:flex;
gap:2em;
`;

const TransactionBtn =styled.button`
font-size:13px;
// padding:15px 30px;
background:blue;
border-radius:100px;
height:50px;
width:120px;
border-style:solid;
border-color:transparent;
background:var(--twikVibe-dark-grey-bg);
color:var(--twikVibe-light-text-color);
font-weight: 600;
`;


const TransactionHeaderContainer = styled.div`
display:flex;
justify-content:space-between;
gap:18em;
align-items:center;
`;

const TransactionParentContainer =styled.div`
display:flex;
flex-direction:column;
gap:1.5em;
padding: 10px;
`;


const TransactionHeaderTxt=styled.h5`
    color:var(--twikVibe-light-text-color);
`;

const TransactionBoxContainer= styled.div`
padding:20px;
// width:89%;
// height:30px;
background:var(--twikVibe-dark-grey-bg);
border-radius:10px;
display:flex;
align-items:center;
gap:0.9em;
`;


const TransactionParentStatusTxtContainer=styled.div`
display:flex;
flex-direction:column;
gap:5px;
color:var(--twikVibe-light-text-color);
`;

const TransactionMainStatusTxt=styled.h3`
font-size:14px;
color:var(--twikVibe-light-text-color);
`;

const TransactionStatementStatusTxt=styled.p`
font-size:13px;
color:var(--twikVibe-light-text-color);
`;

const WalletPage =({setShowPopupMenuButton})=>{

    const dummyData=[
        {
            transactionType:'Transfer',
            statement:"You transferred $20 to @Pinkie"
        },
        {
            transactionType:'Deposit',
            statement:"You Deposited $200 "
        },
        {
            transactionType:'Withdraw',
            statement:"You withdrawed $200"
        },
        {
            transactionType:'Deposit',
            statement:"You Deposited $20"
        },
        {
            transactionType:'Transfer',
            statement:"You Transfered $10 to @Biggie"
        }
    ]


    // remove side bar 
    useEffect(()=>{
        setShowPopupMenuButton(false)
    },[ setShowPopupMenuButton])


    return (
        <Container>
        
            <WalletContainer>
                {/* Wallet Header section*/}
                <WalletHeader>
                    <IoArrowBackOutline color="var(--twikVibe-light-text-color)" size={20} />
                    <WalletHeaderTxt>Wallet</WalletHeaderTxt>
                    <SlOptionsVertical color="var(--twikVibe-light-text-color)" size={20} />
                </WalletHeader>

                {/* ?wallet balance section */}
                <WalletBalanceContainer>
                    <WalletBalancePrice>$100.00</WalletBalancePrice>

                    {/* Wallet btn Section*/}
                    <WalletBtnContainer>
                        <TransactionBtn style={{cursor:"pointer"}}>Withdraw</TransactionBtn>
                        <TransactionBtn style={{cursor:"pointer"}}>Fund</TransactionBtn>
                        <TransactionBtn style={{cursor:"pointer"}}>Transfer</TransactionBtn>
                    </WalletBtnContainer>
                </WalletBalanceContainer>


                {/* Wallet Transaction section */}
                <TransactionParentContainer>
        {/* 3DF836 -transafer box bg */}
        {/*  icon color 2ED72B */}
                    <TransactionHeaderContainer>
                    <TransactionHeaderTxt>Activities</TransactionHeaderTxt>
                    <SlOptionsVertical color="var(--twikVibe-light-text-color)" size={18} />
                    </TransactionHeaderContainer>
                
                {/* transaction boxes activities section  and also run a loop   */}
                {dummyData.map((data,index)=>(
                    <TransactionBoxContainer>
                    <BiTransferAlt fill="#2ED72B" size={25} />
                    <TransactionParentStatusTxtContainer>
                        <TransactionMainStatusTxt>{data.transactionType}</TransactionMainStatusTxt>
                        <TransactionStatementStatusTxt>{data.statement}</TransactionStatementStatusTxt>
                    </TransactionParentStatusTxtContainer>
                </TransactionBoxContainer>
                ))}
            

                </TransactionParentContainer>

            </WalletContainer>
        

        </Container>
    )
}

export default  WalletPage