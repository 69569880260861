// export const formatDateTime =(dateInput)=> {
//    // Ensure that dateInput is a valid Date object
//    const date = new Date(dateInput);
//    if (isNaN(date)) {
//        return "Invalid date";
//    }

//    const now = new Date();
//    const diffInSeconds = Math.floor((now - date) / 1000);
//    const diffInMinutes = Math.floor(diffInSeconds / 60);
//    const diffInHours = Math.floor(diffInMinutes / 60);
//    const diffInDays = Math.floor(diffInHours / 24);

//    if (diffInSeconds < 60) {
//        return diffInSeconds < 5 ? "just now" : `${diffInSeconds} secs ago`;
//    } else if (diffInMinutes < 60) {
//        return `${diffInMinutes} min${diffInMinutes !== 1 ? 's' : ''} ago`;
//    } else if (diffInHours < 24) {
//        return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
//    } else if (diffInDays === 1) {
//        return "yesterday";
//    } else {
//        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
//    }
// }


export const formatDateTime = (dateInput) => {
    // Ensure that dateInput is a valid Date object
    const date = new Date(dateInput);
    if (isNaN(date)) {
        return "Invalid date";
    }
 
    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
 
    // Same day check (ignores hours, checks if calendar day is the same)
    const isSameDay = now.getDate() === date.getDate() &&
                      now.getMonth() === date.getMonth() &&
                      now.getFullYear() === date.getFullYear();
 
    // Correctly handle "yesterday" even if it's a different month
    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);
    const isYesterday = yesterday.getDate() === date.getDate() &&
                        yesterday.getMonth() === date.getMonth() &&
                        yesterday.getFullYear() === date.getFullYear();
 
    if (diffInSeconds < 60) {
        return diffInSeconds < 5 ? "just now" : `${diffInSeconds} secs ago`;
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} min${diffInMinutes !== 1 ? 's' : ''} ago`;
    } else if (diffInHours < 24 && isSameDay) {
        return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
    } else if (isYesterday) {
        return "yesterday";
    } else if (diffInDays >= 2) {
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    }
 }
 