import axios from "axios";
import styled from "styled-components"
import { API_ROUTE, ROOT_API_ROUTE } from "../utils/Api";
import { useCallback, useEffect, useRef, useState } from "react";
import  io  from "socket.io-client";
import { formatDateTime } from "../utils/DateFormater";
import { BsFillSendFill, BsThreeDotsVertical } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";
import { AiOutlineDollar } from "react-icons/ai";
import TipUser from "../components/TipUser";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import RegularBadge from "../assets/icons/regular_verification_badge.svg";
import SilverBadge from "../assets/icons/silver_verification_badge.svg";
import OrganisationBadge from "../assets/icons/business_verification_badge.svg";
import PrideBadge from "../assets/icons/pride_verification_tag.svg";


function detectAndConvertLinks(text) {
    // Regex to match URLs
    const urlRegex = /((https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))/g;

    // Regex to match @tags
    const tagRegex = /(@[a-zA-Z0-9_]+)/g;

    // Merge both regexes into a combined regex
    const combinedRegex = new RegExp(`${urlRegex.source}|${tagRegex.source}`, 'g');

    // Split text into paragraphs by newlines or double newlines
    const paragraphs = text.split(/\n+/);

    return paragraphs.map((paragraph, pIndex) => {
        const parts = [];
        let lastIndex = 0;

        // Replace matches (either URLs or @tags) with clickable links
        paragraph.replace(combinedRegex, (match, ...args) => {
            const index = args[args.length - 2];
            if (index > lastIndex) {
                // Push the text between the last match and the current match
                parts.push(paragraph.slice(lastIndex, index));
            }

            if (match.startsWith('@')) {
                // Handle @tags: Create custom link for your website
                const profileUrl = `http://localhost:3000/@${match.slice(1)}`; // Your custom URL
                parts.push(
                    <Link style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click" key={index} to={profileUrl}>
                        {match}
                    </Link>
                );
            } else {
                // Handle URLs: Make them clickable links
                let href = match;
                if (!href.startsWith('http://') && !href.startsWith('https://')) {
                    href = 'http://' + href;
                }

                parts.push(
                    <a style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click" key={index} href={href} target="_blank" rel="noopener noreferrer">
                        {match}
                    </a>
                );
            }

            lastIndex = index + match.length;
            return match; // Keep the match as it is
        });

        // Add the remaining text after the last match
        if (lastIndex < paragraph.length) {
            parts.push(paragraph.slice(lastIndex));
        }

        // Wrap the processed text in a paragraph <p> element
        return <p style={{color: 'none'}} key={pIndex}>{parts}</p>;
    });
}

const socket = io(ROOT_API_ROUTE);

function shortenText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
}

const Container = styled.div`
    flex:1;
`;


const InboxContainer = styled.div`
    padding: 10px;
    width: 500px;
    position: relative;
    align-items: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;


    @media screen and (max-width: 600px){
        width: auto;
    }


`;

const SearchChatHeader = styled.div`
    // position: sticky;
    // top: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
`;

const SearchChatHeaderIconsCon = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


const SearchContainer = styled.div`
    background: var(--twikVibe-dark-grey-bg);
    border-radius: 50px;
    padding: 5px;
    display: flex;
    
`;

const SearchInput = styled.input`
    padding: 5px;
    border: none;
    background: none;
    flex: 1;
    color: var(--twikVibe-light-text-color);
    background-color: transparent;
    font-size: 16px;
`;

const InboxListContainr = styled.div`
    flex-grow: 1;
    width: 100%;
`;

const InboxItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
`;

const InboxImageAndTextCon = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const InboxImageCon = styled.div`
    width: 35px;
    height: 35px;
    background: var(--twikVibe-dark-grey-bg);
    border-radius: 100%;
    // overflow: hidden;
`;
const InboxImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 100%;

`;
const NoProfilePicCon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: var(--twikvibe-white-color);
    border-radius:100%;
`;
const TextCon = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;
const InboxName = styled.div`
    font-size: 13px;
    font-weight: 600;
    color: var(--twikVibe-light-text-color);
    display: flex;
    align-items: center;
    gap: 10px;
`;
const VerficationImageContainer = styled.div`
    position: relative;
    width: 14px;
    height: 14px;
`;
const VerficationImage = styled.img`
    width: 100%;
    height:100%;
`;
const Check = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
`;
const InboxLastMessage = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: var(--twikVibe-light-text-color);
`;
const InboxLasTime = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: var(--twikvibe-grey-color);
    display: flex;
    flex-direction: column;
    gap: 5px;
`;


const MessageContainer = styled.div`
    // flex: 2;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
    width: 500px;
    margin: auto;

    @media screen and (max-width: 600px){
        width: 100%;
        height: 90vh;
    }
`;
const ChatHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
`;
const ChatHeaderImageAndTextCon = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const ChatHeaderImageCon = styled.div`
    width: 35px;
    height: 35px;
    background: var(--twikVibe-dark-grey-bg);
    border-radius: 100%;
    overflow: hidden;
`;
const ChatHeaderImage = styled.img`
    width: 100%;
    height: 100%;
`;
const ChatHeaderName = styled.div`
    font-size: 14px;
    font-weight: 600;
    color: var(--twikVibe-light-text-color);
`;
const ChatHeaderActive = styled.div``;
const ChatHeaderIcon = styled.div`
    cursor: pointer;
`;

const ChatMessagesCon = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    padding: 10px; 
    flex-grow: 1;
`;

const ChatMessage = styled.div`
    position: relative;
    width: auto;
    max-width: 400px;
    border-radius: 8px;
    margin-bottom: 10px;
    flex-direction: column;
    width: fit-content;
    min-width: 100px;
    align-self: ${props => (props.isMine ? 'flex-end' : 'flex-start')}; 


    @media screen and (max-width: 500px){
        max-width: 300px;
    }


    @media screen and (max-width: 300px){
        max-width: 250px;
    }


`;


const ChatMessageText  = styled.div`
    padding: 10px; 
    font-size: 15px; 
    color: ${props =>(props.isMine? 'var(--twikVibe-dark-text-color)' : 'var(--twikVibe-light-text-color)')}; 
    background: ${props => (props.isMine ? 'var(--twikVibe-light-theme-bg-color)' : 'var(--twikVibe-dark-grey-bg)')}; 
    border-radius: 30px;
    word-break: break-word;
    margin-right:  ${props =>(props.isMine? '0' : '50px')}; 
    margin-left:  ${props =>(props.isMine? '50px' : '0')}; 
`;


const ChatMessageDate = styled.div`
    font-size: 12px; 
    color: var(--twikvibe-grey-color); 
    // align-self: flex-end;
    margin-right:  ${props =>(props.isMine? '0' : '50px')}; 
    margin-left:  ${props =>(props.isMine? '50px' : '0')}; 
`;



const BottomInputContainer = styled.div`
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
`;
const MessageInputContainer = styled.div`
    display: flex;
    flex-grow: 1;
    background: var(--twikVibe-dark-grey-bg);
    border-radius: 50px;
    padding: 15px;
`;
const MessageInput = styled.textarea`
    width: 100%;
    color: var(--twikVibe-light-text-color);
    background: none;
    border: none;
    resize: none !important;
    draggable: false !important; 
    max-height: 100px;
    background-color: transparent;
    font-size: 16px;

`;
const MessageSendButton = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;
`;

const NoChatlist = styled.div`
    text-align: center;
`;
const NoMessage = styled.div``;


const ShimmeringItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;
const ShimmeringImage = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: var(--twikVibe-dark-grey-bg);

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;
        height: 100%;
        width: 150%;
        background: linear-gradient(
            90deg,
            transparent,
            var(--twikVibe-grey-color),
            transparent
        );
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;
const ShimmeringNameAndMessageCon = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const ShimmeringName = styled.div`
    padding: 6px;
    border-radius: 10px;
    background: var(--twikVibe-dark-grey-bg);
    width: 100px;

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;
        height: 100%;
        width: 150%;
        background: linear-gradient(
            90deg,
            transparent,
            var(--twikVibe-grey-color),
            transparent
        );
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;
const ShimmeringMessage = styled.div`
    padding: 5px;
    border-radius: 10px;
    background: var(--twikVibe-dark-grey-bg);
    width: 150px;

    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -150%;
        height: 100%;
        width: 150%;
        background: linear-gradient(
            90deg,
            transparent,
            var(--twikVibe-grey-color),
            transparent
        );
        animation: shimmer 2s infinite;
    }

    @keyframes shimmer {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }
`;

const UnreadContainer = styled.div`
    padding: 0px 4px;
    height: 16px;
    border-radius: 20px;
    background: var(--twikVibe-brand-color);
    color: var(--twikvibe-white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: solid 2px var(--twikVibe-dark-theme-bg-color);
    
`;


const Inboxpage = ({setShowHeader, setShowBackButton, setHideMobileNav, deviceWidth, setShowPopupMenuButton}) => {

    const [chatList, setChatList] = useState([]);
    const [filteredChatList, setFilteredChatList] = useState([])
    const userId = localStorage.getItem('userId')
    const accessToken = localStorage.getItem('accessToken')
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchChatQuery, setSearchChatQuery] = useState('');
    const [selectedUser, setSelectedUser] = useState({
        userId: null,
        fullName: null,
        profilePicture: null,
        subscribers: null,
        hasVerificationBadge: null,
        verificationBadgeType: null,
        userBackgroundColor: null,
    });
    const [messageContent, setMessageContent] = useState('');
    const chatEndRef = useRef(null);
    const textareaRef = useRef(null);

    const [showTipUser, setShowTipUser] = useState({
        userName: null,
        profilePicture: null
    })

    // remove side bar 
    // useEffect(()=>{
    //     setShowHeader(true)
    // },[setShowHeader])

    useEffect(()=>{
        setShowPopupMenuButton(false)

        if(deviceWidth<=600 && selectedUser.userId){
            setShowHeader(false);
        }else{
            setShowHeader(true)
        }
    },[deviceWidth, selectedUser.userId, setShowHeader, setShowPopupMenuButton])
        

    
    const handleChatQuery =(e)=>{
        const value = e.target.value;

        setSearchChatQuery(value)

        // Filter the chat list based on the search query
        const filtered = chatList.filter(chat => 
            chat.fullName.toLowerCase().includes(value.toLowerCase()) || 
            chat.userName.toLowerCase().includes(value.toLowerCase()) ||
            chat.lastMessage.content.toLowerCase().includes(value.toLowerCase()) 
        );

        setFilteredChatList(filtered);

    }

    
    const getUserProfile = useCallback(async({userId})=>{
        try {
            const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`,{
                headers: {Authorization: accessToken}
            });

            const userName = response.data.returnWithoutPassword.userName;
            const fullName = response.data.returnWithoutPassword.fullName;
            const profilePicture = response.data.returnWithoutPassword.profilePicture;
            const subscribers = response.data.returnWithoutPassword.subscribers;
            const hasVerificationBadge = response.data.returnWithoutPassword.hasVerificationBadge;
            const verificationBadgeType = response.data.returnWithoutPassword.verificationBadgeType;
            const userBackgroundColor = response.data.returnWithoutPassword.userBackgroundColor;
            return {fullName: fullName, profilePicture: profilePicture, userName: userName, subscribers: subscribers, hasVerificationBadge: hasVerificationBadge, verificationBadgeType: verificationBadgeType, userBackgroundColor: userBackgroundColor};
        } catch (error) {
            
        }
    },[accessToken])

    // Fetch chat list
    const fetchChatList = useCallback(async () => {
        try {
            const response = await axios.get(`${API_ROUTE}getChatList/${userId}`);
            // Sort the chat list based on the timestamp of the last message in descending order

            const sortedChatList = response.data.sort((a, b) => new Date(b.lastMessage.timestamp) - new Date(a.lastMessage.timestamp));

            const chatListWithNames = await Promise.all(
                sortedChatList.map(async (chat) => {
                    const userProfile = await getUserProfile({ userId: chat.userId });
                    return {
                        ...chat,
                        userName: userProfile.userName,
                        fullName: userProfile.fullName, // Attach the fetched full name to the chat
                        profilePicture: userProfile.profilePicture, // Attach the fetched full name to the chat
                        subscribers: userProfile.subscribers,
                        hasVerificationBadge: userProfile.hasVerificationBadge,
                        verificationBadgeType: userProfile.verificationBadgeType,
                        userBackgroundColor: userProfile.userBackgroundColor,
                    };
                })
            );

            // console.log(chatListWithNames)
            
            setChatList(chatListWithNames);
            setFilteredChatList(chatListWithNames)
            setLoading(false);
        } catch (error) {
            console.error('Error fetching chat list', error);
        }
    }, [userId, getUserProfile]);

    // Scroll to the bottom when messages are loaded or updated
    const scrollToBottom = () => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    


    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    

    useEffect(() => {
        fetchChatList();

        socket.on('chatListUpdate', fetchChatList);

        return () => {
             socket.off('chatListUpdate');
        };
    }, [userId, fetchChatList]);

    // Fetch messages with selected user
    useEffect(() => {
        if (selectedUser.userId) {
            const fetchMessages = async () => {
                const { data } = await axios.get(`${API_ROUTE}messages/${userId}/${selectedUser.userId}`);
                setMessages(data);

                // Mark messages as read
                await axios.patch(`${API_ROUTE}messages/markAsRead/${userId}/${selectedUser.userId}`);
                fetchChatList(); // Refresh chat list to update unread counts
            };

            fetchMessages();
        }
    }, [selectedUser.userId, userId, fetchChatList]);

    

    useEffect(() => {
        socket.emit('join', userId);

        socket.on('newMessage', (newMessage) => {
            if (newMessage.sender === selectedUser.userId || newMessage.receiver === selectedUser.userId) {
                setMessages((prevMessages) => [...prevMessages, newMessage]);
            }
            fetchChatList(); // Refresh chat list to update unread counts
        });

        return () => {
            socket.off('newMessage');
        };
    }, [selectedUser.userId, userId, fetchChatList]);

    const handleSendMessage = async () => {

        if(!messageContent){
            return;
        }
        await axios.post(`${API_ROUTE}messages`, {
            senderId: userId,
            receiverId: selectedUser.userId,  // This should now be the userId of the selected user
            content: messageContent,
        });
        setMessageContent('');
        const textarea = textareaRef.current;
        textarea.style.height = "auto";
        scrollToBottom(); 
    };
    
    const handleSelectUser = ({userId, profilePicture, fullName, userName, subscribers, hasVerificationBadge, verificationBadgeType, userBackgroundColor}) => {
        setSelectedUser((prevUser)=>({
            ...prevUser,
            userId: userId,
            profilePicture: profilePicture,
            fullName: fullName,
            userName: userName,
            subscribers: subscribers,
            hasVerificationBadge: hasVerificationBadge,
            verificationBadgeType: verificationBadgeType,
            userBackgroundColor: userBackgroundColor,
        }));
        setMessages([]); // Clear messages when switching user

        // hide the mobile nav when a user has been selected
        setHideMobileNav(true);
    };

    const handleInputChange = (e)=>{
        const value = e.target.value;
        setMessageContent(value);

        const maxRows = 5;

        const textarea = textareaRef.current;

        textarea.style.height = "auto";

        // Calculate the number of rows based on the scrollHeight
        const rows = Math.min(textarea.scrollHeight / textarea.offsetHeight, maxRows);

        if (rows >= maxRows) {
            textarea.style.height = `${textarea.scrollHeight}px`;
            textarea.style.overflowY = "scroll"; // Enable scrolling after reaching max rows
          } else {
            textarea.style.height = `${textarea.scrollHeight}px`;
            textarea.style.overflowY = "hidden"; // Disable scrolling while growing
          }

    }



    const goBackToChatList=async()=>{
        try {
            // Mark messages as read
            await axios.patch(`${API_ROUTE}messages/markAsRead/${userId}/${selectedUser.userId}`);
            fetchChatList();
            handleSelectUser((prev)=>({
                ...prev,
                userId: null,
                profilePicture: null,
                fullName: null,
                userName: null,
                subscribers: null,
                hasVerificationBadge: null,
                verificationBadgeType: null,
                userBackgroundColor: null,
            }))

            // show the mobile nav when a user has been unselected
            setHideMobileNav(false);
        } catch (error) {
            console.log(error)
        }
    }



    
    return (
        <Container>
            {!selectedUser.userId
            ?<InboxContainer >


                {/* the search container  ================================================================================ */}
                <SearchChatHeader>
                    <SearchChatHeaderIconsCon>
                        <h2>Inbox</h2>
                      
                    </SearchChatHeaderIconsCon>
                    <SearchContainer>
                        <SearchInput disabled={loading} onChange={(e)=>handleChatQuery(e)} value={searchChatQuery} placeholder="full name, username, last message" type="text"/>
                    </SearchContainer>
                </SearchChatHeader>
                
                {/* check if chat list is still loading ================================================================================ */}
                {!chatList.length && loading
                ?<InboxListContainr>
                    {Array.from({ length: 5 }).map((_, index) => (
                        <ShimmeringItem style={{marginBottom:'10px'}}>
                            <ShimmeringImage />
                            <ShimmeringNameAndMessageCon>
                                <ShimmeringName />
                                <ShimmeringMessage />
                            </ShimmeringNameAndMessageCon>
                        </ShimmeringItem>
                        ))}

                </InboxListContainr>

                // {/* check if chat list has loaded but no chat list */}
                :!chatList.length && !loading
                ?<InboxListContainr>
                    <NoChatlist>
                        No chat list, find a friend to chat with.
                    </NoChatlist>    
                </InboxListContainr>

                // {/* check if chat list has loaded and there is chat list */}
                :chatList.length && !loading
                ?<InboxListContainr>
                {filteredChatList.map((chat, index)=>(
                    <InboxItem style={{backgroundColor: chat.unreadCount > 0 && chat.lastMessage.receiver===userId ? 'var(--twikVibe-dark-grey-bg)' : 'var(--twikVibe-dark-theme-bg-color)'}} onClick={() => handleSelectUser({userId:chat.userId, fullName: chat.fullName, profilePicture: chat.profilePicture, userName: chat.userName, hasVerificationBadge: chat.hasVerificationBadge, verificationBadgeType: chat.verificationBadgeType, userBackgroundColor: chat.userBackgroundColor })} key={index}>
                        <InboxImageAndTextCon>
                            <InboxImageCon>
                                {chat.profilePicture
                                ?<InboxImage src={chat.profilePicture} alt="profile"/>
                                :<NoProfilePicCon style={{background: chat.userBackgroundColor}}>{chat.userName[0]}</NoProfilePicCon>}
                            </InboxImageCon>
                            <TextCon>
                                <InboxName>
                                    {/* username  */}
                                    {chat.userName}


                                    {/* verification badge */}

                                    {/* check if this user has purchased a verification badge first */}
                                    {chat.hasVerificationBadge&&
                                    <VerficationImageContainer>

                                        {/* get the exact verification badge type the user has if they have a verification badge */}
                                        <VerficationImage src={chat.verificationBadgeType==='regular'?RegularBadge : chat.verificationBadgeType==='silver'?SilverBadge : chat.verificationBadgeType==='organisation'? OrganisationBadge : chat.verificationBadgeType==='pride'?PrideBadge : null} alt="verification badge"/>
                                        <Check><FaCheck color="var(--twikVibe-dark-text-color)" size={8}/></Check>
                                    </VerficationImageContainer>}
                                </InboxName>
                                <InboxLastMessage>{shortenText(chat.lastMessage.content,40)}</InboxLastMessage>
                            </TextCon>
                        </InboxImageAndTextCon>
                        

                        {/* time last message was sent and the unread chat count */}
                        <InboxLasTime>
                            {/* the date sent  */}
                            {formatDateTime(chat.lastMessage.timestamp)} 
                            {/* unread chat count */}
                            {chat.unreadCount > 0 && chat.lastMessage.receiver===userId && <div style={{display:'flex', justifyContent:'flex-end'}}> <UnreadContainer> {chat.unreadCount}</UnreadContainer></div>}
                        </InboxLasTime>
                    </InboxItem>
                ))}
                </InboxListContainr>:null}

            </InboxContainer>
            

            // the message between two users
            :<MessageContainer>
                    <ChatHeader>
                        <ChatHeaderImageAndTextCon>
                            <ChatHeaderIcon onClick={goBackToChatList}>
                                <BiArrowBack color="var(--twikVibe-light-text-color)" />
                            </ChatHeaderIcon>

                            {/* link to the users profile */}
                            <Link style={{color:'var(--twikVibe-light-text-color)'}} className="transparent_click" to={`/@${selectedUser.userName}`}>
                                <ChatHeaderImageCon>
                                    {selectedUser.profilePicture
                                    ?<ChatHeaderImage src={selectedUser.profilePicture} alt="profile"/>
                                    :<NoProfilePicCon style={{background: selectedUser.userBackgroundColor}}>{selectedUser.userName[0]}</NoProfilePicCon>}
                                </ChatHeaderImageCon>
                            </Link>
                            <Link style={{color:'var(--twikVibe-light-text-color)'}} className="transparent_click" to={`/@${selectedUser.userName}`}>
                                <ChatHeaderName> {selectedUser.userName}</ChatHeaderName>
                            </Link>
                            {/* verification badge */}

                            {/* check if this user has purchased a verification badge first */}
                            {selectedUser.hasVerificationBadge&&
                            <VerficationImageContainer>

                                {/* get the exact verification badge type the user has if they have a verification badge */}
                                <VerficationImage src={selectedUser.verificationBadgeType==='regular'?RegularBadge : selectedUser.verificationBadgeType==='silver'?SilverBadge : selectedUser.verificationBadgeType==='organisation'? OrganisationBadge : selectedUser.verificationBadgeType==='pride'?PrideBadge : null} alt="verification badge"/>
                                <Check><FaCheck color="var(--twikVibe-dark-text-color)" size={8}/></Check>
                            </VerficationImageContainer>}
                            
                            <ChatHeaderActive></ChatHeaderActive>
                        </ChatHeaderImageAndTextCon>

                        <ChatHeaderIcon>
                            <BsThreeDotsVertical color="var(--twikVibe-light-text-color)" />
                        </ChatHeaderIcon>
                    </ChatHeader>


                    {/* show the messages between the two users  ============================= */}
                    {messages.length
                    ?<ChatMessagesCon>
                        {messages.map((msg, index)=>(
                            <ChatMessage isMine={msg.sender === userId} key={index}>
                                <ChatMessageText isMine={msg.sender === userId}>{detectAndConvertLinks(msg.content)}</ChatMessageText>
                                <ChatMessageDate isMine={msg.sender === userId}>{formatDateTime(msg.timestamp)}</ChatMessageDate>
                            </ChatMessage>
                        ))}
                        <small>all messages are end to end encrypted</small>
                        <div ref={chatEndRef} />
                    </ChatMessagesCon>

                    // there are no messages between the two users
                    :<ChatMessagesCon>
                        <NoMessage>
                            No messages between you and {selectedUser.fullName}, tell them hello
                            <small>all messages are end to end encrypted</small>
                        </NoMessage>
                    </ChatMessagesCon>}
                    

                    {/* the message in put box section  ============================================================ */}
                    <BottomInputContainer>
                        <MessageInputContainer>
                            <MessageInput rows={1} ref={textareaRef} placeholder="message" value={messageContent} onChange={(e) => handleInputChange(e)}/>
                        </MessageInputContainer>
                        <AiOutlineDollar onClick={()=>{
                                setShowTipUser((prev)=>({
                                    ...prev,
                                    userName: selectedUser.userName,
                                    profilePicture: selectedUser.profilePicture
                                }))
                            }} size={20}  style={{cursor: 'pointer'}}/>
                        
                        {/* send button */}
                        <MessageSendButton onClick={handleSendMessage} style={{
                            background: messageContent? 'var(--twikVibe-brand-color)' : 'var(--twikvibe-grey-color)'
                        }}>
                            <BsFillSendFill color="var(--twikvibe-white-color)" size={15}/>
                        </MessageSendButton>
                    </BottomInputContainer>
            </MessageContainer>}

            {showTipUser.userName&&<TipUser userName={showTipUser.userName} profilePicture={showTipUser.profilePicture} setShowTipUser={setShowTipUser}/>}
        </Container>

    )
}

export default Inboxpage



