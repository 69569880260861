import axios from "axios";
import {  useEffect, useState } from "react";
import styled, { keyframes } from "styled-components"
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_ROUTE } from "../utils/Api";
import { refreshAccessToken } from "../utils/RefreshAccessToken";



const Container = styled.div`
    padding: 20px;
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;

`;

const MiddleSection = styled.form`
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    

    @media screen and (max-width: 500px){
        width: 100%;
    }
`;
const Inputabel = styled.label`
    color: var(--twikVibe-light-text-color);
    font-size: 13px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 5px;


`;
const InputContainer = styled.label`
    display: flex;
    padding: 8px;
    background-color: var(--twikVibe-dark-grey-bg);
    border-radius: 10px;
    border: 2px solid var(--twikVibe-dark-grey-bg);
    align-items: center;
    gap: 5px;
    justify-content: space-between;

    &:focus-within{
        border: 2px solid var(--twikVibe-brand-color);
    }
`;
const Input = styled.input`
    flex: 1;
    width: 100%:
    background: none;
    border: none;
    padding: 5px;
    color: var(--twikVibe-light-text-color);
    font-weight: 500;
    background-color: transparent;
    font-size: 16px;
    // font-weight: 500;
`;

const ActionButton = styled.button`
    color: var(--twikvibe-white-color);
    background: var(--twikVibe-brand-color);
    font-size: 13px;
    border-radius: 50px;
    padding: 15px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    font-weight: 500;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;

// email found section, time to enter OTP
const CodeInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0px;
`;


const CodeInput = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }

    border: solid 2px var(--twikVibe-dark-grey-bg);
    background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    border-radius: 10px;
    outline: none;
    flex: 1;
    width: 60%;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    transition: 0s;
    padding: 5px;

    &:focus{
        border: solid 2px var(--twikVibe-brand-color);
    }
`;



const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:3.5px;
    background-color:transparent;
    border-radius:100px;
    width:3px;
    height:3px;
    border-style:solid;
    border-left-color:var(--twikvibe-white-color);
    border-top-color:var(--twikvibe-white-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
`;

const ForgotPassword = styled.div`
    background: var( --twikVibe-dark-theme-bg-color);
    padding: 5px 10px;
    border-radius: 15px;
    color: var(--twikvibe-grey-color);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

`;

export const ResetTransactionPin = ({setShowPopupMenuButton}) => {

    const inputLength = 4;

    const [transactionPin, setTransactionPin] = useState(new Array(inputLength).fill(''));
    


    // state to check if the number of entered pin is up to 4 
    const [pinComplete, setPinComplete] = useState(false);

    // the completed pin
    const [completedPin, setCompletedPin] = useState(null);

    const [passwordIsCorrect, setPasswordIsCorrect] = useState(false);

    const [password, setPassword] = useState('');
    const [error, setError] = useState('')
    const accessToken = localStorage.getItem('accessToken')
    const [loading, setLoading] = useState(false)

    // state to check if the number of entered pin is up to 4 


    useEffect(()=>{
        setShowPopupMenuButton(false)
    },[setShowPopupMenuButton])



    const navigate = useNavigate();




    const handlePinInputChange = (e, index)=>{
        const value = e.value;
        setError('');

        
        // Ensure the input is a number and of a single character
        if (isNaN(value) || value.length > 1) return;

        const pin = [...transactionPin];
        pin[index] = value;
        setTransactionPin(pin)

        if(index < inputLength - 1 && value){
            e.nextSibling.focus();
        }

        // Check if all inputs are filled
        const allFilled = pin.every(val => val.length === 1);

        setCompletedPin(pin.join(''))
        
        if (allFilled) {
            setPinComplete(true); 
        }else{
            setPinComplete(false)
        }
    }

    const handleBackspace=(e, index)=>{
        const pin = [...transactionPin];
        pin[index] = '';
        setTransactionPin(pin)

        if(index > 0){
            e.previousSibling.focus();
        }
    }

    const resetTransactionPin=async(e)=>{
        e.preventDefault();
        setLoading(true)

        if(!password){
            setError('Please enter your password')
            setLoading(false)
            return;
        }

        if(!pinComplete){
            setError("Please enter a valid pin")
            setLoading(false)
            return;
        }


        try {
            const response = await axios.post(`${API_ROUTE}resetTransactionPin`,{
                transactionPin: completedPin,
                password: password,
            },{
                headers: {Authorization: accessToken}
            })

            if(response.status === 200){
                setLoading(false)
                navigate(-1)
            }
            
            
        } catch (error) {
            if(error.status===403){
                const {accessToken} = await refreshAccessToken()

                if(accessToken){
                    const response = await axios.post(`${API_ROUTE}resetTransactionPin`,{
                        transactionPin: completedPin,
                        password: password,
                    },{
                        headers: {Authorization: accessToken}
                    })
        
                    if(response.status === 200){
                        setLoading(false)
                        navigate(-1)
                    }
                }
            }else{
                setError(error.response.data.error)
            }
        }
        
    }

    const confirmPassword =async(e)=>{
        e.preventDefault();
        setLoading(true);

        if(!password){
            setError('Please enter your password')
            setLoading(false);
            return;
        }
        try {
            await axios.post(`${API_ROUTE}confirmPassword`,{
                password
            },{
                headers: {Authorization: accessToken}
            });

            setPasswordIsCorrect(true)
            setLoading(false);
        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    await axios.post(`${API_ROUTE}confirmPassword`,{
                        password
                    },{
                        headers: {Authorization: accessToken}
                    });
        
                    setPasswordIsCorrect(true)
                    setLoading(false);
                }
            }else{
                setError(error.response.data.error)
                setLoading(false);
            }
        }
    }
    
    return (
        <>
        <Helmet>
            <title>Reset Transaction Pin</title>
        </Helmet>

        <Container>
            {passwordIsCorrect
            //check if the entered is correct first
            ?<MiddleSection>
                <Inputabel>
                    Enter a new transaction pin
                    <CodeInputContainer>
                        {transactionPin.map((data, index) => (
                            <CodeInput
                                disabled={loading}
                                key={index}
                                maxLength={1}
                                type="text"
                                value={data}
                                onChange={(e) => handlePinInputChange(e.target, index)}
                                placeholder="-"
                                onKeyDown={(e)=>{
                                    if(e.key==='Backspace'){
                                        handleBackspace(e.target, index)
                                    }
                                }}
                            />
                        ))}
                    </CodeInputContainer>
                </Inputabel>

                

                {error&&<div style={{color:'red', fontSize: '13px', fontWeight:'600'}}>{error}</div>}
                
                {pinComplete
                ?<>
                    {loading
                    ?<ActionButton disabled={loading} ><LoadingSpinnerElement/></ActionButton>
                    :<ActionButton disabled={loading} type='submit' onClick={resetTransactionPin}>Save pin</ActionButton>}
                </>
                :<ActionButton style={{background:'var(--twikvibe-grey-color)', cursor:'not-allowed'}}>Save pin</ActionButton>}
                
                
            </MiddleSection>
            

            :<MiddleSection onSubmit={confirmPassword}>
                <Inputabel>
                    <InputContainer>
                        <Input disabled={loading}  value={password} placeholder="Enter your password" onChange={(e)=>{setPassword(e.target.value); setError('')}} />
                        <Link className="transparent_click" to='/reset-password'><ForgotPassword>Forgot?</ForgotPassword></Link>
                    </InputContainer>
                </Inputabel>

           
                {error&&<div style={{color:'red', fontSize: '13px', fontWeight:'600'}}>{error}</div>}
                

                {password
                ?<>
                    {loading
                    ?<ActionButton disabled={loading} ><LoadingSpinnerElement/></ActionButton>
                    :<ActionButton disabled={loading} type='submit' onClick={confirmPassword}>Continue</ActionButton>}
                </>
                :<ActionButton disabled={loading || !password}  style={{background:'var(--twikvibe-grey-color)', cursor:'not-allowed'}}>Continue</ActionButton>}
            </MiddleSection>}

            
        </Container>
        </>
    )
}
