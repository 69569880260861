import CopyToClipboard from "react-copy-to-clipboard";
import styled from "styled-components"
import PopupMessage from "./PopupMessage";
import { useState } from "react";
import { AiOutlineLink } from "react-icons/ai";


const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    z-index: 12;
    gap: 50px;
`;

const ImageContainer = styled.div`
    width: 300px;
    height: 300px;
    overflow: hidden;
    border-radius: 100%;


    @media screen and (max-width: 350px){
        width: 270px;
        height: 270px;
    }
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const ActionsContainer = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;
`;
const Action = styled.div`
    cursor: pointer;
    font-size: 14px;
    color: var(--twikVibe-light-text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;

const ActionIcon = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 100%;
    cursor: pointer;
    font-size: 14px;
    background-color: var(--twikVibe-dark-theme-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
`;


const FullProfileImage = ({image, username, profileLink, setShowFullProfileImage}) => {

    // popupmessage state
    const [showPopupMessage, setShowPopupMessage] = useState(false);
    const [popupMessage, setPopupMessage] = useState('')

    const closeModal = ()=>{
        setShowFullProfileImage(false)
    }
    return (
        <Container onClick={closeModal}>
            <ImageContainer onClick={(e)=>e.stopPropagation()}>
                <Image src={image} alt={`${username}'s profile pic.`}/>
            </ImageContainer>

            <ActionsContainer onClick={(e)=>e.stopPropagation()}>

                
                <CopyToClipboard text={profileLink} onCopy={()=>{
                    setPopupMessage('Copied');
                    setShowPopupMessage(true)
                    console.log('copied')
                }}>
                    <Action>
                        <ActionIcon><AiOutlineLink color="var(--twikVibe-light-text-color)" size={25} /></ActionIcon>
                        Copy link
                    </Action>
                </CopyToClipboard>
                
            </ActionsContainer>

            {/* popup message  */}
            {showPopupMessage&&<PopupMessage message={popupMessage} setShowPopupMessage={setShowPopupMessage} showPopupMessage={showPopupMessage}/>}

        </Container>
    )
}

export default FullProfileImage