import { useState } from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
    padding: 10px;
    border-radius: 100%;
    background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
const IconButton = ({icon, onClick}) => {
    const [scale, setScale] = useState(1);

    const handleClick = () => {
        // Increase the scale on click
        setScale(prevScale => prevScale - 0.09);
        
        // Reset scale after a short delay
        setTimeout(() => {
        setScale(1);
        }, 200);
    };
    return (
        <IconContainer onClick={(e)=>{ e.stopPropagation(); handleClick(); onClick()}} style={{
            transform: `scale(${scale})`,
        }}>{icon}</IconContainer>
    )
}

export default IconButton