
import styled from "styled-components";
import { IoPerson } from "react-icons/io5";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5"
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FaShield } from "react-icons/fa6";
import { MdOutlineHelp } from "react-icons/md";



const Container = styled.div`
    flex: 1;
`;

const SettingsContainer = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: auto;
    aling-items:center;    
    
    @media screen and (max-width: 600px){
        width: auto;
    }
`;

const HeaderContainer = styled.h4`
    display: flex;
    align-items: center;
    color: var(--twikVibe-light-text-color);
    font-size: 16px;
    gap: 20px;
    padding:  10px;
    position: sticky;
    top: 0;

    @media screen and (max-width: 600px){
        top: 50px;
    }

    
    
`;
const SettingsItemsContainer = styled.div`
    // display: flex;
    // flex-direction: column;
    // gap: 10px;
`;
const SettingsItem = styled.div`
    border-radius: 10px;
    padding: 15px;
    // border: solid 1px var(--twikvibe-grey-color);
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    width: 100%;

    &:hover{
        background: var(--twikVibe--darker-transparent-black-color);
    }

    @media screen and (max-width: 600px){
        &:hover{
            background: none;
        }
    }
`;
const SettingsIconContainer = styled.div`
    
`;
const SettingsTextContainer = styled.div`
    flex-direction: column;
    gap: 2px;
`;
const SettingsTitle = styled.div`
    color: var(--twikVibe-light-text-color);
    font-size: 15px;
`;

const DangerZoneContainer = styled.div``;
const DangerZoneItem = styled.div`
    background: var(--twikVibe-red-danger-zone-bg-color);
    border-radius: 10px;
    padding: 10px;
    border: solid 1px var(--twikVibe-red-danger-zone-btn-color);
    display: flex;
    gap: 10px;
    justify-content: space-between;

    @media screen and (max-width: 600px){
        margin: 0px 10px;
    }
`;
const DangerZoneIconContainer = styled.div`

`;
const DangerZoneTextContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;
const DangerZoneTitle = styled.h1`
    color: var(--twikvibe-white-color);
    font-size: 15px;
`;

const DangerZoneDeleteButton = styled.div`
    color: var(--twikvibe-white-color);
    background: var(--twikVibe-red-danger-zone-btn-color);
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
`;

const Settingspage = ({setShowHeader, deviceWidth}) => {

    const settings = [
        {
            icon: <IoPerson style={{color:'var(--twikVibe-light-text-color)'}} size={20}/>,
            title: "My Account",
            link: '/settings/my-account'
        },
        // {
        //     icon: <FaMoneyBillAlt style={{color:'var(--twikVibe-light-text-color)'}} size={20}/>,
        //     title: "Monetization",
        //     link: 'settings/monetization'
        // },
        {
            icon: <MdOutlineRemoveRedEye style={{color:'var(--twikVibe-light-text-color)'}} size={25}/>,
            title: "Display & Languages",
            link: "/settings/display"
        },
        {
            icon: <IoIosCheckmarkCircle style={{color:'var(--twikVibe-light-text-color)'}} size={25}/>,
            title: "Verification Badge",
            link: "/settings/get-verification-badge"
        },
        {
            icon: <FaShield style={{color:'var(--twikVibe-light-text-color)'}} size={25}/>,
            title: "Security & Privacy",
        },
        {
            icon: <MdOutlineHelp style={{color:'var(--twikVibe-light-text-color)'}} size={25}/>,
            title: "Help Center",
        },
        
    ];


    const navigate = useNavigate();

    // remove side bar 
    useEffect(()=>{
        setShowHeader(true)
    },[setShowHeader])

    


    const goBack=()=>{
        navigate(-1);
    }

    // navigate user to account setting page first as the first on the list
    

    return(
        <Container>
            <SettingsContainer>

                
                <HeaderContainer>
                    {/* <IconButton onClick={goBack} icon={<FaArrowLeftLong />}/> */}
                    {deviceWidth>600&&<IoArrowBackOutline style={{cursor:'pointer'}} color="var(--twikVibe-light-text-color)" onClick={goBack} />}
                    Settings
                </HeaderContainer>
                {/* {deviceWidth} */}

                <SettingsItemsContainer>
                    {settings.map((setting, index)=>(
                        <Link style={{color:'var(--twikVibe-light-text-color)',display:'flex',alignItems:"center"}} className="transparent_click" key={index} to={setting.link}>
                            <SettingsItem >
                                <SettingsIconContainer >{setting.icon}</SettingsIconContainer>
                                <SettingsTextContainer>
                                    <SettingsTitle>{setting.title}</SettingsTitle>
                                </SettingsTextContainer>
                            </SettingsItem>
                        </Link>
                    ))}
                </SettingsItemsContainer>

                {/* danger zone area  */}
                <DangerZoneContainer>
                    <DangerZoneItem>

                        <DangerZoneTextContainer>
                            <DangerZoneIconContainer><IoPerson color="var(--twikvibe-white-color)"/></DangerZoneIconContainer>
                            <DangerZoneTitle>Delete Account</DangerZoneTitle>
                        </DangerZoneTextContainer>

                        <DangerZoneDeleteButton>Delete</DangerZoneDeleteButton>
                    </DangerZoneItem>
                </DangerZoneContainer>


            </SettingsContainer>
        </Container>
    )
}

export default Settingspage