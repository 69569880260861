import { useState } from "react";
import styled, { keyframes } from "styled-components";
import { IoClose } from "react-icons/io5";
import { API_ROUTE } from "../utils/Api";
import axios from "axios";
import { IoArrowBackOutline } from "react-icons/io5"
import { refreshAccessToken } from "../utils/RefreshAccessToken";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import RegularBadge from "../assets/icons/regular_verification_badge.svg";
import SilverBadge from "../assets/icons/silver_verification_badge.svg";
import OrganisationBadge from "../assets/icons/business_verification_badge.svg";
import PrideBadge from "../assets/icons/pride_verification_tag.svg";
import { FaLockOpen } from "react-icons/fa6";
import { FaLock } from "react-icons/fa6";

function detectAndConvertLinks(text) {
    // Regex to match URLs
    const urlRegex = /((https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))/g;

    // Regex to match @tags
    const tagRegex = /(@[a-zA-Z0-9_]+)/g;

    // Merge both regexes into a combined regex
    const combinedRegex = new RegExp(`${urlRegex.source}|${tagRegex.source}`, 'g');

    // Split text into paragraphs by newlines or double newlines
    const paragraphs = text.split(/\n+/);

    return paragraphs.map((paragraph, pIndex) => {
        const parts = [];
        let lastIndex = 0;

        // Replace matches (either URLs or @tags) with clickable links
        paragraph.replace(combinedRegex, (match, ...args) => {
            const index = args[args.length - 2];
            if (index > lastIndex) {
                // Push the text between the last match and the current match
                parts.push(paragraph.slice(lastIndex, index));
            }

            if (match.startsWith('@')) {
                // Handle @tags: Create custom link for your website
                parts.push(
                    <span style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click" key={index}>
                        {match}
                    </span>
                );
            } else {
                // Handle URLs: Make them clickable links
                let href = match;
                if (!href.startsWith('http://') && !href.startsWith('https://')) {
                    href = 'http://' + href;
                }

                parts.push(
                    <span style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click" key={index} >
                        {match}
                    </span>
                );
            }

            lastIndex = index + match.length;
            return match; // Keep the match as it is
        });

        // Add the remaining text after the last match
        if (lastIndex < paragraph.length) {
            parts.push(paragraph.slice(lastIndex));
        }

        // Wrap the processed text in a paragraph <p> element
        return <p style={{color: 'var(--twikVibe-light-text-color)'}} key={pIndex}>{parts}</p>;
    });
}
const formatTime = (date) => {
    const parsedDate = new Date(date);
    
    // Check if the parsedDate is valid
    if (isNaN(parsedDate)) {
      return "Invalid date";
    }
  
    const now = new Date();
    const secondsAgo = Math.floor((now - parsedDate) / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
  
    if (secondsAgo < 60) return `${secondsAgo}s ago`;
    if (minutesAgo < 60) return `${minutesAgo}m ago`;
    if (hoursAgo < 24) return `${hoursAgo}h ago`;
    if (daysAgo <= 7) return `${daysAgo}d ago`;

    const formattedDate = `${parsedDate.getDate().toString().padStart(2, '0')}/${(parsedDate.getMonth() + 1).toString().padStart(2, '0')}/${parsedDate.getFullYear()}`;
  
    // For more than 7 days, return formatted date MM/DD/YY
    return formattedDate;
};


const Container = styled.div`
    
`;
const ModalBG = styled.div`
    background: var(--twikVibe--darker-transparent-black-color);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;

    @media screen and (max-width: 600px){
        padding: 10px;
    }
`;
const PayContainer = styled.div`
    background: var(--twikVibe-dark-grey-bg );
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    width: 350px;
    position: relative;
    align-items: center;
    max-height: 90vh;
    overflow-y: auto;
`;
const PayHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const PostContainer = styled.div `
    // padding: 10px;
    // padding-left:20px;
    display:flex;
    flex-direction:column;
    gap:10px;
    background-color: var(--twikVibe-dark-grey-bg);
    border-radius: 10px;
    position: relative;
    width: 100%;
    user-select: none;
`;


const PostProfileParentContainer=styled.div`
    display:flex;
    align-items:center;
    gap: 10px;
`;

const PostProfilePicsCon= styled.div`
    background:grey;
    border-radius:100%;
    width:30px;
    height:30px;
`;

const PostProfilePics= styled.img`
    border-radius:100%;
    width:100%;
    height:100%;
    object-fit: cover;
`;

const PostProfileContainer = styled.div`
    display:flex;
    align-items:center;
    gap:1em;
    justify-content:space-between;
`;

const PostProfileUserNameContainer = styled.div`
    display:flex;
    flex-direction:column;
    gap:5px;
    position: relative;
`;

const PostProfileUserName = styled.h4`
    color:var(--twikVibe-light-theme-color);
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 10px;

    &:hover + .profileDetailHover{
        visibility: visible;
        opacity: 1;
    }
`;

const VerficationImageContainer = styled.div`
    position: relative;
    width: 15px;
    height: 15px;
`;
const VerficationImage = styled.img`
    width: 100%;
    height:100%;
`;
const Check = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
`;

const PostTimeStamp = styled.div`
    font-size:11px;
    color:var(--twikVibe-light-theme-color);
`;




const PostContentContainer = styled.div`
    display:flex;
    flex-direction:column;
    // padding:5px;
    color:var(--twikVibe-light-theme-color);
    gap:1em;
    position: relative;
`;

const PostContent= styled.div`
    line-height:1.5em;
    font-size: 14px;
    word-break: break-word;
    color:var(--twikVibe-light-theme-color);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    
`;

const PostImagesContainer = styled.div`
    // display: grid;
    // gap: 10px;
    // grid-template-columns: repeat(3, 1fr);
`;

const PostImage =styled.div`
    background: var(--twikvibe-grey-color);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
`;

const DeductedText = styled.div`
    color: var(--twikvibe-grey-color);
    font-size: 12px;
    font-weight: 600;
`;
const ActionButton = styled.button`
    width: 100%;
    padding: 10px;
    background: var(--twikVibe-brand-color);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: var(--twikvibe-white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;



const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:2px;
    height:2px;
    border-style:solid;
    border-left-color:var(--twikVibe-light-text-color);
    border-top-color:var(--twikVibe-light-text-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
`;

const CodeInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0px;
    width: 100%;
`;
const CodeInput = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }
    
    border-radius: 10px;
    outline: none;
    border: solid 2px var(--twikVibe-dark-grey-bg);
    background: var(--twikVibe-dark-theme-color);
    color: var(--twikVibe-light-text-color);
    flex: 1;
    width: 60%;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    transition: 0s;
    padding: 5px;

    &:focus{
        border: solid 2px var(--twikVibe-brand-color);
    }
`;

const ResetPin = styled.div`
    font-size: 12px;
    text-align: right;
`;

const TransactionPinText = styled.div`
    color: var(--twikVibe-light-text-color);
    font-size: 14px;
    font-weight: 600;
    width: 250px;
    display: flex;
    flex-direction: column;
    gap: 5px;

`;
const PayToSeePost = ({setShowPayToSeePost, post, postOwnerUsername}) => {


    const [paying, setPaying] = useState(false);

    const [error, setError] = useState('');

    const [acceptedToMakePayment, setAcceptedToMakePayment] = useState(false);

    const [paymentSuccessful, setPaymentSucessful] = useState(false)

    const [hideLock, setHideLock] = useState(true)
    
    const inputLength = 4;
    
    // the array for the transaction pin input
    const [transactionPin, setTransactionPin] = useState(new Array(inputLength).fill(''));

    // state to check if the number of entered pin is up to 4 
    const [pinUpTo4Digits, setPinUpTo4Digits] = useState(false);

    // the completed pin
    const [completedPin, setCompletedPin] = useState(null);

    const accessToken = localStorage.getItem('accessToken')
    // const userId = localStorage.getItem('userId')

    const closeModal = ()=>{
        setShowPayToSeePost((prev)=>({
            ...prev,
            _id: null
        }))
    }



    const handlePinInputChange = (e, index)=>{
        const value = e.value;
        setError('');

        
        // Ensure the input is a number and of a single character
        if (isNaN(value) || value.length > 1) return;

        const pin = [...transactionPin];
        pin[index] = value;
        setTransactionPin(pin)

        if(index < inputLength - 1 && value){
            e.nextSibling.focus();
        }

        // Check if all inputs are filled
        const allFilled = pin.every(val => val.length === 1);

        setCompletedPin(pin.join(''))
        
        if (allFilled) {
            setPinUpTo4Digits(true); 
        }else{
            setPinUpTo4Digits(false)
        }
    }

    const handleBackspace=(e, index)=>{
        const pin = [...transactionPin];
        pin[index] = '';
        setTransactionPin(pin)

        if(index > 0){
            e.previousSibling.focus();
        }
    }

    const payToSeePost = async()=>{
        setPaying(true)
        setError('')


        try {
            const response = await axios.post(`${API_ROUTE}payToSeePost`,{
                postId: post._id, 
                transactionPin: completedPin
            },{
                headers: {Authorization:accessToken}
            })
            if(response){
                setPaying(false);
                setPaymentSucessful(true);
            }
        } catch (error) {

            // check if the error is caused by expired token
            if(error.status===403){
                const {accessToken} = await refreshAccessToken();

                if(accessToken){
                    const response = await axios.post(`${API_ROUTE}payToSeePost`,{
                        postId: post._id, 
                        transactionPin: completedPin
                    },{
                        headers: {Authorization:accessToken}
                    })
                    if(response){
                        setPaying(false);
                        setPaymentSucessful(true)
                    }
                }
            }else{
                setError(error.response.data.error)
                setPaying(false)
            }
        }
    }


    if(post._id){
        document.body.style.overflow = 'hidden';
    }else{
        document.body.style.overflow = 'auto';
    }

    return (
        <Container>
            <ModalBG onClick={closeModal} title="close modal">

                {/* This container is for confirming the payment */}
                {!acceptedToMakePayment&&!paymentSuccessful&&
                <PayContainer onClick={(e)=>e.stopPropagation()} title="">
                    <PayHeader>
                        <div/>
                        <IoClose onClick={closeModal} size={20} style={{color:'var(--twikVibe-light-text-color)', cursor:'pointer', }} />
                    </PayHeader>


                    <PostContainer>
                        {/* Post profile container beginning */}
                        <PostProfileContainer>

                            <PostProfileParentContainer>

                                {/* Post profile pics beginning */}
                                <PostProfilePicsCon>
                                    <PostProfilePics src={post.profilePicture}/>
                                    <Check/>
                                </PostProfilePicsCon>
                            
                                {/* Post UserName Section Beginning */}
                                <PostProfileUserNameContainer>
                                    <PostProfileUserName>
                                        {post.userName}

                                        {/* verification badge */}

                                        {/* check if this user has purchased a verification badge first */}
                                        {post.hasVerificationBadge&&
                                        <VerficationImageContainer>

                                            {/* get the exact verification badge type the user has if they have a verification badge */}
                                            <VerficationImage src={post.verificationBadgeType==='regular'?RegularBadge : post.verificationBadgeType==='silver'?SilverBadge : post.verificationBadgeType==='organisation'? OrganisationBadge : post.verificationBadgeType==='pride'?PrideBadge : null} alt="verification badge"/>
                                            <Check><FaCheck color="var(--twikVibe-dark-text-color)" size={8}/></Check>
                                        </VerficationImageContainer>}

                                    </PostProfileUserName>

                                    <PostTimeStamp>{formatTime(post.timeStamp)}</PostTimeStamp>
                                </PostProfileUserNameContainer>
                                
                        
                            </PostProfileParentContainer>
                            

                        
                        </PostProfileContainer>
                        {/* Profile Profile Container ending  */}

                        <PostContentContainer>
                            <PostContent>{detectAndConvertLinks(post.content)}</PostContent>
                        </PostContentContainer>

                        {/* image  */}
                        <PostImagesContainer>
                            <PostImage>{hideLock?<FaLock size={30} color="#aaa"/>:<FaLockOpen size={30} color="#aaa"/>}</PostImage>
                        </PostImagesContainer>
                    </PostContainer>
                    

                    <ActionButton onClick={()=>setAcceptedToMakePayment(prev=>!prev)} onMouseDown={()=>setHideLock(prev=>!prev)} onMouseEnter={()=>setHideLock(prev=>!prev)} onMouseLeave={()=>setHideLock(prev=>!prev)}>Pay {Number(post.postPrice).toLocaleString("en-US", {style:"currency", currency:"USD"})} to view this content.</ActionButton>
                    
                </PayContainer>}


                {/* show them a page to enter their pin */}
                {acceptedToMakePayment&&!paymentSuccessful&&
                <PayContainer onClick={(e)=>e.stopPropagation()} title="">

                    <PayHeader>
                        <IoArrowBackOutline onClick={()=>setAcceptedToMakePayment(prev=>!prev)} size={20} style={{color:'var(--twikVibe-light-text-color)', cursor:'pointer', }} />
                        <IoClose onClick={closeModal} size={20} style={{color:'var(--twikVibe-light-text-color)', cursor:'pointer', }} />
                    </PayHeader>

                    
                    <TransactionPinText>
                        Transaction pin
                        <CodeInputContainer>
                            {transactionPin.map((data, index) => (
                                <CodeInput
                                    disabled={paying}
                                    key={index}
                                    maxLength={1}
                                    type="text"
                                    value={data}
                                    onChange={(e) => handlePinInputChange(e.target, index)}
                                    placeholder="-"
                                    onKeyDown={(e)=>{
                                        if(e.key==='Backspace'){
                                            handleBackspace(e.target, index)
                                        }
                                    }}
                                />
                            ))}
                            {/* {!verifyingCode&&<div style={{width: '3px', padding:'10px', marginLeft:'20px'}}/>} */}
                            {/* {verifyingCode&&<LoadingSpinnerElement/>} */}
                        </CodeInputContainer> 
                        {/* reset transaction pin button */}
                        <ResetPin><Link to='/reset-transaction-pin' style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click">Reset pin?</Link></ResetPin> 
                    </TransactionPinText>
                
                
                

                    {error&&<div style={{color:'red', fontSize: '13px', fontWeight:'500'}}>{error}</div>}
                    
                    <DeductedText>The Amount is Automatically <br/> deducted from your balance.</DeductedText>

                    {pinUpTo4Digits    ?    (
                        <>
                            {paying
                                ?<ActionButton style={{ cursor:'not-allowed'}}><LoadingSpinnerElement style={{borderLeftColor: 'var(--twikvibe-white-color)', borderTopColor: 'var(--twikvibe-white-color)'}}/></ActionButton>
                                :<ActionButton onClick={payToSeePost}>Confirm payment</ActionButton>}

                        </>
                    )   :   (
                        <ActionButton style={{background:'var(--twikvibe-grey-color)', cursor:'not-allowed'}}>Confirm payment</ActionButton>
                    )}
                    
                </PayContainer>}

                {/* show a successful icon gif */}
                {paymentSuccessful&&
                <PayContainer>
                    sucessful <a style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click" href={`/@${postOwnerUsername}/post/${post._id}`}>View post</a>
                </PayContainer>}
            </ModalBG>
        </Container>
    )
}

export default PayToSeePost