import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components"
import { API_ROUTE } from "../utils/Api";
import { refreshAccessToken } from "../utils/RefreshAccessToken";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoPaperPlane } from "react-icons/io5";
import { IoLinkOutline } from "react-icons/io5";
import IconButton from "../components/IconButton";
import { HiMiniWallet } from "react-icons/hi2";
import { MdBlockFlipped } from "react-icons/md";
import { LuShare } from "react-icons/lu";
import { TiWarningOutline } from "react-icons/ti";
import {CopyToClipboard} from 'react-copy-to-clipboard';
// import {QRCode} from 'react-qrcode-logo';
// import { IoCopyOutline } from "react-icons/io5";
import { GrStatusGood } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { FaAngleRight } from "react-icons/fa6";
import Posts from "../components/Posts";
import TipUser from "../components/TipUser";
import { FaCheck } from "react-icons/fa6";
import RegularBadge from "../assets/icons/regular_verification_badge.svg";
import SilverBadge from "../assets/icons/silver_verification_badge.svg";
import OrganisationBadge from "../assets/icons/business_verification_badge.svg";
import PrideBadge from "../assets/icons/pride_verification_tag.svg";
import PopupMessage from "../components/PopupMessage";
import FullProfileImage from "../components/FullProfileImage";



function shortenText(text, maxLength) {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    }
    return text;
}



const Container = styled.div`
    flex: 1;
`;

const ProfileContainer = styled.div`
    width: 600px;
    margin: auto;
    // padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 650px){
        width: auto;
    }
`;


const InnerProfileContainer = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

`;

const ProfileImageSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const ProfileImageContainer = styled.div`
    width: 90px;
    height:  90px;
    border-radius: 100%;
    overflow: hidden;
    background: var(--twikVibe-dark-grey-bg);
    cursor: pointer;
    
`;
const ProfileImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: var(--twikvibe-grey-color);
`;
const NoProfilePicCon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: var(--twikvibe-white-color);
    border-radius:100%;
    font-size: 20px;
    font-weight: 600;
`;
const ProfileTopIconsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
`;
const WalletBalance = styled.div`
    padding: 10px;
    border-radius: 50px;
    background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 10px;
    font-size: 15px;
`;
const ProfilePopupContainer = styled.div`
    position: absolute;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0,0,0,0.1);
    // border: solid .2px var(--twikvibe-grey-color);
    background: var(--twikVibe-dark-grey-bg);
    width: 180px;
    top: 50px;
    right: 0px;
    z-index: 12;
`;

const ProfilePopupItem = styled.div`
    padding: 15px;
    border-radius: 10px;
    background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;

    &:hover{
        background: var(--twikVibe--darker-transparent-black-color);
    }
`;

const SendMessagePopup  = styled.form`
    position: absolute;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0,0,0,0.2);
    z-index: 12;
    background: var(--twikVibe-dark-theme-bg-color);
    display: flex;
    flex-direction: column;
    gap: 10px;
    top: 50px;
    right: 0px;
`;
const SendMessagePopupText  = styled.div`
    font-size: 15px;
    color: var(--twikVibe-light-text-color);
`;
const SendMessagePopupInputContainer  = styled.div`
    display: flex;
    padding: 10px;
    background-color: var(--twikVibe-dark-grey-bg);
    border-radius: 10px;
    align-items: center;
    gap: 5px;
    min-width: 200px;
`;
const SendMessagePopupInput  = styled.textarea`
    width: 100%;
    font-size: 16px;
    color: var(--twikVibe-light-text-color);
    background: none;
    border: none;
    resize: none !important;
    draggable: false !important; 
    max-height: 100px;
`;
const SendMessagePopupButton  = styled.button`
    padding: 10px;
    border-radius: 50px;
    background: var(--twikVibe-brand-color);
    color: var(--twikvibe-white-color);
    cursor: pointer;
    border: none;
    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;

const ReportContainerBackground = styled.div`
    background: var(--twikVibe--darker-transparent-black-color);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;

    padding: 10px;
`;
const ReportContainer = styled.div`
    background: var(--twikVibe-dark-grey-bg);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    max-height: 90vh;
    overflow-y: auto;
    width: 600px;
`;

const ReportHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const ReportHeaderMainTitle = styled.h1`
    color: var(--twikVibe-light-text-color);
    font-size: 30px;
`;
const ReportHeaderTitle = styled.div`
    color: var(--twikVibe-light-text-color);
    font-size: 20px;
    font-weight: 600;
`;
const ReportHeaderDescription = styled.div`
    color: var(--twikvibe-grey-color);
    border-bottom: solid .2px var(--twikvibe-grey-color);
    padding-bottom: 10px;
    font-size: 15px;
`;
const ReportItemsContainer = styled.div``;
const ReportItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--twikVibe-light-text-color);
    padding: 15px;
    cursor: pointer;
    font-size: 15px;

    &:hover{

    }
`;

const ProfileNameContainer = styled.div`
    display:flex;
    flex-direction:column;
    gap:10px;
    position: relative;
`;

const UsernameContainer = styled.h4`
    color:var(--twikVibe-light-theme-color);
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 10px;


`;


const VerficationImageContainer = styled.div`
    position: relative;
    width: 18px;
    height: 18px;
`;
const VerficationImage = styled.img`
    width: 100%;
    height:100%;
`;
const Check = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
`;

const UserFullName = styled.div`
    color: var(--twikVibe-light-text-color);
    font-size: 18px;
    font-weight: 600;
`;
const Username = styled.div`
    color: var(--twikvibe-grey-color);
    font-size: 15px;
`;
const Bio = styled.div`
    color: var(--twikVibe-light-text-color);
`;
const AnalyticsSection = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const AnalyticsItem = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--twikvibe-grey-color);

    &:hover{
        text-decoration: underline;
    }
`;
const AnalyticsNumber = styled.div`
    color: var(--twikVibe-light-text-color);
    font-size: 16px;
    // font-weight: 600;
`;
const AnalyticsText = styled.div`
    color: var(--twikvibe-grey-color);
    font-size: 15px;
`;
// const AnalyticsDot = styled.div`
//     background: var(--twikvibe-grey-color);
//     padding: 2px;
//     border-radius: 100%;
// `;
const FlexedDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;
const SocialLinksContainer = styled.div`
    font-size: 15px;
`;
const SocialLinkItem = styled.a`
    font-size: 15px;
    color: var(--twikVibe-brand-color);
    align-items: center;
    margin-right: 10px;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    text-decoration: none;
`;

const SubscribeButton = styled.div`
    color: var(--twikvibe-white-color);
    background: var(--twikVibe-brand-color);
    // color: var(--twikVibe-dark-text-color);
    // background: var(--twikVibe-light-theme-bg-color);
    padding: 10px;
    flex: 1;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;
const TipButton = styled.div`
    // background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    border: solid .2px var(--twikvibe-grey-color);
    padding: 9.5px;
    border-radius: 50px;
    flex: 1;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
`;
const WithdrawButton = styled.div`
    color: var(--twikvibe-white-color);
    background: var(--twikVibe-brand-color);
    // color: var(--twikVibe-dark-text-color);
    // background: var(--twikVibe-light-theme-bg-color);
    padding: 10px;
    flex: 1;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;
const TopUpButton = styled.div`
    // background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    border: solid .2px var(--twikvibe-grey-color);
    padding: 9.5px;
    border-radius: 50px;
    flex: 1;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
`;

const PostMediaButtonsContainer = styled.div`
    display: flex;
    width: 500px;
    margin: auto;
    border-bottom: solid .2px var(--twikvibe-grey-color);
    margin-top: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 600px){
        width: auto;
    }

`;
const PostMediaButton = styled.div`
    padding: 10px;
    color: var(--twikVibe-grey-color);
    flex: 1;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
`;


const PostsContainer = styled.div`
    // padding: 10px;
`;


const MediaContainer = styled.div``;


// loadding
const LoadingContainer = styled.div``;

const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:2px;
    height:2px;
    border-style:solid;
    border-left-color:var(--twikVibe-brand-color);
    border-top-color:var(--twikVibe-brand-color);
    border-width:5px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin-left: 20px;
`;

const MessageLoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:4px;
    height:4px;
    border-style:solid;
    border-left-color:var(--twikvibe-white-color);
    border-top-color:var(--twikvibe-white-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
`;


// isBlocked container
const IsBlockedContainer = styled.div``;


// isSuspended container
const IsSuspendedContainer = styled.div``;

const PopupBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    cursor: pointer;
`;
function formatNumber(num) {
    if (num >= 1_000_000_000) {
        // If number is 1 billion or more
        return (num / 1_000_000_000).toFixed(2) + 'b';
    } else if (num >= 1_000_000) {
        // If number is 1 million or more
        return (num / 1_000_000).toFixed(1) + 'm';
    } else if (num >= 1_000) {
        // If number is 1 thousand or more
        return (num / 1_000).toFixed(1) + 'k';
    } else {
        // If number is less than 1 thousand
        return num.toString();
    }
}

const ProfilePage = ({setShowHeader, setShowPopupMenuButton}) => {
    const reports = [
        {
            report: 'The owner is under 18'
        },
        {
            report: 'Violent, hateful or disturbing content'
        },
        {
            report: 'Fake profile'
        },
        {
            report: 'Suicide or self-harm'
        },
        {
            report: 'Bullying, harrassment or abuse'
        },
        {
            report: 'Selling or promoting restricted items'
        },
        {
            report: 'Scam, fraud or false information'
        },
        {
            report: 'Spam'
        },
        {
            report: 'Copyright infringement'
        },
        {
            report: 'Something else'
        },
        
    ]

    const [user, setUser] = useState({});
    const [isMyProfile, setIsMyProfile] = useState(false)
    const [copyProfileLink, setCopyProfileLink] = useState(false);
    const [iBlockedThisUser, setIblockedThisUser] = useState(false)
    const [loadingBlock, setLoadingBlock] = useState(false)
    const [openReportModal, setOpenReportModal] = useState(false)
    const [showSendMMessagePopup, setShowsendmessagePopup] = useState(false)
    const [reportIndex, setReportIndex] = useState(0)
    const [showProfilePopupContainer, setShowProfilePopupContainer] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingSendingReport, setLoadingSendingReport] = useState(false);
    const [loadingSubscribe, setLoadingSubscribe] = useState(false)
    const [loadingSendMessage, setLoadingSendMessage] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false)
    const [currentPostButton, setCurrentPostButton] = useState(1)
    const [profileNotFound, setProfileNotFound] = useState(false);
    const navigate = useNavigate();
    const { userName } = useParams();
    const accessToken = localStorage.getItem('accessToken')
    const userId = localStorage.getItem('userId')

    const [loadingPosts, setLoadingPosts] = useState(true)

    // message state
    const [messageContent, setMessageContent] = useState('');
    const textareaRef = useRef(null);

    const [subscribeScale, setSubscribeScale] = useState(1);
    const [tipScale, setTipScale] = useState(1);
    const [withdrawScale, setWithdrawScale] = useState(1);
    const [topUpScale, setTopUpScale] = useState(1);

    const [posts, setPosts] = useState([])
    const [showTipUser, setShowTipUser] = useState({
        userName: null,
        profilePicture: null
    });


    // popupmessage state
    const [showPopupMessage, setShowPopupMessage] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');

    // posts and media toggle
    const [showPosts, setShowPosts] = useState(true);


    // show profile image state
    const [showFullProfileImage, setShowFullProfileImage] = useState(false);


    // remove side bar 
    useEffect(()=>{
        setShowHeader(true)
        setShowPopupMenuButton(false)
    },[setShowHeader, setShowPopupMenuButton])
        

    


    // this one fetches the data for the posts user data
    const getPostsUserProfile = useCallback(async({userId})=>{
        try {
            const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`,{
                headers: {Authorization: accessToken}
            });

            const userName = response.data.returnWithoutPassword.userName;
            const fullName = response.data.returnWithoutPassword.fullName;
            const profilePicture = response.data.returnWithoutPassword.profilePicture;
            const subscribers = response.data.returnWithoutPassword.subscribers;
            const hasVerificationBadge = response.data.returnWithoutPassword.hasVerificationBadge;
            const verificationBadgeType = response.data.returnWithoutPassword.verificationBadgeType;
            const userBackgroundColor = response.data.returnWithoutPassword.userBackgroundColor;
            return {fullName: fullName, profilePicture: profilePicture, userName: userName, subscribers: subscribers, hasVerificationBadge: hasVerificationBadge, verificationBadgeType: verificationBadgeType, userBackgroundColor: userBackgroundColor};
        } catch (error) {
            
        }
    },[accessToken])


    const getUserPosts = useCallback(async(userWhosePostWeWantToGetId)=>{
        try {
            const response = await axios.get(`${API_ROUTE}getUserPosts?userWhosePostWeWantToGetId=${userWhosePostWeWantToGetId}`,{
                headers: {Authorization: accessToken}
            })

            
            if (response.data.postsWithPaymentStatus.length) {
                const userData = await Promise.all(
                    response.data.postsWithPaymentStatus.map(async (post) => {
                        const userProfile = await getPostsUserProfile({ userId: post.userId });
                        return {
                            ...post,
                            userName: userProfile.userName,
                            fullName: userProfile.fullName, // Attach the fetched full name to the chat
                            profilePicture: userProfile.profilePicture, // Attach the fetched full name to the chat
                            subscribers: userProfile.subscribers,
                            hasVerificationBadge: userProfile.hasVerificationBadge,
                            verificationBadgeType: userProfile.verificationBadgeType,
                            userBackgroundColor: userProfile.userBackgroundColor,
                        };
                    })
                );

                console.log(userData)
    
                setPosts(userData);
    
            }
            setLoadingPosts(false)
            // console.log(response.data)
        } catch (error) {
            // check if access token has expired
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    getUserPosts()
                }
            }else{
                console.log(error)
            }
        }
    },[accessToken, getPostsUserProfile])

    const handlesubscribeScaleClick = () => {
        // Increase the scale on click
        setSubscribeScale(prevScale => prevScale - 0.03);
        toggleSubscribeToUser();
        
        // Reset scale after a short delay
        setTimeout(() => {
            setSubscribeScale(1);
        }, 200);
    };
    const handletipScaleClick = () => {
        // Increase the scale on click
        setTipScale(prevScale => prevScale - 0.03);
        
        // Reset scale after a short delay
        setTimeout(() => {
        setTipScale(1);
        }, 200);

        setShowTipUser((prev)=>({
            ...prev,
            userName: user.returnWithoutPassword.userName,
            profilePicture: user.returnWithoutPassword.profilePicture
        }))
    };
    const handlewithdrawScaleScaleClick = () => {
        // Increase the scale on click
        setWithdrawScale(prevScale => prevScale - 0.03);
        
        // Reset scale after a short delay
        setTimeout(() => {
        setWithdrawScale(1);
        }, 200);
    };
    const handletopUpScaleClick = () => {
        // Increase the scale on click
        setTopUpScale(prevScale => prevScale - 0.03);
        
        // Reset scale after a short delay
        setTimeout(() => {
        setTopUpScale(1);
        }, 200);
    };
    

    // this will be sent to backend witouth the @
    const cleanUsername = userName.replace('@', '');

    // check if there is no @sign before username and put it there
    useEffect(()=>{
        if(!userName.includes('@')){
            navigate(`/@${userName}`);
        }
    },[navigate, userName])


    // this one gets the data of the user whose profile we are in
    const getUserProfile = useCallback(async()=>{

        try {
            const response = await axios.get(`${API_ROUTE}getUserByUsername?userWhoseAccountWeWantToGetUserName=${cleanUsername}`,{
                headers: {Authorization: accessToken}
            })


            // check if the profile is mine
            if(response.data.returnWithoutPassword._id===userId){
                setIsMyProfile(true)
            }else{
                setIsMyProfile(false)
            }

            // check if user is subscribed
            if(response.data.returnWithoutPassword.subscribers.includes(userId)){
                setIsSubscribed(true);
            }else{
                setIsSubscribed(false);
            }

            // check if i blocked this user
            setIblockedThisUser(response.data.iBlockedThisUser)

            setUser(response.data)
            setLoading(false);

            // fetch the users posts after getting the user data
            await getUserPosts(response.data.returnWithoutPassword._id)
            
        } catch (error) {
            // check if accesstoken has expired and refresh
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    // recall the get uset funtion
                    getUserProfile();
                    
                }
            }else if(error.status === 404){
                // navigate to not found if no user exists with this username
                setProfileNotFound(true);
                setLoading(false);
            }else{
                console.log(error)
            }
        }
    },[accessToken, cleanUsername, userId, getUserPosts])

    useEffect(()=>{
        getUserProfile();
    },[getUserProfile])

    const toggleSubscribeToUser = async()=>{
        setLoadingSubscribe(true);
        // set the subscribe button to the opposite of what it was initially
        setIsSubscribed(prev => !prev);
        
        try {

            await axios.post(`${API_ROUTE}toggleSubscribeToUser?userIamSubscribingToId=${user.returnWithoutPassword._id}`,
                {},
                {
                    headers: {Authorization: accessToken}
                });

            setLoadingSubscribe(false);
            if(isSubscribed){
                setPopupMessage('Subscription removed');
                setShowPopupMessage(true)
            }else{
                setPopupMessage('Subscription added')
                setShowPopupMessage(true)
            }
            // set the subscribe button to the opposite of what it was initially
            // setIsSubscribed(prev => !prev);

        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();
                

                if(accessToken){
                    await axios.post(`${API_ROUTE}toggleSubscribeToUser?userIamSubscribingToId=${user.returnWithoutPassword._id}`,
                        {},
                        {
                            headers: {Authorization: accessToken}
                        });
                    
                        setLoadingSubscribe(false);
                        // set the subscribe button to the opposite of what it was initially
                        // setIsSubscribed(prev => !prev);
                }
            }else{
                setLoadingSubscribe(false);

                // verse the subscribe toggle if anything goes wrong
                // set the subscribe button to the opposite of what it was initially
                setIsSubscribed(prev => !prev);
                console.log(error)
            }
        }
    }

    const toggleBlockUser = async()=>{
        setLoadingBlock(true);
        try {
            await axios.post(`${API_ROUTE}toggleBlockUser?blockedUserId=${user.returnWithoutPassword._id}`,
            {},
            {
                headers: {Authorization: accessToken}
            });

            setIblockedThisUser(prev=>!prev)
            setLoadingBlock(false);
        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();
                

                if(accessToken){
                    toggleBlockUser()
                    
                }
            }else{
                setLoadingBlock(false);

                console.log(error)
            }
        }
    }

    const reportUser = async({reportReason, index})=>{
        setLoadingSendingReport(true);
        setReportIndex(index);
        try {
            await axios.post(`${API_ROUTE}reportUser`,
            {
                reportedUserId: user.returnWithoutPassword._id,
                complaint: reportReason
            },
            {
                headers: {Authorization: accessToken}
            });

            setLoadingSendingReport(false);
            setOpenReportModal(prev=>!prev)
        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    // recall the report userfunctin after refresh accestoknen
                    reportUser({reportReason: reportReason, index: index})
                }
            }else{
                console.log()
            }
        }
    }

    // remove scrollbar if popup is open
    if( openReportModal || showSendMMessagePopup){
        document.body.style.overflow = 'hidden';
    }else{
        document.body.style.overflow = 'auto';
    }



    const handleInputChange = (e)=>{
        const value = e.target.value;
        setMessageContent(value);

        const maxRows = 5;

        const textarea = textareaRef.current;

        textarea.style.height = "auto";

        // Calculate the number of rows based on the scrollHeight
        const rows = Math.min(textarea.scrollHeight / textarea.offsetHeight, maxRows);

        if (rows >= maxRows) {
            textarea.style.height = `${textarea.scrollHeight}px`;
            textarea.style.overflowY = "scroll"; // Enable scrolling after reaching max rows
        } else {
            textarea.style.height = `${textarea.scrollHeight}px`;
            textarea.style.overflowY = "hidden"; // Disable scrolling while growing
        }

    }

    // function to send message to a user from their profile
    const handleSendMessage = async (e) => {
        e.preventDefault();
        setLoadingSendMessage(true);
        if(!messageContent){
            return;
        }
        
        try {
            await axios.post(`${API_ROUTE}messages`, {
                senderId: userId,
                receiverId: user.returnWithoutPassword._id,  // This should now be the userId of the selected user
                content: messageContent,
            });
            setMessageContent('');
            setShowsendmessagePopup(prev=>!prev);
            setLoadingSendMessage(false);
        } catch (error) {
            console.log(error)
            setLoadingSendMessage(false);
        }
        
    };

    
    const showFullProfileImageFunction = ()=>{
        setShowFullProfileImage(true)
    }
    
    return (

        <Container onClick={()=>setShowProfilePopupContainer(false)}>

            {/* check if profile is loading */}
            {loading?
            <LoadingContainer>
                loading...
            </LoadingContainer>

            // check if the profile is not found
            :profileNotFound
            ?<div>PROFILE NOT FOUND</div>
            
            // check if the current user is blocked from seeing this profile
            :user.isBlocked?
            <IsBlockedContainer>
                you are blocked from viewing {userName}'s profile'
            </IsBlockedContainer>

            // check if the user is suspended
            :user.returnWithoutPassword.isUserSuspended?
            <IsSuspendedContainer>
                this user is suspended
            </IsSuspendedContainer>
            :<ProfileContainer>
               

                {/* the section under the header */}
                <InnerProfileContainer>
                    <ProfileImageSection>
                        <ProfileImageContainer>
                            {user.returnWithoutPassword.profilePicture
                            ?<ProfileImage onClick={showFullProfileImageFunction} src={user.returnWithoutPassword.profilePicture} alt="profile"/>
                            // user.returnWithoutPassword.userBackgroundColor
                            :<NoProfilePicCon style={{background: user.returnWithoutPassword.userBackgroundColor}}>{user.returnWithoutPassword.userName[0]}</NoProfilePicCon>}
                        </ProfileImageContainer>

                        <ProfileTopIconsContainer>
                            {/* send message icon here  */}
                            {!isMyProfile&&<IconButton onClick={()=>setShowsendmessagePopup(prev=>!prev)} icon={<IoPaperPlane />}/>}
                            {/* send message popup here ================================================================ */}
                            {showSendMMessagePopup&&
                            <SendMessagePopup onSubmit={handleSendMessage}>
                                <SendMessagePopupText>Say hi to <strong>{userName}</strong></SendMessagePopupText>
                                <SendMessagePopupInputContainer>
                                    <SendMessagePopupInput disabled={loadingSendMessage} rows={3} ref={textareaRef} placeholder="message" value={messageContent} onChange={(e) => handleInputChange(e)} />
                                </SendMessagePopupInputContainer>

                                <SendMessagePopupButton disabled={loadingSendMessage} type="submit" onClick={handleSendMessage}>{loadingSendMessage?<MessageLoadingSpinnerElement/>:'Send'}</SendMessagePopupButton>
                            </SendMessagePopup>}
                            {/* end of message popup  */}

                            {isMyProfile&&<WalletBalance><HiMiniWallet /> {Number(user.returnWithoutPassword.walletBalance).toLocaleString("en-US", {style:"currency", currency:"USD"})}</WalletBalance>}
                            

                            {/* profile menu popup icon here ===================== */}
                            <IconButton onClick={()=>setShowProfilePopupContainer(prev=>!prev)} icon={<BsThreeDotsVertical />}/>

                            {/* This is where the report, block and copy profile links button will be */}
                            {showProfilePopupContainer&&
                            <ProfilePopupContainer onClick={(e)=>e.stopPropagation()}>
                                {!isMyProfile&&<ProfilePopupItem onClick={()=>{setOpenReportModal(prev=>!prev); setShowProfilePopupContainer(prev=>!prev)}}>Report <TiWarningOutline size={20}/></ProfilePopupItem>}

                                {loadingBlock
                                ?<>{!isMyProfile&&<ProfilePopupItem>{iBlockedThisUser?'Unblock':'Block'}</ProfilePopupItem>}</>
                                :<>{!isMyProfile&&<ProfilePopupItem onClick={toggleBlockUser}>{iBlockedThisUser?'Unblock':'Block'} <MdBlockFlipped size={20}/></ProfilePopupItem>}</>}

                                <CopyToClipboard text={`http://localhost:3000/@${cleanUsername}`} onCopy={()=>{
                                    setCopyProfileLink(true);
                                    // hide the copied icon after 2 seconds
                                    setTimeout(() => {
                                        setCopyProfileLink(false);
                                        setShowProfilePopupContainer(false);
                                    }, 1000);
                                }}>
                                    <ProfilePopupItem>Copy link {copyProfileLink?<GrStatusGood size={20}/>:<LuShare size={20}/>}</ProfilePopupItem>
                                </CopyToClipboard>
                            </ProfilePopupContainer>}

                        </ProfileTopIconsContainer>

                    </ProfileImageSection>
                    

                    {/* user name starts here */}

                    <ProfileNameContainer>
                        

                        <UserFullName>{user.returnWithoutPassword.fullName}</UserFullName>

                        <UsernameContainer>
                            
                            <Username>@{user.returnWithoutPassword.userName}</Username>


                            {/* verification badge */}

                            {/* check if this user has purchased a verification badge first */}
                            {user.returnWithoutPassword.hasVerificationBadge&&
                            <VerficationImageContainer>

                                {/* get the exact verification badge type the user has if they have a verification badge */}
                                <VerficationImage src={user.returnWithoutPassword.verificationBadgeType==='regular'?RegularBadge : user.returnWithoutPassword.verificationBadgeType==='silver'?SilverBadge : user.returnWithoutPassword.verificationBadgeType==='organisation'? OrganisationBadge : user.returnWithoutPassword.verificationBadgeType==='pride'?PrideBadge : null} alt="verification badge"/>
                                <Check><FaCheck color="var(--twikVibe-dark-text-color)" size={10}/></Check>
                            </VerficationImageContainer>}
                        </UsernameContainer>                        
                    </ProfileNameContainer>
                    
                    
                    {user.returnWithoutPassword.about&&<Bio>{user.returnWithoutPassword.about}</Bio>}

                    {/* analytics sections followers,  */}
                    <AnalyticsSection>
                        <AnalyticsItem>
                            <AnalyticsNumber>{formatNumber(user.subscribersLength)}</AnalyticsNumber>
                            <AnalyticsText>subscribers</AnalyticsText>
                        </AnalyticsItem>

                        {/* <AnalyticsDot/> */}


                        

                    </AnalyticsSection>
                    
                    {/* social and website links */}
                    {user.returnWithoutPassword.socialLinks&&
                    <SocialLinksContainer>
                        {user.returnWithoutPassword.socialLinks.map((link, index)=>(
                            <SocialLinkItem href={link} target="-blank" rel="noreferrer" key={index}>
                                <IoLinkOutline color="var(--twikvibe-grey-color)" size={20}/>
                                {shortenText(link, 40)}
                            </SocialLinkItem>
                        ))}
                    </SocialLinksContainer>}


                    {/* show subscribe button if its not my profile */}
                    {!isMyProfile&&
                    <FlexedDiv>
                        {loadingSubscribe
                        ?<SubscribeButton style={{transform: `scale(${subscribeScale})`}}>{isSubscribed?'Unsubscribe':'Subscribe'}</SubscribeButton>
                        :<SubscribeButton style={{transform: `scale(${subscribeScale})`}} onClick={()=>{handlesubscribeScaleClick();}}>{isSubscribed?'Unsubscribe':'Subscribe'}</SubscribeButton>}

                        <TipButton style={{transform: `scale(${tipScale})`}} onClick={()=>{handletipScaleClick()}}>$Tip</TipButton>
                    </FlexedDiv>}

                    {/* show topup button if its my wallet */}
                    {isMyProfile&&
                    <FlexedDiv>
                        <WithdrawButton style={{transform: `scale(${withdrawScale})`}} onClick={()=>{handlewithdrawScaleScaleClick()}}>Withdraw</WithdrawButton>
                        <TopUpButton style={{transform: `scale(${topUpScale})`}} onClick={()=>{handletopUpScaleClick()}}>Top Up Wallet</TopUpButton>
                    </FlexedDiv>}


                    {/* posts section */}

                    <PostMediaButtonsContainer>
                        <PostMediaButton style={{borderBottom: currentPostButton===1&&'solid .2px var(--twikVibe-light-theme-bg-color)', color: currentPostButton===1?'var(--twikVibe-light-text-color)':'var(--twikvibe-grey-color)'}} onClick={()=>{setCurrentPostButton(1); setShowPosts(true)}}>Posts</PostMediaButton>
                        <PostMediaButton style={{borderBottom: currentPostButton===2&&'solid .2px var(--twikVibe-light-theme-bg-color)', color: currentPostButton===2?'var(--twikVibe-light-text-color)':'var(--twikvibe-grey-color)'}} onClick={()=>{setCurrentPostButton(2); setShowPosts(false)}}>Media</PostMediaButton>
                    </PostMediaButtonsContainer>
                    


                    {/* the posts and files section */}
                    {showPosts
                    ?<PostsContainer>
                        {loadingPosts
                        ?'loading'
                        :<Posts posts={posts} setPosts={setPosts}/>}
                    </PostsContainer>

                    // the media that include images and videos
                    :<MediaContainer>
                    </MediaContainer>}
                    
                </InnerProfileContainer>
                
            </ProfileContainer>}


            
            

            {/* report modal */}
            {openReportModal&&
            <ReportContainerBackground onClick={()=>setOpenReportModal(prev=>!prev)}>
                <ReportContainer onClick={(e)=>{ e.stopPropagation()}}>
                    <ReportHeader>
                        <div/>
                        <ReportHeaderMainTitle>Report</ReportHeaderMainTitle>
                        <IconButton onClick={()=>{setOpenReportModal(prev=>!prev);}} icon={<IoClose size={25}/>}/>
                    </ReportHeader>

                    <ReportHeaderTitle>Why are you reporting this profile?</ReportHeaderTitle>
                    <ReportHeaderDescription>If someone is in immediate danger, get help before reporting to Twikvibe. Don't wait.</ReportHeaderDescription>

                    <ReportItemsContainer>
                        {reports.map((report, index)=>(
                            <ReportItem key={index} onClick={()=>reportUser({reportReason: report.report, index: index})}>{report.report} {loadingSendingReport&&reportIndex===index?<LoadingSpinnerElement/>:<FaAngleRight />}</ReportItem>
                        ))}
                    </ReportItemsContainer>

                </ReportContainer>
            </ReportContainerBackground>}

            {/* the background that closes any open modal */}
            {showProfilePopupContainer?<PopupBackground onClick={()=>{setShowProfilePopupContainer(false);}}/>:null}
            {showSendMMessagePopup?<PopupBackground onClick={()=>{setShowsendmessagePopup(prev=>!prev);}}/>:null}
            
            {/* tip user modal */}
            {showTipUser.userName&&<TipUser userName={showTipUser.userName} profilePicture={showTipUser.profilePicture} setShowTipUser={setShowTipUser}/>}
            
            {/* full profile image */}
            {showFullProfileImage&&<FullProfileImage setShowFullProfileImage={setShowFullProfileImage} profileLink={`http://localhost:3000/@${cleanUsername}`} username={userName} image={user.returnWithoutPassword.profilePicture}/>}

            {/* popup message  */}
            {showPopupMessage&&<PopupMessage message={popupMessage} setShowPopupMessage={setShowPopupMessage} showPopupMessage={showPopupMessage}/>}


        </Container>
    )
}

export default ProfilePage
