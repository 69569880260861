import axios from "axios";
import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { API_ROUTE } from "../../utils/Api";
import { Link, useNavigate } from "react-router-dom";
import blackBackgroundImage from "../../assets/loaders/blackdesign.png";
import whiteBackgroundImage from "../../assets/loaders/whitedesign.png";
import GoogleIcon from "../../assets/icons/google-icon.svg";
import { TiWarningOutline } from "react-icons/ti";
import { FaAngleLeft } from "react-icons/fa6";
import { jwtDecode } from "jwt-decode";
import { FaRegEye } from "react-icons/fa6";
import { FaRegEyeSlash } from "react-icons/fa";
import { useGoogleLogin } from "@react-oauth/google";

const Container = styled.div`
    background-image: ${({ theme }) => (theme === 'dark' ? `url(${blackBackgroundImage})` : `url(${whiteBackgroundImage})`)};
    background-size: cover; 
    background-position: center; 
    // width: 100vw;
    height: 100vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 20px;
    flex: 1;

    @media screen and (max-width: 500px){
        background-image: none;
        height: auto; 
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
    }

    @media screen and (max-height: 400px){
        height: auto; 
    }
`;
const InnerContainer = styled.div`
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 500px){
        width: 90%;

    }
`;
// const LogoContainer = styled.div``;
// const Logo = styled.img``;
const WelcomeText = styled.h1`
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: var(--twikVibe-light-text-color);
`;
const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const InputContainer = styled.label`
    display: flex;
    padding: 8px;
    background-color: var(--twikVibe-dark-grey-bg);
    border-radius: 10px;
    border: 2px solid var(--twikVibe-dark-grey-bg);
    align-items: center;
    gap: px;
    justify-content: space-between;

    &:focus-within{
        border: 2px solid var(--twikVibe-brand-color);
    }
`;
const DotCom = styled.div`
    font-size: 14px;
    background: none;
    border: none;
    padding: 5px;
    color: var(--twikvibe-grey-color);
    font-weight: 600;
    user-select: none;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    cursor: text;
    width: 105px;
`;
const Input = styled.input`
    flex: 1;
    width: 50%:
    background: none;
    border: none;
    padding: 5px;
    color: var(--twikVibe-light-text-color);
    background-color: transparent;
    font-size: 16px;
    // font-weight: 500;
`;

const SignupButton = styled.button`
    width: 100%;
    padding: 15px;
    background: var(--twikVibe-brand-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: var(--twikvibe-white-color);

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;
const LoginText = styled.div`
    font-size: 13px;
    color: var(--twikVibe-light-text-color);
    font-weight: 600;
    text-align: center;
`;

const CustomGoogleSignIn = styled.div`
    color: var(--twikVibe-dark-text-color);
    font-size: 13px;
    font-weight: 600;
    background: var( --twikVibe-light-theme-bg-color);
    text-align: center;
    padding: 15px;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
`;

const GoogleIconCon = styled.div`
    width: 15px;
    height: 15px;
`;

const GoogleImage = styled.img`
    width: 100%;
    height: 100%;
`;

const OrSection = styled.div`
    display: flex;
    gap: 10px;
    color: var(--twikvibe-grey-color);
    align-items: center;
    padding: 0px 10px;
`;

const OrLine = styled.div`
    height: 1px;
    flex: 1;
    background: #5c5c5c;
`;
const MainError = styled.div`
    color:var(--twikvibe-white-color);
    background: var(--twikVibe-red-danger-zone-bg-color);
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
`;
const ErrorContainer = styled.div`
    color: var(--twikVibe-red-danger-zone-bg-color);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
`;
const BackButton = styled.div`
    padding: 15px;
    display: flex;
    border-radius: 50px;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    color: var(--twikvibe-grey-color);
    text-align: center;
    justify-content: center;
    transition: .3s ease-in-out;
    gap: 1px;

    &:hover{
        gap: 5px;
        background: var(--twikVibe-dark-grey-bg);
    }
`;

const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:4px;
    background-color:transparent;
    border-radius:100px;
    width:4px;
    height:4px;
    border-style:solid;
    border-left-color:var(--twikVibe-light-theme-bg-color);
    border-top-color:var(--twikVibe-light-theme-bg-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin: auto;
`;

function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

function isUsernameValid(username) {
    const regex = /^[a-zA-Z0-9_]{4,20}$/;
    return regex.test(username);
}

const Signuppage = ({ setShowHeader }) => {
    // sections
    const [firstSection, setFirstSection] = useState(true);
    const [secondSection, setSecondSection] = useState(false);
    const [thirdSection, setThirdSection] = useState(false);

    const selectedTheme = localStorage.getItem('selectedTheme')

    const navigate = useNavigate();


    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [userName, setUserName] = useState('')
    const [gender, setGender] = useState('')
    const [password, setPassword] = useState('')

    const [confirmPassword, setConfirmPassword] = useState('')

    const [usernameExists, setUsernameExists] = useState(true)

    const [error, setError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [userNameError, setUserNameError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const [loading, setLoading] = useState(false)
    const [dataCheckLoading, setdataCheckLoading] = useState(false)

    const [isGoogleSignUp, setIsGoogleSignUp] = useState(false)


    const [passwordHide, setPasswordHide] = useState(true)
    const [confirmPasswordHide, setConfirmPasswordHide] = useState(true)

    useEffect(()=>{
        setShowHeader(false);
    },[setShowHeader])


    const onEmailChange = (data)=>{
        const value = data.target.value;
        setError('')
        setEmail(value);

        if(!isValidEmail(value)){
            setEmailError('Enter a valid email');
        }else{
            setEmailError('');
        }
    }

    const onUsernameChange = (data)=>{
        const value = data.target.value;

        setUserName(value);


        if(!isUsernameValid(value)){
            setUserNameError('Username must be 4 to 20 characters long and can only contain letters, numbers, and underscores.');
        }else{
            // check if the username exists in the db
            checkUsernameExists(value);
            setUserNameError('');
        }
    }

    const signUp = async(e) =>{
        e.preventDefault();

        setLoading(true)
        setIsGoogleSignUp(false)

        setError('')
        setEmailError('')
        setPasswordError('')

        try {

            // check if there's data
            if(!email) {
                setError('Email is required');
                setLoading(false)
                return;
            }

            if(!firstName) {
                setError('First name is required');
                setLoading(false)
                return;
            }

            if(!gender) {
                setError('Gender is required');
                setLoading(false)
                return;
            }

            if(!password) {
                setError('Password is required');
                setLoading(false)
                return;
            }

            if(!confirmPassword) {
                setError('Confirm password is required');
                setLoading(false)
                return;
            }

            if(!isValidEmail(email)) {
                setEmailError('Enter a valid email');
                setLoading(false)
                return;
            }

            if(password!==confirmPassword) {
                setPasswordError('Passwords must match');
                setLoading(false)
                return;
            }

            if(!userName) {
                setPasswordError('Username is required');
                setLoading(false)
                return;
            }

            if(!isUsernameValid(userName)) {
                setUserNameError('Enter a valid username');
                setLoading(false)
                return;
            }

            if(usernameExists){
                setUserNameError('This username already exists');
            }
            
            console.log(isGoogleSignUp)

            const response = await axios.post(`${API_ROUTE}signUp`,{
                email,
                userName,
                firstName,
                lastName,
                gender,
                password,
                isGoogleSignUp
            });

            const { accessToken, refreshToken } = response.data;

            const user = jwtDecode(accessToken);
            // save data to loacl storage
            localStorage.setItem('accessToken', accessToken)
            localStorage.setItem('refreshToken', refreshToken)
            localStorage.setItem('userId', user.userId)
            localStorage.setItem('userName', user.userName)
            localStorage.setItem('profilePicture', user.profilePicture)

            // navigate to home page
            navigate('/activate-email')
            setLoading(false)
        } catch (error) {
            // check for error
            setError(error.response.data.error)
            setLoading(false)
            console.log(error)
        }
    }

    const checkEmailExists = async(e)=>{
        e.preventDefault()
        setError('');
        setEmailError('');
        setUserNameError('');
        setPasswordError('');

        setdataCheckLoading(true);
        setLoading(true);
        if(!email) {
            setError('Email is required');
            setdataCheckLoading(false)
            setLoading(false)
            return;
        }

        if(!isValidEmail(email)) {
            setEmailError('Enter a valid email');
            setdataCheckLoading(false)
            setLoading(false)
            return;
        }
        try {
            const response = await axios.post(`${API_ROUTE}checkEmailExists`,{
                email
            })

            if(response.status===200){
                console.log('email okay')
            }

            setFirstSection(false);
            setSecondSection(true);
            setThirdSection(false);
            setdataCheckLoading(false)
            setLoading(false)
        } catch (error) {
            if(error.response.status===400){
                setEmailError(error.response.data.error);
            }else{
                console.log(error.response.data.error)
            }
            setdataCheckLoading(false)
            setLoading(false)
        }
    }

    const checkPassword = (e)=>{
        e.preventDefault()
        setError('');
        setEmailError('');
        setUserNameError('');
        setPasswordError('');

        if(!password){
            setPasswordError('Passwords must match');
            return ;
        }

        if(password!==confirmPassword) {
            setPasswordError('Passwords must match');
            setLoading(false)
        }else{
            setFirstSection(false);
            setSecondSection(false);
            setThirdSection(true);
        }

    }

    const checkUsernameExists = async(value)=>{
        // e.preventDefault()
        setdataCheckLoading(true);
        setError('');
        setEmailError('');
        setUserNameError('');
        setPasswordError('');

        if(!value) {
            setUserNameError('Username is required');
            setLoading(false)
            return;
        }

        if(!isUsernameValid(value)) {
            setUserNameError('Enter a valid username');
            setLoading(false)
            return;
        }
        
        try {
            const response = await axios.post(`${API_ROUTE}checkUsernameExists`,{
                userName: value
            })

            if(response.status===200){
                console.log('username okay')
            }

            setUsernameExists(false)

            setdataCheckLoading(false)
            // signUp(e);

          
        } catch (error) {
            if(error.response.status===400){
                setUserNameError(error.response.data.error)
                setUsernameExists(true)
                
            }else{
                console.log(error.response.data.error)
            }
            setdataCheckLoading(false)
        }
    } 
    


    // google signup stuff


    const googleSignup = useGoogleLogin({
        onSuccess: credentialResponse => googleSignUp(credentialResponse.access_token),
    })


    const googleSignUp = async(credentialResponse)=>{
        
        setLoading(true)
        setIsGoogleSignUp(true)
        // get the users data from the credential
        await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${credentialResponse}`, {

            headers: {
                Authorization: `Bearer ${credentialResponse}`,
                Accept: 'application/json'
            }
        })
        .then(async(result)=>{
            const resultResponse = result.data;

            try {
    
                const response = await axios.post(`${API_ROUTE}signUp`,{
                    firstName: resultResponse.given_name,
                    // return an empty string if there is no lastname
                    lastName: resultResponse.family_name?resultResponse.family_name:'',
                    fullName: resultResponse.name,
                    email: resultResponse.email,
                    // return an empty string if there is no picture
                    profilePicture: resultResponse.picture?resultResponse.picture:'',
                    isGoogleSignUp: true,
                    gender: 'custom',


                })
    
                const { accessToken, refreshToken } = response.data;

                const user = jwtDecode(accessToken);
                // save data to loacl storage
                localStorage.setItem('accessToken', accessToken)
                localStorage.setItem('refreshToken', refreshToken)
                localStorage.setItem('userId', user.userId)
                localStorage.setItem('userName', user.userName)
                localStorage.setItem('profilePicture', user.profilePicture)
    
                // navigate to home page
                navigate('/')
                setLoading(false)
    
            } catch (error) {
                setError(error.response.data.error);
                setLoading(false)
            }

        })
        .catch(async(err) => {
            setError(error.response.data.error);
            setLoading(false)
        });

    }   

    return (
        <Container theme={selectedTheme}>

            <InnerContainer>
                <WelcomeText>Find, Observe, Smatch and Vibe.</WelcomeText>
                    {error&&<MainError><TiWarningOutline />{error}</MainError>}

                    {firstSection&&!secondSection&&!thirdSection
                    ?<>
                        <FormContainer onSubmit={checkEmailExists}>
                            <InputContainer>
                                <Input disabled={loading} value={email} onChange={(e)=>onEmailChange(e)} placeholder="Email address"/>
                                {dataCheckLoading&&
                                    <div>
                                        <LoadingSpinnerElement/>
                                    </div>}
                            </InputContainer>

                            {emailError&&<ErrorContainer><TiWarningOutline />{emailError}</ErrorContainer>}

                            <InputContainer>
                                <Input disabled={loading} value={firstName} onChange={(e)=>setFirstName(e.target.value)} placeholder="First name"/>
                            </InputContainer>

                            <InputContainer>
                                <Input disabled={loading} value={lastName} onChange={(e)=>setLastName(e.target.value)} placeholder="Last name"/>
                            </InputContainer>
                            

                            {/* show a grey button if the conditions arent met */}
                            {email&&firstName&&!emailError
                                ?<SignupButton type="submit" style={{cursor: loading||userNameError||dataCheckLoading?'not-allowed':'pointer'}} disabled={loading||userNameError||dataCheckLoading} onClick={checkEmailExists}>Continue</SignupButton>
                                :<SignupButton onClick={(e)=>e.stopPropagation()} type="button" style={{background:'var(--twikvibe-grey-color)', cursor:'not-allowed'}}>Continue</SignupButton>}
                        </FormContainer>
                    </>
                    :!firstSection&&secondSection&&!thirdSection
                    ?<>
                        <FormContainer onSubmit={checkPassword}>

                            <InputContainer>
                                <Input disabled={loading} value={gender} onChange={(e)=>setGender(e.target.value)} placeholder="Gender"/>
                            </InputContainer>

                            <InputContainer>
                                <Input type={passwordHide?"password":"text"} disabled={loading} value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Password"/>
                                <div style={{cursor: 'pointer'}} onClick={()=>setPasswordHide(prev=>!prev)}>{passwordHide?<FaRegEye />:<FaRegEyeSlash />}</div>
                            </InputContainer>

                            <InputContainer>
                                <Input type={confirmPasswordHide?"password":"text"} disabled={loading} value={confirmPassword} onChange={(e)=>{

                                    // ceck if the passwords match as the user types
                                    const value = e.target.value;
                                    setConfirmPassword(value)
                                    if(password&&value!==password){
                                        setPasswordError('Passwords must match');
                                    }else{
                                        setPasswordError('');
                                    }
                                }} placeholder="Confirm password"/>
                                <div style={{cursor: 'pointer'}} onClick={()=>setConfirmPasswordHide(prev=>!prev)}>{confirmPasswordHide?<FaRegEye />:<FaRegEyeSlash />}</div>
                            </InputContainer>

                            {passwordError&&<ErrorContainer><TiWarningOutline />{passwordError}</ErrorContainer>}
                            

                            {/* show a grey button if the conditions arent met */}
                            {password&&confirmPassword&&!passwordError
                            ?<SignupButton type="submit" style={{ cursor: loading||dataCheckLoading ?'not-allowed':'pointer'}} disabled={loading} onClick={checkPassword}>Continue</SignupButton>
                            :<SignupButton type="button"  onClick={(e)=>e.stopPropagation()} style={{background:'var(--twikvibe-grey-color)', cursor:'not-allowed'}}>Continue</SignupButton>}

                            <BackButton onClick={()=>{
                                setFirstSection(true);
                                setSecondSection(false);
                                setThirdSection(false);
                            }}><FaAngleLeft /> Back</BackButton>

                        </FormContainer>
                    
                    </>
                    :!firstSection&&!secondSection&&thirdSection
                    ?<>
                        <FormContainer onSubmit={signUp}>
                            <InputContainer>
                                <DotCom>twikvibe.com/@</DotCom>
                                <Input disabled={loading} value={userName} onChange={(e)=>onUsernameChange(e)} placeholder="Username"/>
                                {dataCheckLoading&&
                                    <div>
                                        <LoadingSpinnerElement/>
                                    </div>}
                            </InputContainer>

                            {userNameError&&<div style={{color:'red', fontSize: '13px', fontWeight:'500'}}>{userNameError}</div>}
                            
                            {loading
                            ?<>
                                <SignupButton style={{ cursor: 'not-allowed'}} disabled={loading} ><LoadingSpinnerElement style={{borderLeftColor:'var(--twikvibe-white-color)',borderTopColor:'var(--twikvibe-white-color)' }}/></SignupButton>
                            </>
                            :<>
                                {/* show a grey button if the conditions arent met */}
                                {userName&&!userNameError
                                ?<SignupButton style={{ cursor: userNameError||dataCheckLoading||!userName ?'not-allowed':'pointer'}} disabled={loading||userNameError||dataCheckLoading||!userName} type="submit" onClick={signUp}>Sign Up</SignupButton>
                                :<SignupButton type="button"  onClick={(e)=>e.stopPropagation()} style={{background:'var(--twikvibe-grey-color)', cursor:'not-allowed'}}>Sign Up</SignupButton>}
                            </>}
                            

                            <BackButton onClick={()=>{
                                setFirstSection(false);
                                setSecondSection(true);
                                setThirdSection(false);
                            }}><FaAngleLeft /> Back</BackButton>
                        </FormContainer>
                    </>:null}

                    

                    {/* {userNameError&&<div>User name must only contain letters and number, no white spaces, no special characters except underscores, more than three letters and not more than 20 ltters</div>} */}
                    

                <OrSection>
                    <OrLine/>
                    or
                    <OrLine/>
                </OrSection>

                {loading
                ?<CustomGoogleSignIn ><LoadingSpinnerElement style={{borderLeftColor:'var(--twikvibe-light-color)',borderTopColor:'var(--twikvibe-light-color)' }} /></CustomGoogleSignIn>
                :<CustomGoogleSignIn onClick={googleSignup}><GoogleIconCon><GoogleImage src={GoogleIcon}/></GoogleIconCon>Sign Up with Google</CustomGoogleSignIn>}


                <LoginText>Already have an account? <Link className="transparent_click" style={{color:' var(--twikVibe-brand-color)'}} to='/login'>Login</Link></LoginText>

            </InnerContainer>

            
        </Container>
    )
}

export default Signuppage