import styled, { keyframes } from "styled-components";
import { SlOptionsVertical } from "react-icons/sl";
import Comment from "../components/Comment"
import {Link, useNavigate,useParams} from "react-router-dom"
import { BsFillSendFill } from "react-icons/bs";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { API_ROUTE } from "../utils/Api";
import { refreshAccessToken } from "../utils/RefreshAccessToken";
import { AiOutlineDollar } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai"; // filed icon 
import TipUser from "../components/TipUser";
import PayToSeePost from "../components/PayToSeePost";


function detectAndConvertLinks(text) {
    // Regex to match URLs
    const urlRegex = /((https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))/g;

    // Regex to match @tags
    const tagRegex = /(@[a-zA-Z0-9_]+)/g;

    // Merge both regexes into a combined regex
    const combinedRegex = new RegExp(`${urlRegex.source}|${tagRegex.source}`, 'g');

    // Split text into paragraphs by newlines or double newlines
    const paragraphs = text.split(/\n+/);

    return paragraphs.map((paragraph, pIndex) => {
        const parts = [];
        let lastIndex = 0;

        // Replace matches (either URLs or @tags) with clickable links
        paragraph.replace(combinedRegex, (match, ...args) => {
            const index = args[args.length - 2];
            if (index > lastIndex) {
                // Push the text between the last match and the current match
                parts.push(paragraph.slice(lastIndex, index));
            }

            if (match.startsWith('@')) {
                // Handle @tags: Create custom link for your website
                const profileUrl = `http://localhost:3000/@${match.slice(1)}`; // Your custom URL
                parts.push(
                    <Link style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click" key={index} to={profileUrl}>
                        {match}
                    </Link>
                );
            } else {
                // Handle URLs: Make them clickable links
                let href = match;
                if (!href.startsWith('http://') && !href.startsWith('https://')) {
                    href = 'http://' + href;
                }

                parts.push(
                    <a style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click" key={index} href={href} target="_blank" rel="noopener noreferrer">
                        {match}
                    </a>
                );
            }

            lastIndex = index + match.length;
            return match; // Keep the match as it is
        });

        // Add the remaining text after the last match
        if (lastIndex < paragraph.length) {
            parts.push(paragraph.slice(lastIndex));
        }

        // Wrap the processed text in a paragraph <p> element
        return <p key={pIndex}>{parts}</p>;
    });
}


const formatTime = (date) => {
    const parsedDate = new Date(date);
    
    // Check if the parsedDate is valid
    if (isNaN(parsedDate)) {
      return "Invalid date";
    }
  
    const now = new Date();
    const secondsAgo = Math.floor((now - parsedDate) / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
  
    if (secondsAgo < 60) return `${secondsAgo}s ago`;
    if (minutesAgo < 60) return `${minutesAgo}m ago`;
    if (hoursAgo < 24) return `${hoursAgo}h ago`;
    if (daysAgo <= 7) return `${daysAgo}d ago`;
  
    // For more than 7 days, return formatted date MM/DD/YY
    return new Intl.DateTimeFormat("en-US").format(parsedDate);
};

const formatLikes = (num) => {
    if (num < 1000) return num.toString(); // 100 -> 100
    if (num >= 1000 && num < 10000) return (num / 1000).toFixed(1) + "k"; // 1.1k
    if (num >= 10000 && num < 100000) return (num / 1000).toFixed(2) + "k"; // 10.1k, 100.23k
    if (num >= 100000 && num < 1000000) return (num / 1000).toFixed(1) + "k"; // 100.2k
    if (num >= 1000000 && num < 10000000) return (num / 1000000).toFixed(2) + "m"; // 1.12m
    if (num >= 10000000 && num < 100000000) return (num / 1000000).toFixed(1) + "m"; // 10.1m
    if (num >= 100000000 && num < 1000000000) return (num / 1000000).toFixed(2) + "m"; // 100.4m
    if (num >= 1000000000 && num < 10000000000) return (num / 1000000000).toFixed(2) + "b"; // 1.12b
    if (num >= 10000000000 && num < 100000000000) return (num / 1000000000).toFixed(1) + "b"; // 12.5b
    if (num >= 100000000000) return (num / 1000000000).toFixed(2) + "b"; // 100.2b
};

const Container = styled.div`
    flex: 1;
`;

const PostDetailContainer = styled.div`
    // display:flex;
    width:500px;
    // padding:10px;
    // flex-direction:column;
    gap: 20px;
    margin: auto;

    @media screen and (max-width: 600px){
        width: auto;
    }
`;

const MainPostContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    background-color: var(--twikVibe-dark-grey-bg);
    border-radius: 10px;
    padding: 10px;


    @media screen and (max-width: 600px){
        margin: 10px;
    }
`;



const PostProfileParentContainer=styled.div`
    display:flex;
    align-items:center;
    gap: 10px;
`;



const PostProfilePicsCon= styled.div`
    background:grey;
    border-radius:100%;
    width:40px;
    height:40px;
`;

const PostProfilePics= styled.img`
    border-radius:100%;
    width:100%;
    height:100%;
    object-fit: cover;
`;

const PostProfileUserNameContainer = styled.div`
    display:flex;
    flex-direction:column;
    gap:5px;
    position: relative;
`;

const PostProfileUserName = styled.h4`
    color:var(--twikVibe-light-theme-color);
    font-size: 15px;

    &:hover + .profileDetailHover{
        visibility: visible;
        opacity: 1;
    }
`;

const ProfileDetailHover = styled.div`
    padding: 10px;
    background: blue;
    width: 200px;
    height:200px;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: 20px;
    border-radius: 10px;


    background: var(--twikVibe-dark-theme-bg-color);
    box-shadow: 0px 4px 15px rgba(0,0,0,0.2);
    z-index: 12;

    &:hover {
        visibility: visible;
        opacity: 1;
    }

    @media screen and (max-width: 600px){
        display: none;
    }
`;

const PostTimeStamp = styled.div`
    font-size:13px;
    color:var(--twikVibe-light-theme-color);
`;


const PostProfileSubcribeBtn=styled.button`
    background:var( --twikVibe-brand-color);
    border-style:solid;
    border-color:transparent;
    color:ivory;
    font-size:12px;
    font-weight:bold;
    padding:8px;
    border-radius:5px;
    cursor:pointer;
`;


const PostContentContainer = styled.div`
    display:flex;
    flex-direction:column;
    padding:5px;
    color:var(--twikVibe-light-theme-color);
    gap:1em;
    position: relative;
`;

const PostContent= styled.div`
    line-height:1.5em;
    font-size: 16px;
    word-break: break-word;
`;

const PostImagesContainer = styled.div`
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 5px; /* Optional: Adds padding around the scroll area */
    gap: 5px; /* Space between each item */
    cursor: ${({ isdragging }) => (isdragging ? 'grabbing' : 'grab')};
    user-select: none;
    // width: 100%;
    
    /* Hide the scrollbar but still allow scrolling */
    &::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
    }
    -ms-overflow-style: none; /* For IE and Edge */
    scrollbar-width: none; /* For Firefox */
    
`;
const PostContentImgCon = styled.div`
    display: inline-block;
    cursor: drag;
    background:whitesmoke;
    width:200px;
    height:200px;
    border-radius:5px;
    // overflow: hidden;
    flex-shrink: 0;
    user-select: none;
    position: relative;
`;

const PostContentImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    border-radius:5px;
`;

const PostContentImgOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

`;




const PostReactionContainer = styled.div`
    // font-size:27px;
    display:flex;
    gap:1em;
    // cursor:pointer;
    // padding:5px;
    align-items: center;
`;

const ReactionItem = styled.div`
    display:flex;
    gap: 10px;
    cursor:pointer;
    align-items: center;
    color: var(--twikVibe-light-text-color);
    padding:5px;
    font-size: 14px;
    justify-content: center;
`;



const PostCommentParentContainer = styled.div`
    padding:10px;
    display:flex;
    flex:1;
    gap:1.5em;
    flex-direction:column;
    justify-content: flex-end;
    
`;

const CommentParentTxt=styled.h4`
    font-size:15px;
`;

// comment input section 
const CommentInputParentContainer =styled.label`
    position:sticky;
    bottom:0px;
    padding:10px;
    background:var(--twikVibe-dark-theme-bg-color);
    border-style:solid;
    border-color:var(--twikVibe-dark-grey-bg);
    border-width:1px;
    border-radius:5px;
    background-color: var(--twikVibe-dark-grey-bg);
    border-radius: 10px;
    cursor: text;

    @media screen and (max-width: 600px){
        position: fixed;
        left: 0;
        right: 0;
        bottom: 60px;
        z-index: 10;
    }
`;

const CommentInputBox =styled.input`
    // height:60px;
    width: 100%;
    border-radius:5px;
    border: none;
    background: none;
    color: var(--twikVibe-light-text-color);
    padding: 10px;
    background-color: transparent;
    font-size: 16px;
    // font-weight: 500;
`;

const CommentInputContainer = styled.div`
    display:flex;
    gap:20px;
    align-items:center;
`;



const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:4px;
    height:4px;
    border-style:solid;
    border-left-color:var(--twikVibe-light-theme-bg-color);
    border-top-color:var(--twikVibe-light-theme-bg-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin: auto;
`;

const HasNotPaidContainer = styled.div`
    background: var(--twikVibe--darker-transparent-black-color);
    // background: rgba(43, 159, 255, 0.9);
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    border-radius: 10px;
`;

const PayButton = styled.div`
    background: var(--twikVibe-brand-color);
    color: var(--twikvibe-white-color);
    font-size: 15px;
    cursor: pointer;
    padding: 10px;
    border-radius: 20px;

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;

const PostDetailPage = ({setShowHeader, setShowPopupMenuButton, setShowBackButton}) => {
  // the checking of id will be done from the backend before sednign to the frontend
  // martins abeg no still vex 
    const { userName, postId } = useParams();
    // this will be sent to backend witouth the @
    const cleanUsername = userName.replace('@', '');

    const navigate = useNavigate();
    const accessToken = localStorage.getItem('accessToken')
    const userId = localStorage.getItem('userId')

    const [post, setPost] = useState({});
    const [user, setUser] = useState({});
    const [postNotFound, setPostNotFound] = useState(false);
    const [loading, setLoading] = useState(true)
    const [comments, setComments] = useState([])

    const [hasPaid, setHasPaid] = useState(false);

    const [isLiked, setIsLiked] = useState(false);
    const [likesLength, setLikesLength] = useState(0);
    const [loadingComments, setLoadingComments] = useState(true)

    const [showTipUser, setShowTipUser] = useState({
        userName: null,
        profilePicture: null
    })

    const [showPayToSeePost, setShowPayToSeePost] = useState({})

    const [commentContent, setCommentContent] = useState('');
    const [loadingSendComment, setLoadingSendComment] = useState(false)

    const scrollContainerRef = useRef(null); // Reference to the container for dragging
    const [isDragging, setIsDragging] = useState(false); // Track if dragging is active
    const [startX, setStartX] = useState(0); // Initial X position when drag starts
    const [scrollLeft, setScrollLeft] = useState(0);


    // remove side bar 
    useEffect(()=>{
        setShowHeader(true)

        setShowPopupMenuButton(false)
        // hide mobile back button button
        setShowBackButton(true)
    },[setShowHeader, setShowBackButton, setShowPopupMenuButton])    

    const togglePostReaction = async({postId})=>{

        // toggle the reaction
        if(isLiked){
            setIsLiked(prev=>!prev)
            setLikesLength(likesLength - 1)
        }else{
            setIsLiked(prev=>!prev)
            setLikesLength(likesLength + 1)
        }
        
          

        try {
            await axios.post(`${API_ROUTE}togglePostReaction?postId=${postId}`,
            {},
            {
                headers: {Authorization: accessToken}
            })

            
        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    await axios.post(`${API_ROUTE}togglePostReaction?postId=${postId}`,
                        {},
                        {
                            headers: {Authorization: accessToken}
                        })

                }
            }else{

                // toggle it back if i fails
                // toggle the reaction
                if(isLiked){
                    setIsLiked(prev=>!prev)
                    setLikesLength(likesLength - 1)
                }else{
                    setIsLiked(prev=>!prev)
                    setLikesLength(likesLength + 1)
                }
                console.log(error)
            }
        }
    }

    const toggleSubscribeToUser = async({userIamSubscribingToId, index})=>{

        // toggle the subscribe button
        // setPost((prevPosts) =>
        //     prevPosts.map((post, i) =>
        //       i === index
        //         ? {
        //             ...post,
        //             subscribers: post.subscribers.includes(userId)
        //               ? post.subscribers.filter((id) => id !== userId) // Unlike
        //               : [...post.subscribers, userId], // Like
        //           }
        //         : post
        //     )
        // );


        try {

            await axios.post(`${API_ROUTE}toggleSubscribeToUser?userIamSubscribingToId=${userIamSubscribingToId}`,
                {},
                {
                    headers: {Authorization: accessToken}
                });

            // set the subscribe button to the opposite of what it was initially

        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();
                

                if(accessToken){
                    await axios.post(`${API_ROUTE}toggleSubscribeToUser?userIamSubscribingToId=${userIamSubscribingToId}`,
                        {},
                        {
                            headers: {Authorization: accessToken}
                        });
                    
                }
            }else{

                // toggle back if it fails
                // setPost((prevPosts) =>
                //     prevPosts.map((post, i) =>
                //       i === index
                //         ? {
                //             ...post,
                //             subscribers: post.subscribers.includes(userId)
                //               ? post.subscribers.filter((id) => id !== userId) // Unlike
                //               : [...post.subscribers, userId], // Like
                //           }
                //         : post
                //     )
                // );
        
                console.log(error)
            }
        }
    }
    
    


    useEffect(()=>{
        if(!userName.includes('@')){
            navigate(`/@${userName}/post/${postId}`);
        }
    },[navigate, userName, postId]);


    const getUserProfile = useCallback(async(userId)=>{

        try {
            const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`,{
                headers: {Authorization: accessToken}
            })

            console.log('fetching profile');


            setUser(response.data.returnWithoutPassword)
            
        } catch (error) {
            // check if accesstoken has expired and refresh
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`,{
                        headers: {Authorization: accessToken}
                    })

    
                    setUser(response.data.returnWithoutPassword)
                }
            }else if(error.status === 404){
                // navigate to not found if no user exists with this username
                console.log(error)

                // set the post to not found if the user is not found
                setPostNotFound(true);
            }else{
                console.log(error)
            }
        }
    },[accessToken])


    const getCommentUserProfiles = useCallback(async({userId})=>{
        console.log(userId)
        try {
            const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`,{
                headers: {Authorization: accessToken}
            });

            const userName = response.data.returnWithoutPassword.userName;
            const fullName = response.data.returnWithoutPassword.fullName;
            const profilePicture = response.data.returnWithoutPassword.profilePicture;
            const subscribers = response.data.returnWithoutPassword.subscribers;
            return {fullName: fullName, profilePicture: profilePicture, userName: userName, subscribers: subscribers};
        } catch (error) {
            console.error('Error fetching user profile:', error);
        }
    },[accessToken])

    const getComments = useCallback(async()=>{
        
        try {
            const response = await axios.get(`${API_ROUTE}getComments?postId=${postId}`,{
                headers: {Authorization: accessToken}
            })


            const userData = await Promise.all(
                response.data.map(async (comment) => {
                    const userProfile = await getCommentUserProfiles({ userId: comment.userId });
                    return {
                        ...comment,
                        userName: userProfile.userName,
                        fullName: userProfile.fullName, // Attach the fetched full name to the chat
                        profilePicture: userProfile.profilePicture, // Attach the fetched full name to the chat
                        subscribers: userProfile.subscribers,
                    };
                })
            );

            console.log(userData)
            setComments(userData)
            setLoadingComments(false)
            // console.log(response.data)
        } catch (error) {
            // check if access token has expired
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    const response = await axios.get(`${API_ROUTE}getAllPosts`,{
                        headers: {Authorization: accessToken}
                    })
                    
                    const userData = await Promise.all(
                        response.data.map(async (comment) => {
                            const userProfile = await getCommentUserProfiles({ userId: comment.userId });
                            return {
                                ...comment,
                                userName: userProfile.userName,
                                fullName: userProfile.fullName, // Attach the fetched full name to the chat
                                profilePicture: userProfile.profilePicture, // Attach the fetched full name to the chat
                                subscribers: userProfile.subscribers,
                            };
                        })
                    );

                    setComments(userData)
                    setLoadingComments(false)
                }
            }else{
                console.log(error)
            }
        }
    },[accessToken, getCommentUserProfiles, postId])


    const getSinglePost = useCallback(async()=>{
        try {
            const response = await axios.get(`${API_ROUTE}getSinglePost?postId=${postId}`,{
                headers: {Authorization: accessToken}
            });

            

            // get the owner of this post
            await getUserProfile(response.data.post.userId)

            // check if the user has lked this post
            if(response.data.post.reactionsId.includes(userId)){
                setIsLiked(true)
            }else{
                setIsLiked(false)
            }

            // number of likes
            setLikesLength(response.data.likesLength)
            

            setPost(response.data.post)

            setHasPaid(response.data.hasPaid)

            console.log(response.data.post)

            setLoading(false);

            // fetch the comments
            getComments();

        } catch (error) {
            if(error.status === 403){
                // refresh acces token if expired
                const { accessToken } = await refreshAccessToken();
                
                // check if access token was returned
                if(accessToken){
                    const response = await axios.get(`${API_ROUTE}getSinglePost?postId=${postId}`,{
                        headers: {Authorization: accessToken}
                    });
        

                    // get the owner of this post
                    await getUserProfile(response.data.post.userId)

                    // check if the user has lked this post
                    if(response.data.post.reactionsId.includes(userId)){
                        setIsLiked(true)
                    }else{
                        setIsLiked(false)
                    }

                    setPost(response.data.post)

                    setHasPaid(response.data.hasPaid)

                    setLoading(false);

                    // fetch the comments
                    getComments();
                }
            }else if(error.status === 404){
                // navigate to not found if no user exists with this username
                console.log(error)

                // set the post to not found if the post is not found
                setPostNotFound(true);
                setLoading(false);
            }else{
                console.log(error)
            }
        }
    },[postId, accessToken, getUserProfile, getComments, userId])


    

    useEffect(()=>{
        getSinglePost();
    },[getSinglePost])



    useEffect(()=>{
        if(user.userName && user.userName !== cleanUsername){
            // set the post to not found if the username in the url is not the same as the owner of the post
            setPostNotFound(true);
        }
    },[user, cleanUsername])


    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Scroll speed
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    }; 

    const createComment = async()=>{
        if(!commentContent){
            console.log('please add text')
            return;
        }

        setLoadingSendComment(true)

        try {
            await axios.post(`${API_ROUTE}createComment`,
            {
                commentContent,
                postId
            },
            {
                headers: {Authorization: accessToken}
            })

            setLoadingSendComment(false)
            // fetch the comments
            getComments();
            setCommentContent('')
        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    createComment()
                }
            }else{
                console.log(error.response.data.error)
                setLoadingSendComment(false)
            }
        }
    }


    return (
        <Container>
      
            {/*  post detail   */}

            {/* check if the page is still loadnig */}
            {loading
            ?<div>Loading...</div>

            // ceck if the post is not found
            :postNotFound
            ?<div>Post not found</div>

            // else, display the post if everything is fine
            :<PostDetailContainer>

            
                {/* render post component and pass data to component  */}
                <MainPostContainer>
                    

                    <PostProfileParentContainer>

                        {/* Post profile pics beginning */}
                        <Link className="transparent_click" to={`/@${user.userName}`}>
                            <PostProfilePicsCon>
                                <PostProfilePics src={user.profilePicture}/>
                            </PostProfilePicsCon>
                        </Link>
                    
                        {/* Post UserName Section Beginning */}
                        <PostProfileUserNameContainer>
                            <PostProfileUserName><Link style={{color:'var(--twikVibe-light-text-color)'}} className="transparent_click" to={`/@${user.userName}`}>{user.userName}</Link></PostProfileUserName>

                            {/* div appears when username is hovered */}
                            <ProfileDetailHover className="profileDetailHover">
                                {user.userName}

                                {/* Post profile subscription Begining */}
                                <PostProfileSubcribeBtn onClick={()=>toggleSubscribeToUser({userIamSubscribingToId: post.userId})}>{user.subscribers.includes(userId)?'Unsubscribe':'Subscribe'}</PostProfileSubcribeBtn>
                            </ProfileDetailHover>

                            <PostTimeStamp>{formatTime(post.timeStamp)}</PostTimeStamp>
                        </PostProfileUserNameContainer>

                        <SlOptionsVertical  style={{cursor:"pointer", justifyContent:'flex-end', color:'var(--twikVibe-light-text-color)'}} />
                        
                
                    </PostProfileParentContainer>
                    
                    {/* under the user profile  */}
                    <PostContentContainer>
                        
                        <PostContent>{detectAndConvertLinks(post.content)}</PostContent>

                        {/* check if the post is paid and the user has not paid yet */}
                        {post.isPostPaid&&!hasPaid&&post.userId!==userId   ?   (
                            <HasNotPaidContainer>
                                <PayButton onClick={()=>{
                                    setShowPayToSeePost(post)
                                }}>Pay {Number(post.postPrice).toLocaleString("en-US", {style:"currency", currency:"USD"})} to view this content.</PayButton>
                            </HasNotPaidContainer>
                        )  :   (
                            <>
                                {post.files.length>0&&
                                    <PostImagesContainer
                                        ref={scrollContainerRef}
                                        onMouseDown={handleMouseDown}
                                        onMouseLeave={handleMouseLeave}
                                        onMouseUp={handleMouseUp}
                                        onMouseMove={handleMouseMove}
                                        isdragging={isDragging}
                                    >

                                        {/* list of images in post */}
                                        {post.files.map((file, index)=>(
                                            <PostContentImgCon key={index}>
                                                {/* check if the fil is an image or video */}

                                                {file && file.pinataUrl && (file.fileName.endsWith('.jpg') || file.fileName.endsWith('.png')) ? (
                                                    <PostContentImg src={file.pinataUrl} alt={`post ${index}`}/>
                                                )   :   file && file.pinataUrl && file.fileName.endsWith('.mp4') ? (
                                                    // <video style={{width:'100%', height:'100%'}}>
                                                    <video style={{width:'100%', height:'100%', objectFit:'cover'}} autoPlay loop controls>
                                                        <source src={file.pinataUrl}  />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                ) : null}
                                                <PostContentImgOverlay/>
                                            </PostContentImgCon>
                                        ))}
                                    </PostImagesContainer>}
                            </>
                        )}
                        
                    </PostContentContainer>


                    <PostReactionContainer>
                            
                        {/* post likes */}
                        {isLiked
                        ?<ReactionItem><AiFillHeart size={20} style={{cursor:'pointer' ,fill:'var(--twikVibe-brand-color)'}} onClick={()=>togglePostReaction({postId: post._id})} /> {formatLikes(likesLength)}</ReactionItem>
                        :<ReactionItem><AiOutlineHeart size={20}  style={{cursor: 'pointer', }} onClick={()=>togglePostReaction({postId: post._id})} /> {formatLikes(likesLength)}</ReactionItem>}
                        
                        {/* tip user button */}
                        {user._id!==userId&&<AiOutlineDollar  onClick={()=>{
                            setShowTipUser((prev)=>({
                                ...prev,
                                userName: user.userName,
                                profilePicture: user.profilePicture
                            }))
                        }}  size={20}  style={{cursor: 'pointer'}}/>}
                    </PostReactionContainer>
                </MainPostContainer>






                {/*comment parent header section or container   */}
                <PostCommentParentContainer>
                    {post.everyoneCanComment &&<CommentParentTxt>Comments ({comments.length})</CommentParentTxt>}
                    
                    {/* comment Component  */}
                    {loadingComments
                    ?'loading'
                    :<Comment commentsData={comments} setComments={setComments} postId={postId}/>}


                    {/* comment section container  */}
                    {/* check if comments are turned off */}
                    {post.everyoneCanComment    ?   (
                        <CommentInputParentContainer>
                        <CommentInputContainer>
                            <CommentInputBox  value={commentContent} onChange={(e)=>setCommentContent(e.target.value)} placeholder="What do you have to say ?" />

                            {loadingSendComment
                            ?<LoadingSpinnerElement/>
                            :<BsFillSendFill onClick={createComment} color="var(--twikVibe-light-text-color)" size={20} style={{cursor:"pointer"}}/>}

                        </CommentInputContainer>
                        </CommentInputParentContainer>

                    // check if only paid veivwers can comment
                    )   :post.onlyPaidViewersCanComment  && hasPaid   ?   (

                        <CommentInputParentContainer>
                            <CommentInputContainer>
                                <CommentInputBox  value={commentContent} onChange={(e)=>setCommentContent(e.target.value)} placeholder="What do you have to say ?" />

                                {loadingSendComment
                                ?<LoadingSpinnerElement/>
                                :<BsFillSendFill onClick={createComment} color="var(--twikVibe-light-text-color)" size={20} style={{cursor:"pointer"}}/>}

                            </CommentInputContainer>
                        </CommentInputParentContainer>
                    )   :   (
                        <div style={{color:'var(--twikvibe-grey-color)', fontSize:'14px', textAlign: 'center', fontWeight:'600'}}>Comments are turned off for this post.</div>
                    )}
                    
                </PostCommentParentContainer>


            </PostDetailContainer>}

            {/* tip user modal */}
            {showTipUser.userName&&<TipUser userName={showTipUser.userName} profilePicture={showTipUser.profilePicture} setShowTipUser={setShowTipUser}/>}
            {showPayToSeePost._id&&<PayToSeePost postOwnerUsername={userName} post={showPayToSeePost} setShowPayToSeePost={setShowPayToSeePost} />}

        </Container>
    )
}

export default PostDetailPage