import { useCallback, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import whiteDollarIcon from "../assets/icons/white_dollar.svg";
import blackDollarIcon from "../assets/icons/black_dollar.svg";
import { IoClose } from "react-icons/io5";
import { API_ROUTE } from "../utils/Api";
import axios from "axios";
import { IoArrowBackOutline } from "react-icons/io5"
import { refreshAccessToken } from "../utils/RefreshAccessToken";
import { Link } from "react-router-dom";

const Container = styled.div`
    
`;
const ModalBG = styled.div`
    background: var(--twikVibe--darker-transparent-black-color);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 13;
    
    @media screen and (max-width: 600px){
        padding: 10px;
    }
`;
const TipContainer = styled.div`
    background: var(--twikVibe-dark-grey-bg );
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    width: 350px;
    position: relative;
    align-items: center;

    max-height: 90vh;
    overflow-y: auto;
`;
const TipHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
`;

const DollarIconContainer = styled.div`
    width: 60px;
    height: 60px;
    position: relative;
`;
const DollarIcon = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;
const ProfilePicCon = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 100%;
    overflow: hidden;
    position: absolute;
    top: -13px;
    right: -13px;
    border: solid 1px var(--twikVibe-light-theme-bg-color);
`;
const ProfilePic = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

const SendText = styled.div`
    color: var(--twikVibe-light-text-color);
    font-size: 13px;
    font-weight: 500;
    word-break: break-word;
`;
const TipInputContainer = styled.label`
    display: flex;
    
    // align-items: center;
    gap: 5px;
    // justify-content: space-between;
    flex-direction: column;
    color: var(--twikVibe-light-text-color);
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    

    // &:focus-within{
    //     border: 2px solid var(--twikVibe-brand-color);
    // }
`;
const TipInput = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }
    padding: 15px;
    background-color: var(--twikVibe-dark-theme-color);
    border-radius: 10px;
    flex: 1;
    width: 100%:
    border: none;
    color: var(--twikVibe-light-text-color);
    font-weight: 500;
    font-size: 16px;
`;

const DeductedText = styled.div`
    color: var(--twikvibe-grey-color);
    font-size: 12px;
    font-weight: 600;
`;
const ActionButton = styled.button`
    width: 100%;
    padding: 10px;
    background: var(--twikVibe-brand-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: var(--twikvibe-white-color);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;

const CloseContainer = styled.div`
    width: 100%;
    justify-content: space-between;
    display: flex;
`;

const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:2px;
    height:2px;
    border-style:solid;
    border-left-color:var(--twikVibe-light-text-color);
    border-top-color:var(--twikVibe-light-text-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
`;

const CodeInputContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0px;
    width: 100%;
`;
const CodeInput = styled.input`
    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }
    
    border-radius: 10px;
    outline: none;
    border: solid 2px var(--twikVibe-dark-grey-bg);
    background: var(--twikVibe-dark-theme-color);
    color: var(--twikVibe-light-text-color);
    flex: 1;
    width: 60%;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    transition: 0s;
    padding: 5px;

    &:focus{
        border: solid 2px var(--twikVibe-brand-color);
    }
`;

const ResetPin = styled.div`
    font-size: 12px;
    text-align: right;
`;

const TransactionPinText = styled.div`
    color: var(--twikVibe-light-text-color);
    font-size: 14px;
    font-weight: 600;
    width: 250px;
    display: flex;
    flex-direction: column;
    gap: 5px;

`;
const TipUser = ({userName, profilePicture, setShowTipUser}) => {

    const [transactionTxtBtn,setTransactionTxtBtn]=useState({
        mode:"pointer",
        txt:"Send tip"
    });

    const [amount, setAmount] = useState('');
    const [user, setUser] = useState({})
    const [error, setError] = useState('');
    const [balanceError, setBalanceError] = useState('');
    const [amountEntered, setAmountEntered] = useState(false);
    
    const inputLength = 4;
    
    // the array for the transaction pin input
    const [transactionPin, setTransactionPin] = useState(new Array(inputLength).fill(''));

    // state to check if the number of entered pin is up to 4 
    const [pinUpTo4Digits, setPinUpTo4Digits] = useState(false);

    // the completed pin
    const [completedPin, setCompletedPin] = useState(null);

    const [sendingTip, setSendingTip] = useState(false)
    const [loadingData, setLoadingData] = useState(true)

    const accessToken = localStorage.getItem('accessToken')
    const userId = localStorage.getItem('userId')

    const amountRef = useRef(null);

    const selecetedTheme = localStorage.getItem('selectedTheme');
    const [currentMode, setCurrentMode] = useState('')

    const handleAmountChange = (e)=>{
        const value = e.target.value;

        if(!isNaN(value)){
            setAmount(value);

            if(value > user.walletBalance){
                setBalanceError('Insufficient funds')
            }else{
                setBalanceError('')
            }
        }
        
    }

    const handleWheel = (event) => {
        // Prevent scroll
        event.preventDefault();
    };

    useEffect(() => {
        const amountElement = amountRef.current;
        if (amountElement) {
            amountElement.addEventListener('wheel', handleWheel);

            // Cleanup function to remove the event listener
            return () => {
                amountElement.removeEventListener('wheel', handleWheel);
            };
        }
    }, []);

    const getUserProfile = useCallback(async()=>{
        try {
            const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`,{
                headers: {Authorization: accessToken}
            });

            setUser(response.data.returnWithoutPassword)
            setLoadingData(false)
        } catch (error) {

            // check if the error is caused by expired token
            if(error.status===403){
                const {accessToken} = await refreshAccessToken();

                if(accessToken){
                    const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`,{
                        headers: {Authorization: accessToken}
                    });
        
                    setUser(response.data.returnWithoutPassword)
                    setLoadingData(false)
                }
            }else{
                console.log(error)
            }
        }
    },[accessToken, userId])

    useEffect(()=>{
        getUserProfile();
    },[getUserProfile])
    
    const closeModal =()=>{
        setShowTipUser((prev)=>({
            ...prev,
            userName: null,
            profilePicture: null
        }))
    }
    

    useEffect(()=>{
        if(selecetedTheme==='dark'){
            setCurrentMode('dark')
        }else{
            setCurrentMode('light')
        }
    },[selecetedTheme])



    const tipUser = async()=>{
        setSendingTip(true)
        setError('')
        setBalanceError('')


        try {
            const response = await axios.post(`${API_ROUTE}tipUser`,{
                amount, 
                receiverUserName: userName,
                transactionPin: completedPin
            },{
                headers: {Authorization:accessToken}
            })
            if(response){
                setSendingTip(false);
                setTransactionTxtBtn({
                    txt:"Successful",
                    mode:"not-allowed"
                })
                
                setTimeout(async()=>{
                    closeModal()
                    setTransactionTxtBtn({
                        txt:"Send tip",
                        mode:"pointer"
                    })
                },5000);
                clearTimeout();
            }
        } catch (error) {
            // check if the error is caused by expired token
            if(error.status===403){
                const {accessToken} = await refreshAccessToken();

                if(accessToken){
                    const response = await axios.post(`${API_ROUTE}tipUser`,{
                        amount, 
                        receiverUserName: userName,
                        transactionPin: completedPin
                    },{
                        headers: {Authorization:accessToken}
                    })
                    if(response){
                        setSendingTip(false)
                        closeModal()   
                    }
                }
            }else{
                console.log(error.response.data.error)
                setError(error.response.data.error)
                setSendingTip(false)
            }
        }
    }


    const handlePinInputChange = (e, index)=>{
        const value = e.value;
        setError('');

        
        // Ensure the input is a number and of a single character
        if (isNaN(value) || value.length > 1) return;

        const pin = [...transactionPin];
        pin[index] = value;
        setTransactionPin(pin)

        if(index < inputLength - 1 && value){
            e.nextSibling.focus();
        }

        // Check if all inputs are filled
        const allFilled = pin.every(val => val.length === 1);

        setCompletedPin(pin.join(''))
        
        if (allFilled) {
            setPinUpTo4Digits(true); 
        }else{
            setPinUpTo4Digits(false)
        }
    }

    const handleBackspace=(e, index)=>{
        const pin = [...transactionPin];
        pin[index] = '';
        setTransactionPin(pin)

        if(index > 0){
            e.previousSibling.focus();
        }
    }
    
    return (
        <Container>
            <ModalBG title="close modal" onClick={()=>closeModal()}>
                {amountEntered
                ?<TipContainer title="" onClick={(e)=>e.stopPropagation()}>
                <TipHeader>
                    <CloseContainer>
                        <IoArrowBackOutline onClick={()=>{
                            setAmountEntered(!amountEntered)

                            // clear the existing errors and pin
                            setError('')
                            setBalanceError('')
                            setTransactionPin(new Array(inputLength).fill(''))
                        }} size={20} style={{color:'var(--twikVibe-light-text-color)', cursor:'pointer', }} />
                        <IoClose onClick={()=>closeModal()} size={20} style={{color:'var(--twikVibe-light-text-color)', cursor:'pointer', }} />
                    </CloseContainer>
                    <DollarIconContainer>
                        <DollarIcon src={currentMode==='dark'?whiteDollarIcon:blackDollarIcon} alt='Dollar icon'/>

                        {/* hovering profile pic */}
                        <ProfilePicCon>
                            <ProfilePic src={profilePicture} alt="profile pic" />
                        </ProfilePicCon>
                    </DollarIconContainer>
                </TipHeader>
                <SendText>Are you sure you want to send {amount?`${Number(amount).toLocaleString("en-US", {style:"currency", currency:"USD"})}`:null} to <strong>@{userName}</strong>?</SendText>

                {/* transaction pin input */}

                <TransactionPinText>
                    Transaction pin
                    <CodeInputContainer>
                        {transactionPin.map((data, index) => (
                            <CodeInput
                                disabled={sendingTip}
                                key={index}
                                maxLength={1}
                                type="text"
                                value={data}
                                onChange={(e) => handlePinInputChange(e.target, index)}
                                placeholder="-"
                                onKeyDown={(e)=>{
                                    if(e.key==='Backspace'){
                                        handleBackspace(e.target, index)
                                    }
                                }}
                            />
                        ))}
                        {/* {!verifyingCode&&<div style={{width: '3px', padding:'10px', marginLeft:'20px'}}/>} */}
                        {/* {verifyingCode&&<LoadingSpinnerElement/>} */}
                    </CodeInputContainer> 
                    {/* reset transaction pin button */}
                    <ResetPin><Link to='/reset-transaction-pin' style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click">Reset pin?</Link></ResetPin> 
                </TransactionPinText>
                
                
                

                {error&&<div style={{color:'red', fontSize: '13px', fontWeight:'500'}}>{error}</div>}
                
                <DeductedText>The Amount is Automatically <br/> deducted from your balance.</DeductedText>
                
                {/* che if the enetered pin is up to 4 */}
                {pinUpTo4Digits

                ?<>
                    {/* check if tht tip is sending a show a loading spinner */}
                    {sendingTip
                    ?<ActionButton><LoadingSpinnerElement style={{borderLeftColor: 'var(--twikvibe-white-color)', borderTopColor: 'var(--twikvibe-white-color)'}}/></ActionButton>
                    :<ActionButton onClick={tipUser} style={{cursor:`${transactionTxtBtn.mode}`}}>{transactionTxtBtn.txt}</ActionButton>}
                </>

                // pin is not up to 4
                :<ActionButton style={{background:'var(--twikvibe-grey-color)', cursor:'not-allowed'}}>Send tip</ActionButton>}
            </TipContainer>

            // the amount has not been set yet
            :<TipContainer title="" onClick={(e)=>e.stopPropagation()}>
                    <TipHeader>
                        <CloseContainer><div/><IoClose onClick={()=>closeModal()} size={20} style={{color:'var(--twikVibe-light-text-color)', cursor:'pointer', }} /></CloseContainer>
                        <DollarIconContainer>
                            <DollarIcon src={currentMode==='dark'?whiteDollarIcon:blackDollarIcon} alt='Dollar icon'/>

                            {/* hovering profile pic */}
                            <ProfilePicCon>
                                <ProfilePic src={profilePicture} alt="profile pic" />
                            </ProfilePicCon>
                        </DollarIconContainer>
                    </TipHeader>
                    <SendText>Send <strong>@{userName}</strong> {amount?`${Number(amount).toLocaleString("en-US", {style:"currency", currency:"USD"})}`:null}</SendText>
                    {loadingData
                    ?<LoadingSpinnerElement/>
                    :<TipInputContainer>
                        Amount
                        <TipInput onChange={(e)=>handleAmountChange(e)} ref={amountRef} value={amount} placeholder="amount" type="number" />
                    </TipInputContainer>}
                    {balanceError&&<div style={{color:'red', fontSize: '13px', fontWeight:'500'}}>{balanceError}</div>}
                    <br/>
                    <DeductedText>The Amount is Automatically <br/> deducted from your balance.</DeductedText>
                    

                    {/* check if a valid amount has been entered */}
                    {amount && !balanceError && amount > 0
                    ?<ActionButton onClick={()=>setAmountEntered(!amountEntered)}>Proceed</ActionButton>
                    :<ActionButton style={{background:'var(--twikvibe-grey-color)', cursor:'not-allowed'}}>Proceed</ActionButton>}
                </TipContainer>}
            </ModalBG>
            
        </Container>
    )
}

export default TipUser




