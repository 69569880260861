import styled, { keyframes } from "styled-components";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API_ROUTE } from "../utils/Api";
import { IoImages } from "react-icons/io5";
import { refreshAccessToken } from "../utils/RefreshAccessToken";
import ToggleSwitch from "../components/ToggleSwitch";
import { IoClose } from "react-icons/io5";


const Container = styled.div`
    flex: 1;
`;
const PostContainer = styled.div`
    width: 530px;
    margin: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 650px){
        width: auto;
    }
`;

const PostButton = styled.div`
    // color: var(--twikVibe-dark-text-color);
    // background: var(--twikVibe-light-theme-bg-color);
    background: var(--twikVibe-brand-color);
    color: var(--twikvibe-white-color);
    padding: 15px;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;
    

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }

    @media screen and (max-width: 600px){
        margin-bottom: 80px;
    }
`;
const TextAreaContainer = styled.div`
    display: flex;
`;
const TextArea = styled.textarea`
    width: 100%;
    font-size: 18px;
    color: var(--twikVibe-light-text-color);
    background: none;
    border: none;
    resize: none !important;
    draggable: false !important; 
    max-height: 300px;
    font-weight: 600;
`;
const ImageAndVideoIconsAndTextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const ImageAndVideoIconsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
`;
const ImageAndVideoIcon = styled.label`
    cursor: pointer;
    background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    padding: 10px;
    align-items: center;
    display: flex;
    gap: 10px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
`;
const ImageAndVideoFileSlect = styled.input`
    display: none;
`;

const ImageAndVideoPreviewContainer = styled.div`
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 10px; /* Optional: Adds padding around the scroll area */
    gap: 10px; /* Space between each item */
    cursor: ${({ isdragging }) => (isdragging ? 'grabbing' : 'grab')};
    user-select: none;
    
    /* Hide the scrollbar but still allow scrolling */
    &::-webkit-scrollbar {
        display: none; /* For Chrome, Safari, and Opera */
    }
    -ms-overflow-style: none; /* For IE and Edge */
    scrollbar-width: none; /* For Firefox */
`;
const ImageAndVideoPreviewItemContainer = styled.div`
    display: inline-block;
    cursor: drag;
    background:whitesmoke;
    width:150px;
    height:150px;
    border-radius:5px;
    // overflow: hidden;
    flex-shrink: 0;
    user-select: none;
    position: relative;
`;
const ImageAndVideoPreviewImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    border-radius:5px;
`;

const ImageOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

`;

const FreeOrPaidContainer = styled.div`
    background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;

`;

const InnerFreeOrPaidContainer = styled.div`
    align-items: center;
    display: flex;
    gap: 10px;
    border-radius: 10px;
    justify-content: space-between;
    font-size: 15px;
    // font-weight: 600;
    position: relative;
    line-height: 2;

    @media screen and (max-width: 550px){
        display: block;
    }
`;

const PaidSmallText = styled.div`
    color: var(--twikvibe-grey-color);
    font-size: 12px;
    font-weight: 600;
`;
const FreeOrPaidInputAmountContainer = styled.label`
    font-size: 16px;
    display: flex;
    background-color: var(--twikVibe--darker-transparent-black-color);
    border-radius: 10px;
    padding: 5px;
    align-items: center;
`;
const DollarSign = styled.div`
    font-size: 16px;
    background: none;
    border: none;
    padding: 5px;
    color: var(--twikvibe-grey-color);
    font-weight: 600;
    user-select: none;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    cursor: text;
`;
const FreeOrPaidAmountInput = styled.input`
    color: var(--twikVibe-light-text-color);
    font-size: 16px;
    background: none;
    border: none;
    width: 100%;
    padding: 10px;

    /* Hide up and down arrows in number input */
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & {
        -moz-appearance: textfield; /* Firefox */
    }
`;

const HideCommentContainer = styled.div`
    background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
`;

const HideCommentInnerContainer = styled.div`
    align-items: center;
    display: flex;
    gap: 10px;
    border-radius: 10px;
    justify-content: space-between;
    font-size: 15px;
    // flex-direction: column;
    gap: 20px;
    // font-weight: 600;
    position: relative;

    @media screen and (max-width: 500px){
        display: block;
    }
`;

const DropdownContainer = styled.div`
    position: absolute;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0,0,0,0.1);
    // border: solid .2px var(--twikvibe-grey-color);
    background: var(--twikVibe-dark-grey-bg);
    width: 180px;
    top: 50px;
    right: 0px;
    z-index: 10;
`;

const DropdownItem = styled.div`
    padding: 15px;
    border-radius: 10px;
    background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;

    &:hover{
        background: var(--twikVibe--darker-transparent-black-color);
    }
`;

const DropdownButton = styled.div`
    padding: 15px;
    border-radius: 10px;
    background: var(--twikVibe-dark-grey-bg);
    color: var(--twikVibe-light-text-color);
    cursor: pointer;
    font-size: 15px;
    border: solid 2px var(--twikVibe--darker-transparent-black-color);
    // width: 100%;
    text-align: center;

    &:hover{
        background: var(--twikVibe--darker-transparent-black-color);
    }

    @media screen and (max-width: 500px){
        margin-top: 20px;
    }
`;

const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:4px;
    height:4px;
    border-style:solid;
    border-left-color:var(--twikvibe-white-color);
    border-top-color:var(--twikvibe-white-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin: auto;
`;



const Createpostpage = ({setShowHeader, setShowPopupMenuButton}) => {

    const [loading, setLoading] = useState(false);
    const [isPostPaidCheck, setIsPostPaidCheck] = useState(false);
    const [showCommentAuthPopup, setShowCommentAuthPopup] = useState(false);
    const [whoCanComment, setWhoCanComment] = useState('Everyone');
    const [everyoneCanComment, setEveryoneCanComment] = useState(true);
    const [onlyPaidViewersCanComment, setOnlyPaidViewersCanComment] = useState(false);

    const scrollContainerRef = useRef(null); // Reference to the container for dragging
    const [isDragging, setIsDragging] = useState(false); // Track if dragging is active
    const [startX, setStartX] = useState(0); // Initial X position when drag starts
    const [scrollLeft, setScrollLeft] = useState(0); // Initial scroll position when drag starts


    const [postContent, setPostContent] = useState('');
    const [paymentAmountInput, setPaymentAmountInput] = useState('');
    const [files, setFiles] = useState([]);
    const [filesPreview, setFilesPreview] = useState([]);
    const paidAmountRef = useRef(null)

    const accessToken = localStorage.getItem('accessToken')
    


    const textareaRef = useRef(null);

    // remove side bar 
    useEffect(()=>{
        setShowHeader(true)
        setShowPopupMenuButton(false)
    },[setShowHeader, setShowPopupMenuButton])



    const handlePostContentChange=(e)=>{
        const value = e.target.value;

        setPostContent(value);

        const maxRows = 5;

        const textarea = textareaRef.current;

        textarea.style.height = "auto";

        // Calculate the number of rows based on the scrollHeight
        const rows = Math.min(textarea.scrollHeight / textarea.offsetHeight, maxRows);

        if (rows >= maxRows) {
            textarea.style.height = `${textarea.scrollHeight}px`;
            textarea.style.overflowY = "scroll"; // Enable scrolling after reaching max rows
        } else {
            textarea.style.height = `${textarea.scrollHeight}px`;
            textarea.style.overflowY = "hidden"; // Disable scrolling while growing
        }
    }

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const allowedTypes = ['image/jpeg', 'image/png', 'video/mp4', 'image/gif'];
        const maxSize = 50 * 1024 * 1024; // 50MB in bytes
    
        // Filter out invalid files based on type and size
        const validFiles = selectedFiles.filter(file =>
            allowedTypes.includes(file.type) && file.size <= maxSize
        );
    
        // Alert user if there are invalid files
        const invalidFiles = selectedFiles.filter(file =>
            !allowedTypes.includes(file.type) || file.size > maxSize
        );
    
        if (invalidFiles.length > 0) {
            const sizeError = invalidFiles.some(file => file.size > maxSize) ? 'Some files are too large (max 50MB).' : '';
            const typeError = invalidFiles.some(file => !allowedTypes.includes(file.type)) ? 'Some files have invalid formats (only .mp4, .jpg, .png, .gif allowed).' : '';
            
            alert(`${sizeError} ${typeError}`);
        }

        setFiles(validFiles)

        console.log(validFiles)


    
        const filePreviews = [];
        
        // Read and preview valid files
        validFiles.forEach((file) => {
            const reader = new FileReader();
    
            reader.onload = (e) => {
                filePreviews.push({ src: e.target.result, type: file.type });
    
                if (filePreviews.length === validFiles.length) {
                    setFilesPreview(filePreviews);
                }
            };
    
            reader.readAsDataURL(file);
        });
    };
    


    const createPost = async (e) => {
        e.preventDefault();
        setLoading(true)

        if(!files && !postContent){
            // return an error here
            setLoading(false);
            return;
        }

        if(isPostPaidCheck && (!paymentAmountInput || paymentAmountInput <= 0)){
            // return please enter a 
            console.log('please enter a valid amount or turn off the is paid toggle')
            return;
        }
    
        const formData = new FormData();
        formData.append('content', postContent); 
        formData.append('isPostPaid', isPostPaidCheck? (!paymentAmountInput || paymentAmountInput <= 0)? false.toString(): true.toString() : false.toString()); 
        formData.append('everyoneCanComment', everyoneCanComment.toString()); 
        formData.append('onlyPaidViewersCanComment', onlyPaidViewersCanComment.toString()); 
        formData.append('postPrice', paymentAmountInput); 
    
        // Append multiple images
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]); // Append images as 'files'
            }
        }

        // check length of files not more than 4
        if(files && files.length > 4){
            console.log('not more than 4 files')
            return
        }

    
        try {
            const response = await axios.post(`${API_ROUTE}createPost`, 
                formData, 
                {
                    headers: {
                        Authorization: accessToken, 
                        // "Content-Type": "multipart/form-data" 
                    }
                }
            );

            setLoading(false)

            // navigate('/')
            window.location.href = '/';
    
            console.log('Post created:', response.data); // Handle success response
        } catch (error) {

            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    const response = await axios.post(`${API_ROUTE}createPost`, 
                        formData, 
                        {
                            headers: {
                                Authorization: accessToken, 
                                // "Content-Type": "multipart/form-data" 
                            }
                        }
                    );
                    setLoading(false)
                    // navigate('/')
                    window.location.href = '/';
            
                    console.log('Post created:', response.data); 
                }
            }else{
                console.error('Error creating post:', error.response.data.error || error.response.data.meaasge); // Handle errors
                setLoading(false)
            }
        }
    };
    
    const handlePaidInputChange = (e)=>{
        const value = e.target.value;

        if(!isNaN(value)){
            setPaymentAmountInput(value)
        }else{
            return
        }
    }

    const handleWheel = (event) => {
        // Prevent scroll
        event.preventDefault();
    };

    useEffect(() => {
        const paidAmountELement = paidAmountRef.current;
        if (paidAmountELement) {
            paidAmountELement.addEventListener('wheel', handleWheel);

            // Cleanup function to remove the event listener
            return () => {
                paidAmountELement.removeEventListener('wheel', handleWheel);
            };
        }
    }, []);

    
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Scroll speed
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleRemoveFile = (index) => {
        setFilesPreview((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    return (
        <Container onClick={()=>setShowCommentAuthPopup(false)}>
            <PostContainer>

                <TextAreaContainer>
                    <TextArea disabled={loading} rows={6} ref={textareaRef} onChange={(e)=>handlePostContentChange(e)} value={postContent} placeholder={`What do you have for your fans?`}/>
                </TextAreaContainer>

                <ImageAndVideoIconsAndTextContainer>

                    <ImageAndVideoIconsContainer>
                        <ImageAndVideoIcon>
                            Attachment
                            <IoImages color="#00e750" size={25} />
                            <ImageAndVideoFileSlect disabled={loading} type="file" accept=".mp4, .jpg, .jpeg, .png" multiple onChange={handleFileChange}/>
                        </ImageAndVideoIcon>

                        {/* <ImageAndVideoIcon style={{padding:'9px',}}>
                            <div style={{background: 'var(--twikVibe-brand-color)', color: 'var(--twikvibe-white-color)', fontSize: '15px', borderRadius: '10px', padding:'5px', fontWeight: '600',}}>GIF</div>
                            <ImageAndVideoFileSlect type="file" accept=".gif" multiple onChange={handleFileChange}/>
                        </ImageAndVideoIcon> */}

                        
                    </ImageAndVideoIconsContainer>
                
                </ImageAndVideoIconsAndTextContainer>

                {files.length > 0 && (
                    <ImageAndVideoPreviewContainer
                        ref={scrollContainerRef}
                        onMouseDown={handleMouseDown}
                        onMouseLeave={handleMouseLeave}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                        isdragging={isDragging}
                    >
                        {filesPreview.map((file, index) => (
                            <ImageAndVideoPreviewItemContainer key={index}>
                                {file.type.startsWith('image/') ? (
                                    <ImageAndVideoPreviewImage src={file.src} alt={`Preview ${index}`} />
                                ) : file.type.startsWith('video/') ? (
                                    <video style={{width:'100%', height:'100%'}}>
                                    {/* <video style={{width:'100%', height:'100%'}} autoPlay loop controls> */}
                                    <source src={file.src} type={file.type} />
                                    Your browser does not support the video tag.
                                    </video>
                                ) : null}
                                <ImageOverlay>
                                    <div onClick={()=>handleRemoveFile(index)} style={{position: "absolute", top:'-5px', right:'-5px', cursor:'pointer', borderRadius:' 50px', background:'var(--twikVibe-dark-grey-bg)', width:'25px', height: '25px', display: 'flex', alignItems:'center', justifyContent:'center' }}>
                                        <IoClose color="var(--twikVibe-light-text-color)" size={17}/>
                                    </div>
                                </ImageOverlay>
                            </ImageAndVideoPreviewItemContainer>
                        ))}
                    </ImageAndVideoPreviewContainer>
                )}

                
                <FreeOrPaidContainer>
                    <InnerFreeOrPaidContainer>
                        <span style={{marginRight: '20px'}}>
                            Would you like to charge your viewers to see this content? {isPostPaidCheck?<strong style={{color:'var(--twikVibe-brand-color)', fontWeight:'600'}}>Yes</strong>:<strong style={{color:'var(--twikVibe-brand-color)', fontWeight:'600'}}>No</strong>}
                        </span>
                        <ToggleSwitch isChecked={isPostPaidCheck} onChange={()=>setIsPostPaidCheck(prev=>!prev)}/>
                    </InnerFreeOrPaidContainer>

                    {isPostPaidCheck&&<>
                        <PaidSmallText>How much would like to charge for this content?</PaidSmallText>
                        <FreeOrPaidInputAmountContainer>
                            <DollarSign>$</DollarSign>
                            <FreeOrPaidAmountInput value={paymentAmountInput} type="number" ref={paidAmountRef} onChange={(e)=>handlePaidInputChange(e)} placeholder="100.00"/>
                        </FreeOrPaidInputAmountContainer>
                    </>}
                    
                </FreeOrPaidContainer>


                <HideCommentContainer>
                    
                    <HideCommentInnerContainer>
                        Who can comment on this post?
                        <DropdownButton onClick={(e)=>{
                            e.stopPropagation()
                            setShowCommentAuthPopup(prev=>!prev) 
                        }}>
                            {whoCanComment}
                        </DropdownButton>

                        {showCommentAuthPopup&&
                            <DropdownContainer onClick={(e)=>e.stopPropagation()}>
                                <DropdownItem
                                    onClick={()=>{
                                        setWhoCanComment('Everyone')
                                        setEveryoneCanComment(true);
                                        setOnlyPaidViewersCanComment(false)
                                        setShowCommentAuthPopup(prev=>!prev)
                                    }}
                                >Everyone</DropdownItem>

                                <DropdownItem
                                    onClick={()=>{
                                        setWhoCanComment('No one')
                                        setEveryoneCanComment(false);
                                        setOnlyPaidViewersCanComment(false)
                                        setShowCommentAuthPopup(prev=>!prev)
                                    }}
                                >No one</DropdownItem>

                                <DropdownItem
                                    onClick={()=>{
                                        setWhoCanComment('Paid veiwers only')
                                        setEveryoneCanComment(false);
                                        setOnlyPaidViewersCanComment(true)
                                        setShowCommentAuthPopup(prev=>!prev)
                                    }}
                                >Paid veiwers only</DropdownItem>
                            </DropdownContainer>}

                    </HideCommentInnerContainer>

                    <PaidSmallText>{whoCanComment} on Twikvibe can comment on this post</PaidSmallText>

                </HideCommentContainer>    
                



                {/* check if the post is being created */}
                {loading
                    ?<PostButton style={{cursor: 'not-allowed'}}><LoadingSpinnerElement/></PostButton>

                    // check f there's data in the available fields
                    :!postContent&&!files.length
                    ?<PostButton style={{background:'var(--twikvibe-grey-color)', cursor:'not-allowed'}}>Post</PostButton>
                    :<PostButton onClick={createPost}>Post</PostButton>}
            </PostContainer>
        </Container>
    )
}

export default Createpostpage