import { Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './pages/Homepage';
import Loginpage from './pages/ath_pages/Loginpage';
import Signuppage from './pages/ath_pages/Signuppage';
import { useCallback, useEffect, useState } from 'react';
import SplashScreen from './pages/SplashScreen';
import { AuthenticatedRoutes, UnAuthenticatedRoutes } from './utils/PrivateRoutes';
import ActivateEmailpage from './pages/ath_pages/ActivateEmailpage';
import Notfoundpage from "./pages/Notfoundpage";
import ResetPassword from './pages/ath_pages/ResetPassword';
import Searchpage from "./pages/Searchpage";
import Notificationspage from './pages/NotificationspPage';
import Header from './components/Header';
import MobileNav from './components/MobileNav';
import ProfilePage from './pages/ProfilePage';
import CreatePostPage from './pages/CreatePostPage';
import PostDetailPage from './pages/PostDetailPage';
import PopupMenu from './components/PopupMenu';
import styled from 'styled-components';
import AccountSettings from './pages/settings_pages/AccountSettings';
import DisplaySettings from './pages/settings_pages/DisplaySettings';
import VerificationBadge from './pages/settings_pages/VerificationBadge';
import Settingspage from './pages/settings_pages/Settingspage';
import WalletPage from "./pages/WalletPage"

import axios from "axios";
import { API_ROUTE, ROOT_API_ROUTE } from "./utils/Api";
import  io  from "socket.io-client";
import { ResetTransactionPin } from './pages/ResetTransactionPin';
import Inboxpage from './pages/Inboxpage';


const socket = io(ROOT_API_ROUTE);
// const socket = io('http://localhost:5000');

// const BottomSpace = styled.div`
//     @media screen and (max-width: 600px) {
//         height: 100px;
//     }
// `;


const PopupBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    cursor: pointer;
`;

function App() {
    const [showSplashScreen, setShowSplashScreen] = useState(true);

    // this will be used to hide header from certain pages like login and signup and other pages its not needed
    const [showHeader, setShowHeader] = useState(false);

    const accessToken = localStorage.getItem('accessToken')

    // this will be used as the back button for the site and 
    const [showBackButton, setShowBackButton] = useState(false);

    const [showNotifications, setShowNotifications] = useState(false);

    const [popupmenuOpen, setPopupMenuOpen] = useState(false);

    const [totalUnreadNotifications, setTotalUnreadNotifications] = useState(null);

    const userId = localStorage.getItem('userId')

    const [unreadChatCount, setUnreadChatCount] = useState(0);

    // store posts for when you go to another page to avoid refresh
    const [storedHomepagePosts, setStoredHomepagePosts] = useState(null)

    // store popular users for when you go to another page to avoid refresh
    const [storedHomepagePopularUsers, setHomepageStoredPopularUsers] = useState(null)

    // stored the scroll position
    const [storedHomepageScrollPosition, setStoredHomepageScrollPosition] = useState(null)

    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)


    // use this to hide the mobile navigation in certain pages
    const [hideMobileNav, setHideMobileNav] = useState(false);

    // this hides the space at the bottom of the webapp 
    const [showPopupMenuButton, setShowPopupMenuButton] = useState(false);

    


    // screen size provider
    useEffect(()=>{
        const handleResize = ()=> setDeviceWidth(window.innerWidth)

        window.addEventListener('resize', handleResize);

        return ()=>{
            window.removeEventListener('resize', handleResize)
        }
    },[])
    

    const fetchUnreaderNotificationCount = useCallback(async()=>{
        try {
            const response = await axios.get(`${API_ROUTE}getNotifications`,{
                headers: {Authorization: accessToken}
            })


            setTotalUnreadNotifications(response.data.totalUnread)
        } catch (error) {
            
        }
    },[accessToken])

    const fetchUnreadCount = useCallback(async () => {
        try {
            const response = await axios.get(`${API_ROUTE}getChatList/${userId}`);
            
            // Sum up all unread messages count from the chat list response
            const totalUnread = response.data.reduce((total, chat) => total + (chat.unreadCount || 0), 0);

            // Update the unread count state
            setUnreadChatCount(totalUnread);

            console.log(totalUnread)
        } catch (error) {
            console.error('Error fetching unread count', error);
        }
    }, [userId]);
   

    // Listen for real-time updates via socket
    useEffect(() => {
        socket.emit('join', userId);

        // Fetch the unread count on component mount
        fetchUnreadCount();
        fetchUnreaderNotificationCount();

        // Listen for the 'chatListUpdate' event to fetch the unread count in real-time
        socket.on('chatListUpdate', fetchUnreadCount);
        socket.on('notificationListUpdate', fetchUnreaderNotificationCount);


        socket.on('newNotification', (newNotification) => {
            fetchUnreaderNotificationCount(); // Refresh chat list to update unread counts
        });

        socket.on('newMessage', (newMessage) => {
            fetchUnreadCount(); // Refresh chat list to update unread counts
            
        });

        return () => {
            // Cleanup the socket listener on component unmount
            socket.off('chatListUpdate');
            socket.off('notificationListUpdate');

            socket.off('newNotification');

            socket.off('newMessage');
        };
    }, [userId, fetchUnreadCount, fetchUnreaderNotificationCount]);

    
    

    

    return (
        <div className='app'>
            {/* show splash screen for some seconds */}
            {showSplashScreen
            ?<SplashScreen setShowSplashScreen={setShowSplashScreen}/> 
            :<>
                {showHeader&&<Header setHideMobileNav={setHideMobileNav} showPopupMenuButton={showPopupMenuButton} setShowNotifications={setShowNotifications} totalUnreadNotifications={totalUnreadNotifications} unreadChatCount={unreadChatCount} popupmenuOpen={popupmenuOpen} setPopupMenuOpen={setPopupMenuOpen} showBackButton={showBackButton}/>}
                <Routes>
                {/* authenticated routes */}
                <Route element={<AuthenticatedRoutes/>}>
                    <Route path='/' element={<Homepage storedHomepageScrollPosition={storedHomepageScrollPosition} setStoredHomepageScrollPosition={setStoredHomepageScrollPosition} setHomepageStoredPopularUsers={setHomepageStoredPopularUsers} storedHomepagePopularUsers={storedHomepagePopularUsers} storedHomepagePosts={storedHomepagePosts} setStoredHomepagePosts={setStoredHomepagePosts} setShowHeader={setShowHeader}  setShowBackButton={setShowBackButton} setShowPopupMenuButton={setShowPopupMenuButton}/>}/>
                    <Route path='/:userName/post/:postId' element={<PostDetailPage setShowHeader={setShowHeader} setShowBackButton={setShowBackButton} setShowPopupMenuButton={setShowPopupMenuButton}/>}/>
                    <Route path='/:userName' element={<ProfilePage setShowHeader={setShowHeader} setShowBackButton={setShowBackButton} setShowPopupMenuButton={setShowPopupMenuButton}/>}/>
                    <Route path='/create' element={<CreatePostPage setShowHeader={setShowHeader} setShowBackButton={setShowBackButton} setShowPopupMenuButton={setShowPopupMenuButton}/>}/>
                    <Route path='/inbox' element={<Inboxpage deviceWidth={deviceWidth} setHideMobileNav={setHideMobileNav} setShowHeader={setShowHeader} setShowBackButton={setShowBackButton} setShowPopupMenuButton={setShowPopupMenuButton}/>}/>
                    <Route path='/search' element={<Searchpage setShowHeader={setShowHeader} setShowBackButton={setShowBackButton} setShowPopupMenuButton={setShowPopupMenuButton}/>}/>
                    {/* <Route path='/search/:searchType' element={<Searchpage setShowHeader={setShowHeader} setShowBackButton={setShowBackButton} />}/> */}
                    {/* <Route path='/notifications' element={<Notificationspage setShowHeader={setShowHeader} setShowBackButton={setShowBackButton} setShowPopupMenuButton={setShowPopupMenuButton}/>}/> */}
                    <Route path='/settings' element={<Settingspage deviceWidth={deviceWidth} setShowHeader={setShowHeader} setShowBackButton={setShowBackButton} setShowPopupMenuButton={setShowPopupMenuButton}/>}/>
                    <Route path='/settings/my-account' element={<AccountSettings deviceWidth={deviceWidth} setShowHeader={setShowHeader} setShowBackButton={setShowBackButton}/>}/>
                    <Route path='/settings/display' element={<DisplaySettings deviceWidth={deviceWidth} setShowHeader={setShowHeader} setShowBackButton={setShowBackButton}/>}/>
                    <Route path='/settings/get-verification-badge' element={<VerificationBadge deviceWidth={deviceWidth} setShowHeader={setShowHeader} setShowBackButton={setShowBackButton}/>}/>
                    <Route path='/wallet' element={<WalletPage setShowHeader={setShowHeader} setShowBackButton={setShowBackButton} setShowPopupMenuButton={setShowPopupMenuButton}/>}/>
                    <Route path='/reset-transaction-pin' element={<ResetTransactionPin setShowHeader={setShowHeader} setShowBackButton={setShowBackButton} setShowPopupMenuButton={setShowPopupMenuButton}/>}/>
                    
                    {/* <Route path='/inbox' element={<Inboxpage setShowHeader={setShowHeader}/>}/> */}
                </Route>

                {/* unauthenticated routes */}
                <Route element={<UnAuthenticatedRoutes/>}>
                    <Route path='/login' element={<Loginpage setShowHeader={setShowHeader}/>}/>
                    <Route path='/signup' element={<Signuppage setShowHeader={setShowHeader}/>}/>
                    <Route path='/reset-password' element={<ResetPassword setShowHeader={setShowHeader}/>}/>
                    <Route path='/activate-email' element={<ActivateEmailpage setShowHeader={setShowHeader} />}/>
                </Route>

                <Route path='*' element={<Notfoundpage/>}/>
                
            </Routes>
            {showHeader&&!hideMobileNav&&<MobileNav setShowNotifications={setShowNotifications} totalUnreadNotifications={totalUnreadNotifications} unreadChatCount={unreadChatCount} popupmenuOpen={popupmenuOpen} setPopupMenuOpen={setPopupMenuOpen}  />}


            {showNotifications&&<Notificationspage totalUnreadNotifications={totalUnreadNotifications} setTotalUnreadNotifications={setTotalUnreadNotifications} setShowNotifications={setShowNotifications}/>}
            {popupmenuOpen&&<PopupMenu setPopupMenuOpen={setPopupMenuOpen}/>}

            {popupmenuOpen&&<PopupBackground title="close modal" onClick={()=>{setPopupMenuOpen(false);}}/>}
            {showNotifications&&<PopupBackground title="close modal" onClick={()=>{setShowNotifications(false)}}/>}

            {/* <BottomSpace/> */}
            </>}


        </div>
    );
}

export default App;
