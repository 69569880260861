import styled from "styled-components";
import { IoArrowBackOutline } from "react-icons/io5"
import { useNavigate } from "react-router-dom";
import DarkMode from "../../utils/Darkmode";
import { useEffect } from "react";

const Container = styled.div`
    flex: 1;
`;

const InnerContainer = styled.div`
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: auto;
    
    
    
    @media screen and (max-width: 600px){
        width: auto;
        padding: 10px;
    }
`;
const HeaderContainer = styled.h1`
    display: flex;
    align-items: center;
    color: var(--twikVibe-light-text-color);
    font-size: 16px;
    gap: 20px;
    position: sticky;
    top: 0px; 
    // justify-content: space-between;
    z-index: 5;
    background: var(--twikVibe-dark-theme-bg-color);
    padding: 10px 0px;

    @media screen and (max-width: 600px){
        top: 50px; 
    }
`;

const SettingsItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const SettingsItem = styled.div`
    border-radius: 10px;
    padding: 25px 10px;
    // border: solid 1px var(--twikvibe-grey-color);
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;

    &:hover{
        background: var(--twikVibe--darker-transparent-black-color);
    }
`;
const SettingsIconContainer = styled.div`
    
`;
const SettingsIconAndTextContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const SettingsTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
const SettingsTitle = styled.h1`
    color: var(--twikVibe-light-text-color);
    font-size: 15px;
`;

const SettingsDescription = styled.div`
    color: var(--twikvibe-grey-color);
    font-size: 14px;
    font-weight: 600;
`;

const DisplaySettings = ({setShowHeader, deviceWidth}) => {

    const navigate = useNavigate();
    
        // remove side bar 
        useEffect(()=>{
            setShowHeader(true)
        },[setShowHeader])
    
    

    const goBack=()=>{
        navigate(-1);
    }

    return (
        <Container>
            <InnerContainer>
                <HeaderContainer>
                    {deviceWidth>600&&<IoArrowBackOutline style={{cursor:'pointer'}} color="var(--twikVibe-light-text-color)" onClick={goBack} />}
                    Display and Language
                </HeaderContainer>

                <SettingsItemsContainer>
                    <SettingsItem>
                        <SettingsIconAndTextContainer>
                            <SettingsIconContainer>

                            </SettingsIconContainer>

                            <SettingsTextContainer>
                                <SettingsTitle>Language</SettingsTitle>
                                <SettingsDescription>English</SettingsDescription>
                                <SettingsDescription style={{fontWeight:'500', fontSize:'14px'}}>Twikvibe will be available in more languages soon, stay tuned.</SettingsDescription>
                            </SettingsTextContainer>
                        </SettingsIconAndTextContainer>

                    </SettingsItem>

                    <DarkMode/>


                    
                </SettingsItemsContainer>
            </InnerContainer>
        </Container>
    )
}

export default DisplaySettings