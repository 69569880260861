import styled, { keyframes } from "styled-components";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineDollar } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useState } from "react";
import { API_ROUTE } from "../utils/Api";
import axios from "axios";
import { AiFillHeart } from "react-icons/ai"; // filed icon 
import { refreshAccessToken } from "../utils/RefreshAccessToken";
import TipUser from "./TipUser";
import { FiFlag, FiTrash2 } from "react-icons/fi";



function detectAndConvertLinks(text) {
    // Regex to match URLs
    const urlRegex = /((https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))/g;

    // Regex to match @tags
    const tagRegex = /(@[a-zA-Z0-9_]+)/g;

    // Merge both regexes into a combined regex
    const combinedRegex = new RegExp(`${urlRegex.source}|${tagRegex.source}`, 'g');

    // Split text into paragraphs by newlines or double newlines
    const paragraphs = text.split(/\n+/);

    return paragraphs.map((paragraph, pIndex) => {
        const parts = [];
        let lastIndex = 0;

        // Replace matches (either URLs or @tags) with clickable links
        paragraph.replace(combinedRegex, (match, ...args) => {
            const index = args[args.length - 2];
            if (index > lastIndex) {
                // Push the text between the last match and the current match
                parts.push(paragraph.slice(lastIndex, index));
            }

            if (match.startsWith('@')) {
                // Handle @tags: Create custom link for your website
                const profileUrl = `http://localhost:3000/@${match.slice(1)}`; // Your custom URL
                parts.push(
                    <Link style={{color:'var(--twikVibe-brand-color)'}} className="transparent_click" key={index} to={profileUrl}>
                        {match}
                    </Link>
                );
            } else {
                // Handle URLs: Make them clickable links
                let href = match;
                if (!href.startsWith('http://') && !href.startsWith('https://')) {
                    href = 'http://' + href;
                }

                parts.push(
                    <a key={index} href={href} target="_blank" rel="noopener noreferrer">
                        {match}
                    </a>
                );
            }

            lastIndex = index + match.length;
            return match; // Keep the match as it is
        });

        // Add the remaining text after the last match
        if (lastIndex < paragraph.length) {
            parts.push(paragraph.slice(lastIndex));
        }

        // Wrap the processed text in a paragraph <p> element
        return <p key={pIndex}>{parts}</p>;
    });
}


const formatTime = (date) => {
    const parsedDate = new Date(date);
    
    // Check if the parsedDate is valid
    if (isNaN(parsedDate)) {
      return "Invalid date";
    }
  
    const now = new Date();
    const secondsAgo = Math.floor((now - parsedDate) / 1000);
    const minutesAgo = Math.floor(secondsAgo / 60);
    const hoursAgo = Math.floor(minutesAgo / 60);
    const daysAgo = Math.floor(hoursAgo / 24);
  
    if (secondsAgo < 60) return `${secondsAgo}s ago`;
    if (minutesAgo < 60) return `${minutesAgo}m ago`;
    if (hoursAgo < 24) return `${hoursAgo}h ago`;
    if (daysAgo <= 7) return `${daysAgo}d ago`;
  
    // For more than 7 days, return formatted date MM/DD/YY
    return new Intl.DateTimeFormat("en-US").format(parsedDate);
};

const formatLikes = (num) => {
    if (num < 1000) return num.toString(); // 100 -> 100
    if (num >= 1000 && num < 10000) return (num / 1000).toFixed(1) + "k"; // 1.1k
    if (num >= 10000 && num < 100000) return (num / 1000).toFixed(2) + "k"; // 10.1k, 100.23k
    if (num >= 100000 && num < 1000000) return (num / 1000).toFixed(1) + "k"; // 100.2k
    if (num >= 1000000 && num < 10000000) return (num / 1000000).toFixed(2) + "m"; // 1.12m
    if (num >= 10000000 && num < 100000000) return (num / 1000000).toFixed(1) + "m"; // 10.1m
    if (num >= 100000000 && num < 1000000000) return (num / 1000000).toFixed(2) + "m"; // 100.4m
    if (num >= 1000000000 && num < 10000000000) return (num / 1000000000).toFixed(2) + "b"; // 1.12b
    if (num >= 10000000000 && num < 100000000000) return (num / 1000000000).toFixed(1) + "b"; // 12.5b
    if (num >= 100000000000) return (num / 1000000000).toFixed(2) + "b"; // 100.2b
};

const Container =styled.div`
    display:flex;
    flex-direction:column;
    gap:1.5em;
    color:  var(--twikVibe-light-text-color);

`;

const CommentParentContainer = styled.div`
    display:flex;
    flex-direction:column;
    gap:10px;
    background-color: var(--twikVibe-dark-grey-bg);
    border-radius: 10px;
    padding: 10px;
    position: relative;
`;

const CommentProfileBioContainer = styled.div`
    display:flex;
    gap:0.3em;
    flex-direction:column;
`;

const CommentProfilePicsCon=styled.div`
    background:grey;
    border-radius:100px;
    width:40px;
    height:40px;
`;

const PostProfilePics= styled.img`
    border-radius:100%;
    width:100%;
    height:100%;
    object-fit: cover;
`;

const CommentProfileContainer = styled.div`
    display:flex;
    gap:0.5em;
    align-items:center;
    flex:1;
`;

const CommentReactionContainer =styled.div`
    display:flex;
    gap:20px;
    align-items: center;
`; 

const CommentContentContainer = styled.div`
    color:var(--twikVibe-light-theme-color);
    font-size:14px;
    padding:5px;
`;

const CommentUserName = styled.h5`
    color:  var(--twikVibe-light-text-color);
    font-size:15px;
`;

const CommentTimestamp = styled.div`
    font-size:12px;
    color:  var(--twikVibe-light-text-color);
`;

const CommentParentProfileContainer =styled.div`
    display:flex;
    gap:1em;
    flex:1;
    justify-content:space-between;
    align-items:center;
`;


const ReactionItem = styled.div`
    display:flex;
    gap: 10px;
    cursor:pointer;
    align-items: center;
    color: var(--twikVibe-light-text-color);
    padding:5px;
    font-size: 14px;
    justify-content: center;
`;

const MoreContainer = styled.div`
    cursor: pointer;
`;

const PostPopupContainer = styled.div`
    position: absolute;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0,0,0,0.1);
    // border: solid .2px var(--twikvibe-grey-color);
    background: var(--twikVibe-dark-theme-color);
    width: 180px;
    top: 50px;
    right: 10px;
    z-index: 9;
`;

const PostPopupItem = styled.div`
    padding: 15px;
    border-radius: 10px;
    background: var(--twikVibe-dark-theme-color);
    color: var(--twikVibe-light-text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;
    gap: 10px;

    &:hover{
        background: var(--twikVibe--darker-transparent-black-color);
    }
`;

const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:2px;
    height:2px;
    border-style:solid;
    border-left-color:var(--twikVibe-light-text-color);
    border-top-color:var(--twikVibe-light-text-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin-left: 50px;
`;
const Comment = ({commentsData, setComments, postId}) => {


    const userName = localStorage.getItem('userName')
    const userId = localStorage.getItem('userId')
    const accessToken = localStorage.getItem('accessToken')

    const [deleting, setDeleting] = useState(false);

    const [showCommentPopupIndex, setShowCommentPopupIndex] = useState(null)

    const [showCommentPopup, setShowCommentPopup] = useState(false);

    const [showTipUser, setShowTipUser] = useState({
        userName: null,
        profilePicture: null
    })


    const toggleCommentReaction = async({commentId, index})=>{

        // toggle the reaction
        setComments((prevComments) =>
            prevComments.map((comment, i) =>
              i === index
                ? {
                    ...comment,
                    reactionsId: comment.reactionsId.includes(userId)
                      ? comment.reactionsId.filter((id) => id !== userId) // Unlike
                      : [...comment.reactionsId, userId], // Like
                  }
                : comment
            )
        );

        try {
            await axios.post(`${API_ROUTE}toggleCommentReaction?commentId=${commentId}&&postId=${postId}`,
            {},
            {
                headers: {Authorization: accessToken}
            })

            
        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    await axios.post(`${API_ROUTE}toggleCommentReaction?commentId=${commentId}&&postId=${postId}`,
                        {},
                        {
                            headers: {Authorization: accessToken}
                        })

                }
            }else{

                // toggle the reaction
                setComments((prevComments) =>
                    prevComments.map((comment, i) =>
                    i === index
                        ? {
                            ...comment,
                            reactionsId: comment.reactionsId.includes(userId)
                            ? comment.reactionsId.filter((id) => id !== userId) // Unlike
                            : [...comment.reactionsId, userId], // Like
                        }
                        : comment
                    )
                );
                console.log(error)
            }
        }
    }

    const deleteComment = async(commentId, index)=>{
        setDeleting(true);
        try {
            await axios.delete(`${API_ROUTE}deleteComment?commentId=${commentId}`,{
                headers: {Authorization: accessToken}
            })

            setDeleting(false);

            // delete the posts from the array without refreshing
            setComments((prevComments) => prevComments.filter((_, i) => i !== index));

            // hide the popup 
            setShowCommentPopup(prev=>!prev);


        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();
                

                if(accessToken){
                    await axios.delete(`${API_ROUTE}deleteComment?commentId=${commentId}`,{
                        headers: {Authorization: accessToken}
                    })

                    setDeleting(false);
                    // delete the posts from the array without refreshing
                    setComments((prevComments) => prevComments.filter((_, i) => i !== index));

                    // hide the popup 
                    setShowCommentPopup(prev=>!prev);
                }

            }else{

                console.log(error)
                setDeleting(false);
            }
        }
    }

    return (
        <Container onClick={()=>setShowCommentPopup(false)}>
            {
                commentsData.map((comment, index)=>(
                    <CommentParentContainer key={index}>

                        {/* comment profile container  */}
                        <CommentParentProfileContainer>
                            <CommentProfileContainer>

                                <Link className="transparent_click" to={`/@${comment.userName}`}>
                                    <CommentProfilePicsCon>
                                        <PostProfilePics src={comment.profilePicture}/>
                                    </CommentProfilePicsCon>
                                </Link>
                                <CommentProfileBioContainer>
                                    <Link className="transparent_click" to={`/@${comment.userName}`}>
                                        <CommentUserName>{comment.userName}</CommentUserName>
                                    </Link>
                                    <CommentTimestamp>{formatTime(comment.timeStamp)}</CommentTimestamp>
                                </CommentProfileBioContainer>
                            </CommentProfileContainer> 

                            {/* the three dots menu on the post item container ============================================================================================================ */}
                            <MoreContainer onClick={(e)=>{
                                e.stopPropagation();
                                setShowCommentPopupIndex(index)
                                setShowCommentPopup(prev=>!prev);
                            }}>
                                <BsThreeDotsVertical />
                            </MoreContainer>
                            
                            {/* the posts popup for deleting */}
                            {showCommentPopup&&showCommentPopupIndex===index&&
                            <PostPopupContainer onClick={(e)=>e.stopPropagation()}>
                                {/* show report button if the post is not mine */}
                                {userName!==comment.userName&&<PostPopupItem><FiFlag style={{color: 'var(--twikvibe-grey-color)'}}/> Report</PostPopupItem>}
                                {/* show delete button if the post is mine */}
                                {userName===comment.userName&&<PostPopupItem onClick={()=>deleteComment(comment._id, index)}><FiTrash2 style={{color: 'var(--twikvibe-grey-color)'}}/> Delete {deleting&&<LoadingSpinnerElement/>}</PostPopupItem>}

                            </PostPopupContainer>}
                        </CommentParentProfileContainer>
                        

                        {/* comment content container  */}
                        <CommentContentContainer>{detectAndConvertLinks(comment.commentContent)}</CommentContentContainer> 

                        {/* comment reaction container  */}
                        <CommentReactionContainer>
                            {comment.reactionsId.includes(userId)
                            ?<ReactionItem><AiFillHeart size={20} style={{cursor:'pointer' ,fill:'var(--twikVibe-brand-color)'}} onClick={()=>toggleCommentReaction({commentId: comment._id, index})} /> {formatLikes(comment.reactionsId.length)}</ReactionItem>
                            :<ReactionItem><AiOutlineHeart size={20}  style={{cursor: 'pointer', }} onClick={()=>toggleCommentReaction({commentId: comment._id, index})} /> {formatLikes(comment.reactionsId.length)}</ReactionItem>}
                            
                            {comment.userName!==userName&&<ReactionItem>
                                <AiOutlineDollar onClick={()=>{
                                    setShowTipUser((prev)=>({
                                        ...prev,
                                        userName: comment.userName,
                                        profilePicture: comment.profilePicture
                                    }))
                                }} size={20} style={{cursor:"pointer"}}/>
                            </ReactionItem>}

                            
                        </CommentReactionContainer> 
                        
                    </CommentParentContainer>

                ))
            }

            {showTipUser.userName&&<TipUser userName={showTipUser.userName} profilePicture={showTipUser.profilePicture} setShowTipUser={setShowTipUser}/>}
        </Container>
    )
}

export default Comment