import styled, { keyframes } from "styled-components";
import { API_ROUTE } from "../../utils/Api";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { refreshAccessToken } from "../../utils/RefreshAccessToken";
import { RiPencilFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5"


const Container = styled.div`
    flex: 1;
`;


const HeaderContainer = styled.h1`
    display: flex;
    align-items: center;
    color: var(--twikVibe-light-text-color);
    font-size: 16px;
    gap: 20px;
    position: sticky;
    top: 0px; 
    // justify-content: space-between;
    z-index: 5;
    background: var(--twikVibe-dark-theme-bg-color);
    padding: 10px 0px;

    @media screen and (max-width: 600px){
        top: 50px; 
    }
`;


const AccountSettingsContainer = styled.form`
    // flex: 1;
    width: 450px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: auto;
    // padding: 10px;

    @media screen and (max-width: 700px){
        width: auto;
        padding: 0px 10px;
    }
`;

const ProfileImageContainer = styled.div`
    width: 100px;
    height:  100px;
    border-radius: 100%;
    background: var(--twikVibe-dark-grey-bg);
    position: relative;
    cursor: pointer;
`;
const ProfileImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
    cursor: pointer;
`;

const EditImageIcon = styled.div`
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    right: 0px;
    background: var(--twikVibe-light-grey-bg);
    cursor: pointer;

`;

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const InputContainer = styled.label`
    display: flex;
    padding: 8px;
    border-radius: 10px;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
    background-color: var(--twikVibe-dark-grey-bg);
    border: 2px solid var(--twikVibe-dark-grey-bg);

    &:focus-within{
        border: 2px solid var(--twikVibe-brand-color);
    }
`;

const Input = styled.input`
    flex: 1;
    width: 100%;
    font-size: 16px;
    background: none;
    border: none;
    padding: 5px;
    color: var(--twikVibe-light-text-color);
    font-weight: ;
    
`;

const TextAreaContainer = styled.label`
    display: flex;
    padding: 8px;
    border-radius: 10px;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
    background-color: var(--twikVibe-dark-grey-bg);
    border: 2px solid var(--twikVibe-dark-grey-bg);

    &:focus-within{
        border: 2px solid var(--twikVibe-brand-color);
    }
`;
const TextArea = styled.textarea`
    width: 100%;
    font-size: 16px;
    color: var(--twikVibe-light-text-color);
    background: none;
    border: none;
    resize: none !important;
    draggable: false !important; 
    max-height: 150px;
`;

const SaveButton = styled.button`
    padding: 10px 15px;
    background: var(--twikVibe-brand-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: var(--twikvibe-white-color);
    border: none;

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;

const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const ImageLoaderBackground = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--twikvibe-transparent-black-color);
    border-radius: 100%;
`;

const InputLoadingSpinnerElement=styled.div`
    padding:4px;
    background-color:transparent;
    border-radius:100px;
    width:4px;
    height:4px;
    border-style:solid;
    border-left-color:var(--twikVibe-light-theme-bg-color);
    border-top-color:var(--twikVibe-light-theme-bg-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin: auto;
`;

const ProfileImageLoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:4px;
    height:4px;
    border-style:solid;
    border-left-color:var(--twikvibe-white-color);
    border-top-color:var(--twikvibe-white-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin: auto;
`;

const DotCom = styled.div`
    font-size: 14px;
    background: none;
    border: none;
    padding: 5px;
    color: var(--twikvibe-grey-color);
    font-weight: 600;
    user-select: none;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    cursor: text;
`;
const ForgotPassword = styled.div`
    background: var( --twikVibe-dark-theme-bg-color);
    padding: 5px 10px;
    border-radius: 15px;
    color: var(--twikvibe-grey-color);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

`;


function isUsernameValid(username) {
    const regex = /^[a-zA-Z0-9_]{4,20}$/;
    return regex.test(username);
}

const AccountSettings = ({setShowHeader, deviceWidth}) => {


    // const [user, setUser] = useState({});
    const [profilePicture, setProfilePicture] = useState('')
    const [email, setEmail] = useState('')
    const [about, setAbout] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [userName, setUserName] = useState('')
    const [gender, setGender] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [facebookLink, setFacebookLink] = useState('')
    const [xTwitterLink, setXTwitterLink] = useState('')
    const [instagramLink, setInstagramLink] = useState('')
    const [websiteLink, setWebsiteLink] = useState('')
    const [youtubeLink, setYoutubeLink] = useState('')


    const [passwordConfirmed, setPasswordConfirmed] = useState(false);
    const [confirmingPassword, setConfirmingPassword] = useState(false);
    const [password, setPassword] = useState('')


    const [usernameExists, setUsernameExists] = useState(false)
    const [error, setError] = useState('')
    const [userNameError, setUserNameError] = useState('')
    
    const [loading, setLoading] = useState(true)
    const [loadingProfileUpdate, setLoadingProfileUpdate] = useState(false)
    const [loadingProfileImageUpdate, setLoadingProfileImageUpdate] = useState(false)
    const [userNameLoading, setUserNameLoading] = useState(false)


    const textareaRef = useRef(null);

    

    const accessToken = localStorage.getItem('accessToken')
    const userId = localStorage.getItem('userId')
    const localStorageUserName = localStorage.getItem('userName')

    const navigate = useNavigate();
    
    // remove side bar 
    useEffect(()=>{
        setShowHeader(true)
    },[setShowHeader])

    const goBack=()=>{
        navigate(-1);
    }

    const getUserProfile = useCallback(async()=>{

        try {
            const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`,{
                headers: {Authorization: accessToken}
            })

            console.log(response.data)


            // set the user profile data
            setProfilePicture(response.data.returnWithoutPassword.profilePicture);
            setEmail(response.data.returnWithoutPassword.email);
            setUserName(response.data.returnWithoutPassword.userName);
            setFirstName(response.data.returnWithoutPassword.firstName);
            setLastName(response.data.returnWithoutPassword.lastName);
            setPhoneNumber(response.data.returnWithoutPassword.phoneNumber);
            setGender(response.data.returnWithoutPassword.gender);
            setAbout(response.data.returnWithoutPassword.about);
            setLoading(false);
        } catch (error) {
            // check if accesstoken has expired and refresh
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    // recall the get user function
                    getUserProfile();
                }
            }else if(error.status === 404){
                // navigate to not found if no user exists with this username
                console.log(error)
            }else{
                console.log(error)
            }
        }
    },[accessToken, userId])

    useEffect(()=>{
        getUserProfile();
    },[getUserProfile])


    const confirmPassword =async(e)=>{
        e.preventDefault();
        setConfirmingPassword(true);

        if(!password){
            setError('Please enter your password')
            setConfirmingPassword(false);
            return;
        }
        try {
            await axios.post(`${API_ROUTE}confirmPassword`,{
                password
            },{
                headers: {Authorization: accessToken}
            });

            setPasswordConfirmed(true)
            getUserProfile()
            setConfirmingPassword(false);
        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    confirmPassword()
                }
            }else{
                setError(error.response.data.error)
                setLoading(false);
                setConfirmingPassword(false);
                console.log(error);
            }
        }
    }

    const handleAboutTextAreaChange=(e)=>{
        const value = e.target.value;

        setAbout(value);

        const maxRows = 5;

        const textarea = textareaRef.current;

        textarea.style.height = "auto";

        // Calculate the number of rows based on the scrollHeight
        const rows = Math.min(textarea.scrollHeight / textarea.offsetHeight, maxRows);

        if (rows >= maxRows) {
            textarea.style.height = `${textarea.scrollHeight}px`;
            textarea.style.overflowY = "scroll"; // Enable scrolling after reaching max rows
        } else {
            textarea.style.height = `${textarea.scrollHeight}px`;
            textarea.style.overflowY = "hidden"; // Disable scrolling while growing
        }
    }

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const allowedTypes = ['image/jpeg', 'image/png'];
        const maxSize = 10 * 1024 * 1024; // 50MB in bytes
    
        // Filter out invalid files based on type and size
        const validFiles = selectedFiles.filter(file =>
            allowedTypes.includes(file.type) && file.size <= maxSize
        );
    
        // Alert user if there are invalid files
        const invalidFiles = selectedFiles.filter(file =>
            !allowedTypes.includes(file.type) || file.size > maxSize
        );
    
        if (invalidFiles.length > 0) {
            const sizeError = invalidFiles.some(file => file.size > maxSize) ? 'Some files are too large (max 10MB).' : '';
            const typeError = invalidFiles.some(file => !allowedTypes.includes(file.type)) ? 'Some files have invalid formats (only .mp4, .jpg, .png, .gif allowed).' : '';
            
            alert(`${sizeError} ${typeError}`);
        }


        // update profile after setting file
        updateProfileImage(validFiles)
    };

    const updateProfileImage =async(file)=>{
        setLoadingProfileImageUpdate(true);

        if(!file){
            // return an error here
            return;
        }

        try {
            console.log('sending')
            const formData = new FormData();

            // Append multiple image
            
            formData.append('files', file); // Append images as 'file'
           

            // check length of file not more than 4
            if (file && file.length > 0) {
                for (let i = 0; i < file.length; i++) {
                    formData.append('files', file[i]); // Append images as 'files'
                }
            }
    
            // check length of file not more than 4
            if(file && file.length > 1){
                console.log('not more than 1 file')
                return
            }

            console.log(file)

            const response = await axios.post(`${API_ROUTE}updateProfileImage`,
                formData,
                {
                    headers: {Authorization: accessToken}
                }
            )

            // call theget user function again to get the updated profile image
            await getUserProfile();
            setLoadingProfileImageUpdate(false);
            console.log(response)
        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    updateProfileImage()
                }
            }else{
                console.log(error)
            }
        }
    }

    const checkUsernameExists = async(value)=>{
        // e.preventDefault()
        setUserNameLoading(true);
        setError('');
        setUserNameError('');

        if(!value) {
            setUserNameError('Username is required');
            setLoading(false)
            return;
        }

        if(!isUsernameValid(value)) {
            setUserNameError('Enter a valid username');
            setLoading(false)
            return;
        }
        
        try {
            const response = await axios.post(`${API_ROUTE}checkUsernameExists`,{
                userName: value
            })

            if(response.status===200){
                console.log('username okay')
            }

            setUsernameExists(false)

            setUserNameLoading(false)

          
        } catch (error) {
            if(error.response.status===400){
                if(localStorageUserName!==value){
                    setUserNameError(error.response.data.error)
                    setUsernameExists(true)
                }
                
            }else{
                console.log(error.response.data.error)
            }
            setUserNameLoading(false)
        }
    }


    const onUsernameChange = (data)=>{
        const value = data.target.value;

        setUserName(value);


        if(!isUsernameValid(value)){
            setUserNameError('Username must be 4 to 20 characters long and can only contain letters, numbers, and underscores.');
        }else{
            // check if the username exists in the db
            checkUsernameExists(value);
            setUserNameError('');
        }
    }

    const changeUsername = async()=>{


        try {
            await axios.post(`${API_ROUTE}changeUsername`,{
                userName,
            },
            {
                headers: {Authorization: accessToken}
            })


        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    changeUsername()
                }
            }else{
                setLoadingProfileUpdate(false)
            }
        }
    }

    const updateProfile = async()=>{
        // e.preventDefault()

        if(usernameExists){
            setError('This username is taken')
            return
        }

        if(userNameError){
            return
        }


        setLoadingProfileUpdate(true)
        try {
            await axios.post(`${API_ROUTE}updateProfile`,{
                fullName: `${firstName} ${lastName}`,
                firstName,
                lastName,
                gender,
                about,
                phoneNumber,
            },
            {
                headers: {Authorization: accessToken}
            })

            // only update username if its different
            if(localStorageUserName!==userName){
                await changeUsername();

                // set the new username to local storage after saving
                localStorage.setItem('userName', userName)
            }
            setLoadingProfileUpdate(false);
        } catch (error) {
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    updateProfile()
                }
            }else{
                console.log(error);
                setLoadingProfileUpdate(false);
            }

        }
    }


    


    return (
        <Container>
                
                {/* Sticky container for settings */}

                {/* Account settings */}
                {!passwordConfirmed
                ?<AccountSettingsContainer onSubmit={confirmPassword}>
                    <HeaderContainer>
                        {deviceWidth>600&&<IoArrowBackOutline style={{cursor:'pointer'}} color="var(--twikVibe-light-text-color)" onClick={goBack} />}
                        Edit Profile Information

                    </HeaderContainer>

                    <div style={{fontSize:'14px', color:'var(--twikVibe-light-text-color)'}}>Confirm your password to see your information</div>
                    <InputContainer>
                        <Input disabled={confirmingPassword} onChange={(e)=>setPassword(e.target.value)} value={password} placeholder="Password" />
                        <Link className="transparent_click" to='/reset-password'><ForgotPassword>Forgot?</ForgotPassword></Link>
                    </InputContainer>

                    {error&&<div style={{color:'red', fontSize: '13px', fontWeight:'600'}}>{error}</div>}

                    <div style={{display:'flex', justifyContent:'space-between'}}>
                        <div/>
                        {<SaveButton type="submit" style={{}} disabled={confirmingPassword} onClick={confirmPassword}>{confirmingPassword?<ProfileImageLoadingSpinnerElement/>:'Confirm'}</SaveButton>}
                    </div>

                </AccountSettingsContainer>

                // the user password has been confirmed
                :<>
                    {loading
                    ?
                    <AccountSettingsContainer>loading</AccountSettingsContainer>
                    :<AccountSettingsContainer>
                        <HeaderContainer style={{justifyContent:'space-between'}}>
                            <div style={{display:'flex', alignItems:'center', gap:'20px'}}>
                                <IoArrowBackOutline style={{cursor:'pointer'}} color="var(--twikVibe-light-text-color)" onClick={goBack} />
                                Edit Profile Information
                            </div>

                            {!usernameExists&&!userNameError&&<SaveButton disabled={loadingProfileUpdate} onClick={updateProfile}>{loadingProfileUpdate?<ProfileImageLoadingSpinnerElement/>:'Save'}</SaveButton>}
                        </HeaderContainer>


                        <FormContainer>

                            <ProfileImageContainer>
                                <label>
                                    <ProfileImage src={profilePicture} alt="profile"/>
                                    {!loadingProfileImageUpdate&&<EditImageIcon><RiPencilFill color="var(--twikVibe-dark-text-color)" size={15}/></EditImageIcon>}
                                    <input style={{display:'none'}} type="file" accept=".jpg, .jpeg, .png" onChange={handleFileChange}/>
                                </label>
                        
                                {loadingProfileImageUpdate&&
                                    <ImageLoaderBackground>
                                        <ProfileImageLoadingSpinnerElement/>
                                    </ImageLoaderBackground>}
                            </ProfileImageContainer>

                            <InputContainer>
                                <DotCom>twikvibe.com/@</DotCom>
                                <Input disabled={loadingProfileUpdate} onChange={(e)=>onUsernameChange(e)} value={userName} placeholder="Username" />
                                {userNameLoading&&
                                    <div>
                                        <InputLoadingSpinnerElement/>
                                    </div>}
                            </InputContainer>

                            {userNameError&&<div style={{color:'red', fontSize: '13px', fontWeight:'600'}}>{userNameError}</div>}

                            <InputContainer>
                                <Input disabled value={email} placeholder="Email" />
                            </InputContainer>

                            <TextAreaContainer>
                                <TextArea rows={3} ref={textareaRef} disabled={loadingProfileUpdate} value={about} placeholder="about you" onChange={(e)=>handleAboutTextAreaChange(e)} />
                            </TextAreaContainer>

                            <InputContainer>
                                <Input disabled={loadingProfileUpdate} onChange={(e)=>setFirstName(e.target.value)} value={firstName} placeholder="Firstname" />
                            </InputContainer>

                            <InputContainer>
                                <Input disabled={loadingProfileUpdate} onChange={(e)=>setLastName(e.target.value)}  value={lastName} placeholder="Lastname" />
                            </InputContainer>

                            <InputContainer>
                                <Input disabled={loadingProfileUpdate} onChange={(e)=>setGender(e.target.value)}  value={gender} placeholder="Gender" />
                            </InputContainer>

                            <InputContainer>
                                <Input disabled={loadingProfileUpdate} onChange={(e)=>setPhoneNumber(e.target.value)}  value={phoneNumber} placeholder="Phone number" />
                            </InputContainer>


                            <HeaderContainer>Social links</HeaderContainer>

                            <InputContainer>
                                <Input disabled={loadingProfileUpdate} onChange={(e)=>setWebsiteLink(e.target.value)}  value={websiteLink} placeholder="Phone number" />
                            </InputContainer>

                            <InputContainer>
                                <Input disabled={loadingProfileUpdate} onChange={(e)=>setInstagramLink(e.target.value)}  value={instagramLink} placeholder="Phone number" />
                            </InputContainer>

                            <InputContainer>
                                <Input disabled={loadingProfileUpdate} onChange={(e)=>setXTwitterLink(e.target.value)}  value={xTwitterLink} placeholder="Phone number" />
                            </InputContainer>

                            <InputContainer>
                                <Input disabled={loadingProfileUpdate} onChange={(e)=>setFacebookLink(e.target.value)}  value={facebookLink} placeholder="Phone number" />
                            </InputContainer>

                            <InputContainer>
                                <Input disabled={loadingProfileUpdate} onChange={(e)=>setYoutubeLink(e.target.value)}  value={youtubeLink} placeholder="Phone number" />
                            </InputContainer>

                            
                        </FormContainer>
                    </AccountSettingsContainer>}
                </>}


        </Container>
    );
};

export default AccountSettings;


