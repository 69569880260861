import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components"
import NavButton from "./NavButton";
import { useState } from "react";
import { RiHomeSmile2Fill } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import { IoMdPerson } from "react-icons/io";
import { RiMessage3Line } from "react-icons/ri";
import { BsStars } from "react-icons/bs";
import { IoMdNotifications } from "react-icons/io";
import { IoArrowBackOutline } from "react-icons/io5"
import { FaPlus } from "react-icons/fa6";
import { RiMenu4Fill } from "react-icons/ri";

const Container = styled.div`
    position: sticky;
    top: 0;
    z-index: 10;
`;

const DesktopContainer = styled.div`
    display: flex;
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0px 10px;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
    justify-content: space-between;
    // width: 50px;
    gap: 10px;
    overflow-y: auto;

    @media screen and (max-width: 600px){
        display: none;
    }
`;

const LogoContainer = styled.div`
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
    transition: transform 0.2s ease;
    color: var(--twikVibe-light-text-color);

    &:hover {
        transform: scale(1.9);
    }
`;
// const Logo = styled.img``;
const NavigationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
`;

// const MenuContainer = styled.div`
//     height: 45px;
//     width: 45px;
//     border-radius: 100%;
//     margin-bottom: 30px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     cursor: pointer;
//     background: var(--twikVibe-brand-color);

//     &:hover {
//         color: var(--twikVibe-light-text-color);
//     }
// `;


const MenuContainer = styled.div`
    border-radius: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;


const MobileContainer = styled.div`
    display: none;
    justify-content: space-between;
    padding: 20px 10px;
    background: var(--twikVibe-dark-theme-bg-color);
    

    @media screen and (max-width: 600px){
        display: flex;
        align-items: center;
    }
`;

const MobileLogoCon = styled.div`

`;


const MobileMenuContainer = styled.div`
    position: fixed;
    bottom: 100px;
    left: 20px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    z-index: 12;
    background: var(--twikVibe-brand-color);
    box-shadow: 0px 4px 15px rgba(0,0,0,0.2);
`;
const IconCon = styled.div`
    cursor: pointer;
`;


const ProfilePicCon = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 100%;
    overflow: hidden;
`;

const ProfilePic = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;




const Header = ({ setHideMobileNav, showPopupMenuButton, unreadChatCount, showBackButton, popupmenuOpen, setPopupMenuOpen, totalUnreadNotifications, setShowNotifications }) => {

    const [logoScale, setLogoScale] = useState(1);
    const [menuScale, setMenuScale] = useState(1);
    const [menuColor, setMenuColor] = useState(null);
    const navigate = useNavigate();

    

    const profilePicture = localStorage.getItem('profilePicture');
    const userName = localStorage.getItem('userName');

    const handleLogoClick = () => {
        setLogoScale(prevScale => prevScale - 0.09);
        
        setTimeout(() => {
            setLogoScale(1);
        }, 200);
    };

    const handleMenuClick = () => {
        

        if(popupmenuOpen){
            setMenuColor('var(--twikVibe-grey-color)')
            
            setPopupMenuOpen(false);

        }else{
            setMenuScale(prevScale => prevScale - 0.09);
            setMenuColor('var(--twikVibe-light-text-color)')
            
            setPopupMenuOpen(true);
            setTimeout(() => {
                setMenuScale(1);
            }, 200);
        }

    };

    // remove scrollbar if popup is open
    if(popupmenuOpen){
        document.body.style.overflow = 'hidden';
    }else{
        document.body.style.overflow = 'auto';
    }
    const goBack=()=>{
        navigate(-1);
    }

    return (
        <Container>

            {/* side bar for desktop screen */}
            <DesktopContainer>
        
                <Link className='transparent_click' to='/'>
                    <LogoContainer style={{transform: `scale(${logoScale})`}} onClick={handleLogoClick}>
                        logo
                    </LogoContainer>
                </Link>

                <NavigationContainer>
                    
                    <NavButton setHideMobileNav={setHideMobileNav} icon={<RiHomeSmile2Fill size={25}/>} link='/'/>
                    <NavButton setHideMobileNav={setHideMobileNav} icon={<FiSearch size={25}/>} link='/search'/>
                    <NavButton setHideMobileNav={setHideMobileNav} icon={<FaPlus size={25}/>} link='/create'/>

                    {/* pass the unreadchatcount to th enavbutton to show it  */}
                    <NavButton setHideMobileNav={setHideMobileNav} icon={<RiMessage3Line size={25}/>} grey={'var(--twikvibe-grey-color)'} unreadChatCount={unreadChatCount} link='/inbox'/>
                    <NavButton setHideMobileNav={setHideMobileNav} icon={<IoMdNotifications size={25}/>} grey={'var(--twikvibe-grey-color)'} totalUnreadNotifications={totalUnreadNotifications} setShowNotifications={setShowNotifications}/>
                    <NavButton setHideMobileNav={setHideMobileNav} icon={profilePicture?<ProfilePicCon><ProfilePic src={profilePicture}/></ProfilePicCon>:<IoMdPerson size={25}/>} link={`/@${userName}`}/>

                </NavigationContainer>

                <MenuContainer style={{ transform: `scale(${menuScale})`, color: menuColor&&menuColor }}  onClick={handleMenuClick}>
                    <RiMenu4Fill color="var(--twikVibe-grey-color)" size={25} />
                </MenuContainer>
                

                {/* <MenuContainer onClick={handleMenuClick}>
                    <BsStars color="var(--twikvibe-white-color)" size={25}/>
                </MenuContainer> */}

            </DesktopContainer>

            {/* header for mobile */}
            <MobileContainer>
            

                {/* check if the back button is set to true in the current page else show anempty div */}
                {showBackButton
                ?<IconCon onClick={goBack}>
                    <IoArrowBackOutline color="var(--twikVibe-light-text-color)" size={20} />
                </IconCon>

                :<div/>}

                <Link className='transparent_click' to='/'>
                    <MobileLogoCon style={{transform: `scale(${logoScale})`}} onClick={handleLogoClick}>
                        logo
                    </MobileLogoCon>
                </Link>

                <div></div>



                {/* the floating menu button */}
                {showPopupMenuButton&&
                <MobileMenuContainer style={{ transform: `scale(${menuScale})`, color: menuColor&&menuColor }} onClick={handleMenuClick}>
                    <BsStars color="var(--twikvibe-white-color)" size={25}/>
                </MobileMenuContainer>}

            </MobileContainer>
            
            
        </Container>
        
    )
}

export default Header

// import { NavLink } from "react-router-dom";
// import styled from "styled-components"
// import NavButton from "./ScalableButton";

// const Container = styled.div`
//     display: flex;
//     width: 1200px;
//     margin: auto;
//     position: sticky;
//     top: 0;
//     z-index: 10;
//     padding: 10px;
// `;
// const LeftContainer = styled.div`
//     flex: 1;
// `;
// const MiddleContainer = styled.div`
//     flex: 2;
// `;
// const NavigationContainer = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 20px;
// `;
// const NavigationIcon = styled.div`
//     flex: 1;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     padding: 20px;
//     cursor: pointer;
//     border-radius: 5px;
    


//     &:hover{
//         background: var(--twikVibe-dark-grey-bg);
//         scale: 1
//     }
// `;
// const RightContainer = styled.div`
//     flex: 1;
// `;


// const Header = () => {
//     return (
//         <Container>
//             {/* left section with logo ============================================================= */}
//             <LeftContainer>

//             </LeftContainer>

//             {/* middle section with icons ============================================================= */}
//             <MiddleContainer>
                
//                 <NavigationContainer>

//                     <NavLink to='/'>
//                         <NavButton text='icon'/>
//                         {/* <NavigationIcon>icon</NavigationIcon> */}
//                     </NavLink>
//                     <NavLink to='/search'>
//                         <NavigationIcon>icon</NavigationIcon>
//                     </NavLink>
//                     <NavLink to='/create'>
//                         <NavigationIcon>icon</NavigationIcon>
//                     </NavLink>
//                     <NavLink to='/inbox'>
//                         <NavigationIcon>icon</NavigationIcon>
//                     </NavLink>
//                     <NavLink to='/notifications'>
//                         <NavigationIcon>icon</NavigationIcon>
//                     </NavLink>
//                     <NavLink to='/profile'>
//                         <NavigationIcon>icon</NavigationIcon>
//                     </NavLink>
                    

//                 </NavigationContainer>

//             </MiddleContainer>

//             <RightContainer>

//             </RightContainer>
//         </Container>
//     )
// }

// export default Header

