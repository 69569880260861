import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { API_ROUTE } from '../utils/Api';
import { refreshAccessToken } from '../utils/RefreshAccessToken';
import Posts from '../components/Posts';
import { BsFillSendFill } from "react-icons/bs";
import Users from '../components/Users';


const Container = styled.div`
    flex: 1;  // use flex: 1 for all the pages container.
    padding: 10px;
`;

const InnerContainer = styled.div`
    width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 800px){
        width: 100%;
    }
`;

const SearchInputContainer = styled.form`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--twikVibe-dark-grey-bg);
    position: sticky: 
    top: 0;
`;
const SearchInput = styled.input`
    color: var(--twikVibe-light-text-color);
    background: none;
    border: none;
    padding: 5px;
    width: 100%;
    background-color: transparent;
    font-size: 16px;
    // font-weight: 500;
`;
const SearchButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
`;

const SearchOptionsContainer = styled.div`
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    cursor: grab;
    gap: 10px;
    align-items: center;
`;
const SearchOption = styled.div`
    display: inline-block;
    padding: 10px 20px;
    border-radius: 50px;
    background-color: var(--twikVibe-dark-grey-bg);
    flex-shrink: 0;
    cursor: pointer;
    font-size: 14px;
`;

const SearchChoiceContainer = styled.div``;

const PostsContainer = styled.div`

    

`;

const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:4px;
    height:4px;
    border-style:solid;
    border-left-color:var(--twikVibe-light-theme-bg-color);
    border-top-color:var(--twikVibe-light-theme-bg-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
    margin: auto;
`;

const Searchpage = ({setShowHeader, setShowPopupMenuButton}) => {

    const [query, setQuery] = useState();

    const accessToken = localStorage.getItem('accessToken')
    const [posts, setPosts] = useState([])
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedSearchChoice, setSelectedSearchChoice] = useState('posts')

    const [postsScale, setPostsScale] = useState(1);
    const [peopleScale, setPeopleScale] = useState(1);
    const [mediaScale, setMediaScale] = useState(1);

    // const userId = localStorage.getItem('userId')

    const navigate = useNavigate();
    // const location = useLocation()
    // const { searchType } = useParams();

    // const searchQuery = new URLSearchParams(location.search);
    // const searchedQuery = searchQuery.get('query');

        // remove side bar 
        useEffect(()=>{
            setShowHeader(true)
            setShowPopupMenuButton(false)
        },[setShowHeader, setShowPopupMenuButton])
        

    const handleInputChange = (data) =>{
        const value = data.target.value;

        setQuery(value);
    }

    const getUserProfile = useCallback(async({userId})=>{
        try {
            const response = await axios.get(`${API_ROUTE}getUserById?userWhoseAccountWeWantToGetId=${userId}`,{
                headers: {Authorization: accessToken}
            });

            const userName = response.data.returnWithoutPassword.userName;
            const fullName = response.data.returnWithoutPassword.fullName;
            const profilePicture = response.data.returnWithoutPassword.profilePicture;
            const subscribers = response.data.returnWithoutPassword.subscribers;
            const hasVerificationBadge = response.data.returnWithoutPassword.hasVerificationBadge;
            const verificationBadgeType = response.data.returnWithoutPassword.verificationBadgeType;
            const userBackgroundColor = response.data.returnWithoutPassword.userBackgroundColor;
            return {fullName: fullName, profilePicture: profilePicture, userName: userName, subscribers: subscribers, hasVerificationBadge: hasVerificationBadge, verificationBadgeType: verificationBadgeType, userBackgroundColor: userBackgroundColor};
        } catch (error) {
            
        }
    },[accessToken])



    const searchAll = async(e)=>{
        e.preventDefault();
        setLoading(true)

        if(!query){
            setLoading(false)
            return;
        }

        navigate(`/search?query=${query}`)

        try {
            const response  =  await axios.get(`${API_ROUTE}searchAll?searchQuery=${query}`,{
                headers: {Authorization: accessToken}
            });

            

            // getting the data of the users in the posts
            // const userData = await Promise.all(
            //     response.data.posts.map(async (post) => {
            //         const userProfile = await getUserProfile({ userId: post.userId });
            //         return {
            //             ...post,
            //             userName: userProfile.userName,
            //             fullName: userProfile.fullName, // Attach the fetched full name to the chat
            //             profilePicture: userProfile.profilePicture, // Attach the fetched full name to the chat
            //             subscribers: userProfile.subscribers,
            //             hasVerificationBadge: userProfile.hasVerificationBadge,
            //             verificationBadgeType: userProfile.verificationBadgeType,
            //         };
            //     })
            // );


            if (response.data.posts.length) {
                const userData = await Promise.all(
                    response.data.posts.map(async (post) => {
                        const userProfile = await getUserProfile({ userId: post.userId });
                        return {
                            ...post,
                            userName: userProfile.userName,
                            fullName: userProfile.fullName, // Attach the fetched full name to the chat
                            profilePicture: userProfile.profilePicture, // Attach the fetched full name to the chat
                            subscribers: userProfile.subscribers,
                            hasVerificationBadge: userProfile.hasVerificationBadge,
                            verificationBadgeType: userProfile.verificationBadgeType,
                            userBackgroundColor: userProfile.userBackgroundColor,
                        };
                    })
                );

    
                setPosts(userData);

            }


            setUsers(response.data.users)
            setLoading(false)

            console.log(response.data)
        } catch (error) {
            // check if acces token is expired
            if(error.status === 403){
                const { accessToken } = await refreshAccessToken();

                if(accessToken){
                    // recall the search function
                    searchAll();
                }
            }else{
                console.log(error)
                setLoading(false)
                
            }
        }
    }

    

    const handlePostsClick = () => {
        // Increase the scale on click
        setPostsScale(prevScale => prevScale - 0.04);
        
        // Reset scale after a short delay
        setTimeout(() => {
            setPostsScale(1);
        }, 200);
    };

    const handlePeopleClick = () => {
        // Increase the scale on click
        setPeopleScale(prevScale => prevScale - 0.04);
        
        // Reset scale after a short delay
        setTimeout(() => {
            setPeopleScale(1);
        }, 200);
    };

    const handleMediaClick = () => {
        // Increase the scale on click
        setMediaScale(prevScale => prevScale - 0.04);
        
        // Reset scale after a short delay
        setTimeout(() => {
            setMediaScale(1);
        }, 200);
    };

    return (
        <Container>
            <InnerContainer>
                <SearchInputContainer onSubmit={searchAll}>
                    <SearchInput value={query} placeholder='Username, title, about, anything...' onChange={(e)=>handleInputChange(e)} type='text'/>
                    <SearchButton type='submit' onClick={searchAll}><BsFillSendFill color='var(--twikVibe-light-text-color)' size={20} /></SearchButton>
                </SearchInputContainer>

                <SearchOptionsContainer>
                    {/* set the selected choice to either posts, users, videos, images ... */}
                    <SearchOption style={{background: selectedSearchChoice==='posts'&&'var(--twikVibe-light-theme-bg-color)', color: selectedSearchChoice==='posts'&&'var(--twikVibe-dark-text-color)', transform: `scale(${postsScale})`}} onClick={()=>{
                        setSelectedSearchChoice('posts');
                        handlePostsClick()
                    }}>Posts</SearchOption>


                    <SearchOption style={{background: selectedSearchChoice==='users'&&'var(--twikVibe-light-theme-bg-color)', color: selectedSearchChoice==='users'&&'var(--twikVibe-dark-text-color)', transform: `scale(${peopleScale})`}} onClick={()=>{
                        setSelectedSearchChoice('users');
                        handlePeopleClick();
                    }}>Users</SearchOption>


                    <SearchOption style={{background: selectedSearchChoice==='videos'&&'var(--twikVibe-light-theme-bg-color)', color: selectedSearchChoice==='videos'&&'var(--twikVibe-dark-text-color)', transform: `scale(${mediaScale})`}} onClick={()=>{
                        setSelectedSearchChoice('videos');
                        handleMediaClick();
                    }}>Videos</SearchOption>


                    <SearchOption style={{background: selectedSearchChoice==='images'&&'var(--twikVibe-light-theme-bg-color)', color: selectedSearchChoice==='images'&&'var(--twikVibe-dark-text-color)', }} onClick={()=>{
                        setSelectedSearchChoice('images');
                    }}>Images</SearchOption>

                </SearchOptionsContainer>


                {/* display the search results based on the seleted search results */}
                {selectedSearchChoice==='posts'?
                <SearchChoiceContainer>
                    {loading?   (
                        <LoadingSpinnerElement/>
                        
                    )   :   (
                        <PostsContainer>
                            <Posts posts={posts} setPosts={setPosts}/>
                        </PostsContainer>
                    )}
                </SearchChoiceContainer>
                
                :selectedSearchChoice==='users'?
                <SearchChoiceContainer>
                    {loading?   (
                        <LoadingSpinnerElement/>
                    )   :   (
                        <Users setUsers={setUsers} users={users}/>
                    )}
                    
                </SearchChoiceContainer>

                :selectedSearchChoice==='videos'?
                <SearchChoiceContainer>
                    videos
                </SearchChoiceContainer>

                :selectedSearchChoice==='images'?
                <SearchChoiceContainer>
                    images
                </SearchChoiceContainer>
                :null}

                
            </InnerContainer>
        </Container>
    )
}

export default Searchpage