import React, { useCallback, useEffect, useState } from 'react'
import  io  from "socket.io-client";
import axios from "axios";
import { API_ROUTE, ROOT_API_ROUTE } from '../utils/Api';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { refreshAccessToken } from '../utils/RefreshAccessToken';


const socket = io(ROOT_API_ROUTE);


const Container = styled.div`
     position: fixed;
     top: 50px;
     bottom:100px;
     padding: 10px;


    left: 100px;
    width: 400px;
    background: var(--twikVibe-dark-theme-bg-color);
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0,0,0,0.2);
    z-index: 12;

//     @media screen and (max-width: 600px){
//         left: 0;
//         right: 0;
//         bottom: 0;
//         top: 0;
//         height: auto;
//         width: auto;
//         border-radius: 0px;
//     }
`;

const Title = styled.h1`
     padding: 10px;
     font-size: 25px;
     color:var(--twikVibe-light-theme-color);
     position: sticky;
     top: 10px;
`;
const NotificationsConainer = styled.div`
     overflow-y: auto;
     height: 90vh;
`;

const NotificationItem = styled.div`
     padding: 15px 10px;
     font-size: 16px;
     color:var(--twikVibe-light-theme-color);
     word-break: break-word;
`;

const Notificationspage = ({setTotalUnreadNotifications, setShowNotifications }) => {

     const [notifications, setNotifications] = useState([]);
     const [loading, setLoading] = useState(true)

     const accessToken = localStorage.getItem('accessToken')


     const fetchNotifications = useCallback(async()=>{
          try {
              const response = await axios.get(`${API_ROUTE}getNotifications`,{
                  headers: {Authorization: accessToken}
              })
  
  
              setNotifications(response.data.userNotifications)

              setLoading(false)
          } catch (error) {
              
          }
     },[accessToken])
  
  
     // Listen for real-time updates via socket
     useEffect(() => {
     // Fetch the unread count on component mount
     fetchNotifications();

     // Listen for the 'chatListUpdate' event to fetch the unread count in real-time
     // socket.on('chatListUpdate', fetchUnreadCount);

     return () => {
          // Cleanup the socket listener on component unmount
          socket.off('chatListUpdate');
     };
     }, [fetchNotifications]);

     const viewNotification = async(notificationId)=>{
          try {
               await axios.post(`${API_ROUTE}viewNotification`,{
                    notificationId: notificationId
               },{
                   headers: {Authorization: accessToken}
               })

               setTotalUnreadNotifications(prev=>prev-1)
   
   
           } catch (error) {
               if(error.status === 403){
                    const {accessToken} = await refreshAccessToken();

                    if(accessToken){
                         viewNotification(notificationId);
                    }
               }else{
                    console.log(error)
               }
           }
     }


     if(loading){
          return <Container>
               loading
          </Container>
     }
     return (
          <Container>
               <Title>Notifications</Title>
               <NotificationsConainer>
                    {notifications.map((notification, index)=>(

                         <>
                              {notification.notificationTitle && notification.notificationLink
                              ?<Link  className='transparent_click' to={notification.notificationLink}>
                                   <NotificationItem onClick={()=>{
                                        setShowNotifications(prev=>!prev);

                                        viewNotification(notification._id)
                                        }} key={index} style={{background: notification.notificationIsRead?'':'var(--twikVibe-transparent-color)'}}>
                                        {notification.notificationTitle}
                                   </NotificationItem>
                              </Link>
                              :!notification.notificationLink
                              ?<NotificationItem key={index}>
                                   {notification.notificationTitle}
                              </NotificationItem>
                              :null}
                         </>
                         
                    ))}
               </NotificationsConainer>
          </Container>
     )
}

export default Notificationspage