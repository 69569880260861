import styled, { keyframes } from "styled-components";
import { IoArrowBackOutline } from "react-icons/io5"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import RegularBadge from "../../assets/icons/regular_verification_badge.svg";
import SilverBadge from "../../assets/icons/silver_verification_badge.svg";
import OrganisationBadge from "../../assets/icons/business_verification_badge.svg";
import PrideBadge from "../../assets/icons/pride_verification_tag.svg";
import { FaCheck } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import IconButton from "../../components/IconButton";
import { useState } from "react";
import axios from "axios";
import { API_ROUTE } from "../../utils/Api";
import { refreshAccessToken } from "../../utils/RefreshAccessToken";



const Container = styled.div`
    flex: 1;
`;

const InnerContainer = styled.div`
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: auto;
    
    
    
    @media screen and (max-width: 600px){
        width: auto;
        padding: 10px;
    }
`;
const HeaderContainer = styled.h1`
    display: flex;
    align-items: center;
    color: var(--twikVibe-light-text-color);
    font-size: 16px;
    gap: 20px;
    position: sticky;
    top: 0px; 
    // justify-content: space-between;
    z-index: 5;
    background: var(--twikVibe-dark-theme-bg-color);
    padding: 10px 0px;

    @media screen and (max-width: 600px){
        top: 50px; 
    }
`;
const Title = styled.div`
    color: var(--twikvibe-grey-color);
    font-size: 14px;
    font-weight: 600;
`;
// const Description = styled.div`
//     color: var(--twikvibe-grey-color);
//     font-size: 14px;
// `;
const VerficationItemsContainer = styled.div``;
const VerficationItem = styled.div`
    border-radius: 10px;
    padding: 10px;
    // border: solid 1px var(--twikvibe-grey-color);
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;

    &:hover{
        background: var(--twikVibe--darker-transparent-black-color);
    }
`;
const VerficationImageContainer = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
`;
const VerficationImage = styled.img`
    width: 100%;
    height:100%;
`;
const Check = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
`;

const VerficationTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
const VerficationTextTitle = styled.div`
    color: var(--twikVibe-light-text-color);
    font-size: 15px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-weight: 600;
`;
const VerficationTextDescription = styled.h4`
    color: var(--twikVibe-light-text-color);
    font-size: 14px;
    font-weight: 500;
`;






// modal designs here


const VerificationModalBG = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--twikVibe--darker-transparent-black-color);
    z-index: 11;
    padding: 10px;
`;
const VerificationModalContainer = styled.div`
    background: var(--twikVibe-dark-grey-bg);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    width: 500px;
    position: relative;
`;

const VerificationModalHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const VerificationModalHeaderTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const VerficationImageAndTitle = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;

const VerficationImageModalContainer = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
`;
const VerficationImageModal = styled.img`
    width: 100%;
    height:100%;
`;

const VerificationModalTitle = styled.div`
    color: var(--twikVibe-light-text-color);
    font-size: 15px;
    font-weight: 600;

`;




const DurationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;


const DurationSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    max-height: 50vh;
    min-height: 20vh;
    overflow-y: auto;
`;
const DurationItem = styled.div`
    padding: 10px;
    border-radius: 10px;
    background: var(--twikVibe-dark-theme-color);
    color: var(--twikVibe-light-text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    border: solid 2px var(--twikVibe-dark-theme-color)

    // &:hover{
    //     background: var(--twikVibe--darker-transparent-black-color);
    // }
`;

const DurationItemTextCon = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const ActionButton = styled.div`
    padding: 14px;
    background: var(--twikVibe-brand-color);
    color: var(twikvibe-white-color);
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        background: var(--twikVibe-brand-hover-color);
    }
`;


// selected duration styles

const SelectedDurationModal = styled.div`
    background: var(--twikVibe-dark-grey-bg);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 10px;
    width: 330px;
`;
const BigBadge = styled.div`
    width: 50px;
    height: 50px;
    position: relative;
`;


const HasSelectedDescription = styled.h4`
    color: var(--twikVibe-light-text-color);
    font-size: 13px;
    font-weight: 500;
    text-align: center;
`;

const rotate=keyframes`
    from {
        transform:rotate(0deg);
    }
    to{
        transform:rotate(360deg)
    }
`;

const LoadingSpinnerElement=styled.div`
    padding:5px;
    background-color:transparent;
    border-radius:100px;
    width:2px;
    height:2px;
    border-style:solid;
    border-left-color:var(--twikvibe-white-color);
    border-top-color:var(--twikvibe-white-color);
    border-width:3px;
    border-right-color:rgba(214, 214, 212, 0.39);
    border-bottom-color:rgba(214, 214, 212, 0.39);
    transition:linear,500ms;
    animation:${rotate} 1s linear infinite;
`;

const VerificationBadge = ({setShowHeader, deviceWidth}) => {

    // every verification price is calculated per month as a starting price
    const verificationBadges = [
        {
            title: 'Regular Tag',
            type: 'regular',
            monthlyPrice: 3,
            image: RegularBadge,
            description: '$3 / month. '
        },
        {
            title: 'Silver (Professional)',
            type: 'silver',
            monthlyPrice: 4,
            image: SilverBadge,
            description: '$4 / month. '
        },
        {
            title: 'Gold (organisation)',
            type: 'organisation',
            monthlyPrice: 5,
            image: OrganisationBadge,
            description: '$5 / month. \nComes with Organisation Flag e.g \nAccess To Unlimited Tools',
        },
        {
            title: 'Pride Tag',
            type: 'pride',
            monthlyPrice: 6,
            image: PrideBadge,
            description: '$6 / month. '
        },
    ]

    const durations = [
        {
            duration: '1 month',
            months: 1,
            description: 'Get subscribed for one month',
            subscriptionType: 'Monthly plan',
        },
        {
            duration: '2 months',
            months: 2,
            description: 'Get subscribed for two months',
            subscriptionType: 'Monthly plan',
        },
        {
            duration: '3 months',
            months: 3,
            description: 'Get subscribed for three months',
            subscriptionType: 'Monthly plan',
        },
        {
            duration: '5 months',
            months: 5,
            description: 'Get subscribed for five months',
            subscriptionType: 'Monthly plan',
        },
        {
            duration: '6 months',
            months: 6,
            description: 'Get subscribed for six months',
            subscriptionType: 'Monthly plan',
            discount: -4
        },
        {
            duration: '8 months',
            months: 8,
            description: 'Get subscribed for eight months',
            subscriptionType: 'Monthly plan',
        },
        {
            duration: '10 months',
            months: 10,
            description: 'Get subscribed for ten months',
            subscriptionType: 'Monthly plan',
        },
        {
            duration: '12 months',
            months: 12,
            description: 'Get subscribed for one year(12 months)',
            subscriptionType: 'Annual plan',
        },
        
       
        
    ]
 
    const accessToken = localStorage.getItem('accessToken')

    const userName = localStorage.getItem('userName');


    const [selectedDuration, setSelectedDuration] = useState({
        duration: null,
        description: null,
        months: null
    });

    const [selectedVerificationBadgeType, setSelectedVerificationBadgeType] = useState({
        icon: null,
        title: null,
        type: null,
        monthlyPrice: null
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [selectedTotalPurchaseAmount, setSelectedTotalPurchaseAmount] = useState(null)

    const [selectedExpirationDate, setSelectedExpirationDate] = useState(null)

    const navigate = useNavigate();
    
        // remove side bar 
        useEffect(()=>{
            setShowHeader(true)
        },[setShowHeader])
    
    

    const goBack=()=>{
        navigate(-1);
    }

    const getVerificationBadge = async()=>{
        setLoading(true);
        setError(null);
        try {
            await axios.post(`${API_ROUTE}getVerificationBadge`,{
                verificationBadgeType: selectedVerificationBadgeType.type,
                verificationBadgeEndDate: selectedExpirationDate,
                verificationBadgeTypeAmount: selectedTotalPurchaseAmount
            },{
                headers: {Authorization: accessToken}
            })

            setLoading(false);

            // navigate to homepasge after getting the badge
            navigate(`/@${userName}`)
            
        } catch (error) {
            if(error.status===403){
                const {accessToken} = await refreshAccessToken();

                if(accessToken){
                    // recall the getverification badge function
                    getVerificationBadge();
                }
            }else{
                setLoading(false);
                setError(error.response.data.error)
            }
        }
    }
    return (
        <Container>
            <InnerContainer>
                <HeaderContainer>
                    {deviceWidth>600&&<IoArrowBackOutline style={{cursor:'pointer'}} color="var(--twikVibe-light-text-color)" onClick={goBack} />}
                    Get Verified
                </HeaderContainer>

                {/* <Title>Get Verified </Title> */}
                <Title>Get Verified, Get Seen Faster And Gain Access To Exclusive Features and Style.</Title>


                
                {/* the list of verification types ================================================================================ */}
                <VerficationItemsContainer>
                    {verificationBadges.map((badge, index)=>(
                        <VerficationItem onClick={()=>{

                            setSelectedVerificationBadgeType((prev)=>({
                                ...prev,
                                icon: badge.image,
                                title: badge.title,
                                monthlyPrice: badge.monthlyPrice,
                                type: badge.type
                            }));
                        }} key={index}>
                            <VerficationImageContainer>
                                <VerficationImage src={badge.image}/>
                                <Check><FaCheck color="var(--twikVibe-dark-text-color)" size={12}/></Check>
                            </VerficationImageContainer>

                            <VerficationTextContainer>
                                <VerficationTextTitle>{badge.title}</VerficationTextTitle>
                                <VerficationTextDescription>{badge.description}</VerficationTextDescription>
                            </VerficationTextContainer>
                        </VerficationItem>
                    ))}
                </VerficationItemsContainer>
                
                
                {/* the user has selected a verification type, now show them the duration prices for the badge type they chose */}
                {selectedVerificationBadgeType.icon&&
                    <VerificationModalBG title="close modal" onClick={()=>{
                            setSelectedVerificationBadgeType((prev)=>({
                                ...prev,
                                icon: null,
                                title: null,
                                monthlyPrice: null,
                                type: null
                            }));
                            setSelectedDuration((prev)=>({
                                ...prev,
                                duration: null,
                                months: null,
                                description: null
                            }));

                            setError(null);

                        }}>

                        {/* show the calculated prices for the months of the selected badge type */}
                        {/* the user has not selected a duration yet */}
                        {!selectedDuration.duration
                        ?<VerificationModalContainer title="" onClick={(e)=>e.stopPropagation()}>
                            <VerificationModalHeader>


                                <VerificationModalHeaderTitle>
                                    <VerficationTextTitle>Pricing And Plans</VerficationTextTitle>
                                    <IconButton onClick={()=>{
                                        setSelectedVerificationBadgeType((prev)=>({
                                            ...prev,
                                            icon: null,
                                            title: null,
                                            monthlyPrice: null,
                                            type: null
                                        }));

                                        setError(null);
                                    }} icon={<IoClose color="var(--twikVibe-light-text-color)" size={17}/>}/>
                                </VerificationModalHeaderTitle>
                                
                                <VerficationTextDescription>
                                    You get seen faster when you get a verification badge
                                </VerficationTextDescription>
                                
                            </VerificationModalHeader>
                            
                            <DurationContainer>
                                <VerficationImageAndTitle>
                                    <VerficationImageModalContainer>
                                        <VerficationImageModal src={selectedVerificationBadgeType.icon}/>
                                        <Check><FaCheck color="var(--twikVibe-dark-text-color)" size={12}/></Check>
                                    </VerficationImageModalContainer>
                                    <VerificationModalTitle>{selectedVerificationBadgeType.title}</VerificationModalTitle>
                                </VerficationImageAndTitle>



                                {/* list of durations to be selected in months */}
                                <DurationSection>
                                    {durations.map((duration, index)=>(
                                        <DurationItem style={{border: selectedDuration.duration === duration.duration &&'solid 2px var(--twikVibe-brand-color)'}} key={index} onClick={()=>{

                                            // select the duration the user wants
                                            setSelectedDuration((prev)=>({
                                                ...prev,
                                                duration: duration.duration,
                                                months: duration.months,
                                                description: duration.description
                                            }));

                                            // set the date of the expiration according to the duration the user has selected
                                            const date = new Date();
                                            setSelectedExpirationDate(date.setMonth(date.getMonth() + duration.months))

                                            // date.setMonth(date.getMonth() + duration.months)


                                            // Format the date in MM/DD/YYYY format
                                            // const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;


                                            
                                            // set the total purchase amount
                                            setSelectedTotalPurchaseAmount(duration.months * selectedVerificationBadgeType.monthlyPrice)
                                        }}>
                                            <DurationItemTextCon>
                                                <VerficationTextTitle>
                                                    {duration.duration}

                                                    {/* add discount text */}
                                                    {duration.duration==='6 months'&&
                                                        <div style={{color: '#00e750', background:'rgba(0, 70, 0)', padding: '5px', borderRadius: '10px', fontSize:'12px', fontWeight:'500'}}>
                                                            $4 discount
                                                        </div>}
                                                </VerficationTextTitle>
                                                <VerficationTextDescription>{duration.description}</VerficationTextDescription>
                                            </DurationItemTextCon>

                                            <VerficationTextTitle>${duration.months * selectedVerificationBadgeType.monthlyPrice}</VerficationTextTitle>
                                        </DurationItem>
                                    ))}
                                </DurationSection>

                                {/* <ActionButton onClick={()=>setHasSelected(!hasSlected)}>Next</ActionButton> */}
                            </DurationContainer>

                        
                        </VerificationModalContainer>


                        // the user has just selected a duration with the price, give them a prompt to confirm that they they want to purchase the badge they chose above
                        :<SelectedDurationModal title="" onClick={(e)=>e.stopPropagation()}>
                            <VerificationModalHeaderTitle>
                                <IoArrowBackOutline style={{cursor: 'pointer', color: "var(--twikVibe-light-text-color)"}} onClick={()=>{
                                    setSelectedDuration((prev)=>({
                                        ...prev,
                                        duration: null,
                                        months: null,
                                        description: null
                                    }));
                                }}  size={17}/>
                                <BigBadge>
                                    <VerficationImage src={selectedVerificationBadgeType.icon}/>
                                    <Check><FaCheck color="var(--twikVibe-dark-text-color)" size={15}/></Check>
                                </BigBadge>
                                <div/>
                            </VerificationModalHeaderTitle>
                            

                            <HasSelectedDescription>Are you sure you <br/> want to be verified?</HasSelectedDescription>

                            <DurationItem style={{border: 'solid 2px var(--twikVibe-brand-color)'}}>
                                <DurationItemTextCon>
                                    <VerficationTextTitle>
                                        {selectedDuration.duration}

                                        {/* add discount text */}
                                        {selectedDuration.duration==='6 months'&&
                                            <div style={{color: '#00e750', background:'rgba(0, 70, 0)', padding: '5px', borderRadius: '10px', fontSize:'12px', fontWeight:'500'}}>
                                                $4 discount
                                            </div>}
                                    </VerficationTextTitle>
                                    <VerficationTextDescription>{selectedDuration.description}</VerficationTextDescription>
                                </DurationItemTextCon>

                                <VerficationTextTitle>${selectedTotalPurchaseAmount}</VerficationTextTitle>

                            </DurationItem>
                            

                            {/* display error */}
                            {error&&<div style={{color:'red', fontSize: '13px', fontWeight:'500'}}>{error}</div>}


                            <br/>
                            <br/>
                            <br/>

                            <HasSelectedDescription>By Verifying, you agree to <br/> our terms and conditions</HasSelectedDescription>

                            {loading
                            ?<ActionButton><LoadingSpinnerElement/></ActionButton>
                            :<ActionButton onClick={getVerificationBadge}>Sure, Proceed</ActionButton>}
                        </SelectedDurationModal>}
                    </VerificationModalBG>}
            </InnerContainer>
        </Container>
    )
}

export default VerificationBadge