import React, { useState } from 'react'
// import { MdOutlineLightMode } from "react-icons/md";
import styled from 'styled-components';
import ToggleSwitch from '../components/ToggleSwitch';
import axios from 'axios';
import { API_ROUTE } from './Api';
import { refreshAccessToken } from './RefreshAccessToken';


const DarkModeTogle = styled.h1`
    
`;

const Container = styled.label`
    border-radius: 10px;
    padding: 20px;
    // border: solid 1px var(--twikvibe-grey-color);
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;

    &:hover{
        background: var(--twikVibe--darker-transparent-black-color);
    }
`;

const SettingsTitle = styled.h1`
    color: var(--twikVibe-light-text-color);
    font-size: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const SettingsDescription = styled.div`
    color: var(--twikvibe-grey-color);
    font-size: 14px;
    font-weight: 600;
`;

const DarkMode = () => {
    const selecetedTheme = localStorage.getItem('selectedTheme');
    const [currentMode, setCurrentMode] = useState(selecetedTheme==='dark'?'Dark Mode':'Light Mode')
    const accessToken = localStorage.getItem('accessToken')
    
    
    const setDarkModeTheme = async ({darkModeTheme}) => {
        try {
             await axios.post(`${API_ROUTE}updateProfile`,
                { darkModeTheme: darkModeTheme.toString()},
                {headers: {
                    Authorization: accessToken
                }})

        } catch (error) {
            if(error.status === 403){
                // refresh access token
                const {accessToken} = await refreshAccessToken();

                if (accessToken) {
                    // Optionally, update the authorization header with the new token
                     await axios.post(`${API_ROUTE}updateProfile`, 
                        { darkModeTheme: darkModeTheme.toString() },
                        {
                            headers: {
                                Authorization: accessToken // Use the new token for this request
                            }
                        }
                    );
        
                }
            }else{
                console.log(error.message)
            }

           
        }
    }

    const setDarkMode = ()=>{
        document.querySelector("body").setAttribute('data-theme', 'dark')
        localStorage.setItem('selectedTheme', 'dark')
        setDarkModeTheme({darkModeTheme: true})
    }

    const setLightMode = ()=>{
        document.querySelector("body").setAttribute('data-theme', 'light')
        localStorage.setItem('selectedTheme', 'light')
        setDarkModeTheme({darkModeTheme: false})
    }


    

    if(selecetedTheme==='dark' || selecetedTheme === null){
        setDarkMode();
    }



    const toggleTheme = (e)=>{
        if(selecetedTheme!=='dark'){
            setDarkMode();
            setCurrentMode('Dark Mode');
        } else {
            setLightMode();
            setCurrentMode('Light Mode');
        }
    }
    
    return (
        <DarkModeTogle onClick={()=>{}} className='darkmodetoggle'>
            <Container>
                <SettingsTitle>
                    {currentMode}
                    <SettingsDescription>Adjust the appearance of Twikvibe to reduce glare</SettingsDescription>
                    <SettingsDescription style={{fontWeight:'500', fontSize:'14px'}}>We care about your eyes.</SettingsDescription>
                </SettingsTitle>
                
                
                <ToggleSwitch
                    isChecked={selecetedTheme!=='dark'?false:true}
                    onChange={toggleTheme}
                />
            </Container>
            
        </DarkModeTogle>
    )
}

export default DarkMode;